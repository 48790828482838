import {
  DateRangePicker,
  SingleInputDateRangeField,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  FilledInput,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Popover,
  Select,
  Slider,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { common } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers";
import { IconX } from "@tabler/icons";
import React from "react";
import theme from "themes";
import StatusChip from "ui-component/StatusChip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function StudentCourseFilter(props) {
  const [status, setStatus] = React.useState({
    label: "수강중",
    color: "availiable",
  });
  const [sliderValue, setSliderValue] = React.useState([0,100]);
  
  function handleSlider(event,newValue,activeThumb){
    if(!Array.isArray(newValue)){
      return;
    }

    if(activeThumb === 0) setSliderValue([Math.min(newValue[0],sliderValue[1]-5),sliderValue[1]]);
    else setSliderValue([sliderValue[0], Math.max(newValue[1], sliderValue[0]+5)])
  }

  function handleChange(e) {
    setStatus(e.target.value);
  }

  function handleSave() {
    props.handleClose();
  }
  const paletteTheme = useTheme(theme());
  const style = {
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    paddingBottom: 10,
  };
  return (
    <ThemeProvider theme={theme}>
      <Popover
        open={props.open}
        onClose={() => {
          props.handleClose();
        }}
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "5%" }} />
            <Typography variant="h1">검색 옵션</Typography>
            <div style={{ flex: 1 }} />
            <div style={{ width: "2%" }} />
          </Box>
          <Divider />
          <Grid
            container
            alignItems={"center"}
            direction={"column"}
            spacing={3}
          >
            <Grid item xs={12} />
            <Grid item xs={12} sx={{ width: "90%" }}>
              <TextField
                label="이름"
                variant="filled"
                defaultValue="All"
                color=""
                sx={{ "& label": { fontSize: "1rem"} }}
                InputProps={{ style: { fontSize: "1.0rem" } }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ width: "90%" }}>
              <InputLabel id="status_label">상태</InputLabel>
              <Select
                labelId="status_label"
                id="status"
                defaultValue={{ value: "availiable" }}
                value={status}
                onChange={handleChange}
                input={<FilledInput label="출결상태" />}
                sx={{ textAlign: "left" }}
                renderValue={(value) => (
                  <StatusChip type={"student_program"} value={value}/>
                )}
                fullWidth
              >
                <MenuItem
                  sx={{ justifyContent: "left" }}
                  value="on"
                >
                  <StatusChip type={'student_program'} value={"on"} />
                </MenuItem>
                <MenuItem
                  sx={{ justifyContent: "left" }}
                  value="complete"
                >
                  <StatusChip type={"student_program"} value={"complete"} />
                </MenuItem>
                <MenuItem
                  sx={{ justifyContent: "left" }}
                  value="waiting"
                >
                  <StatusChip type={"student_program"} value={"waiting"} />
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sx={{ width: "90%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker label="기간 설정" format="YYYY-MM-DD" slots={{ field: SingleInputDateRangeField }} sx={{width:'100%', textAlign:'left', '& .MuiInputBase-input':{
                  textAlign:'left', fontSize:'1.0rem'
                }}}/>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sx={{ width: "90%" }}>
            <Typography gutterBottom>점수 설정</Typography>
            <Slider value={sliderValue} onChange={handleSlider} disableSwap valueLabelDisplay="on"/>
            </Grid>
            <Grid item xs={12} sx={{ width: "90%" }}>
              <Button
                onClick={handleSave}
                variant="contained"
                color="save"
                sx={{
                  width: "100%",
                  borderRadius: 0,
                  height: 60,
                  fontSize: "1.5rem",
                }}
              >
                저장
              </Button>
            </Grid>
          </Grid> 
        </Box>
      </Popover>
    </ThemeProvider>
  );
}

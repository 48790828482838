import StatusChip from "ui-component/StatusChip";
import AttendenceChip from "ui-component/AttendenceChip";
import * as React from "react";
import { IconUser } from "@tabler/icons";
import {
  Avatar,
  Button,
  Divider,
  Box,
  ButtonBase,
  Card,
  InputAdornment,
  OutlinedInput,
  Typography,
  Popper,
  Input,
  Checkbox,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import { red, grey, common } from "@mui/material/colors";
import ClassStudent from "ui-component/buttons/ClassStudent";
import IconButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconDotsVertical } from "@tabler/icons";
import { forwardRef } from "react";
import {
  GridToolbarQuickFilter,
  DataGridPro,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";

// material-ui
import { useTheme, styled } from "@mui/material/styles";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import {GetStudents} from "data/StudentDataAPI";
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { alpha, shouldForwardProp } from "@mui/system";
import { useRef } from "react";
import CourseAssign from "ui-component/modals/CourseAssign";
import ChangeClassroom from "ui-component/modals/ChangeClassroom";
import { useEffect } from "react";
import CRUDStudent from "ui-component/modals/CRUDStudent";


export default function O_StudentTable(props) {
  const [studentdata, setStudentdata] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [filterOptions, setFilterOptions] = useState(() => {
    const savedFilters = localStorage.getItem('studentTableFilters');
    return savedFilters ? JSON.parse(savedFilters) : {
      on: true,
      off: true,
      deleted: true,
      waiting: true
    };
  });
  useEffect(() => {
    GetStudents().then((Students) => {
      // setStudentdata(Students);
      setOriginalData(Students);
      const filteredData = applyFilters(Students, filterOptions);
      setStudentdata(filteredData);
    });
  }, []);

  const applyFilters = (data, filters) => {
    return data.filter(student => {
      if (student.UserStatus === "on" && filters.on) return true;
      if (student.UserStatus === "off" && filters.off) return true;
      if (student.UserStatus === "deleted" && filters.deleted) return true;
      if (student.UserStatus === "waiting" && filters.waiting) return true;
      return false;
    });
  };

  const ref = useRef();
  const CustomQuickFilter = styled(GridToolbarQuickFilter, {
    shouldForwardProp,
  })(({ theme }) => ({
    width: "100%",
    fontSize: "1.0rem",
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
      height: "29px",
      fontSize: "1.0rem",
      "&::placeholder": {
        fontSize: "1.0rem",
        color: grey[800],
      },
    },
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background: "#fff",
    },
  }));

  const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
    ({ theme }) => ({
      ...theme.typography.commonAvatar,
      ...theme.typography.mediumAvatar,
      background: theme.palette.grey[100],
      color: theme.palette.grey[500],
      "&:hover": {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light,
      },
    })
  );

  const handleXClick = (e, index, id) => {
    const list = props.selectedRow.filter((_, i) => i !== index);
    props.setSelectedRow(list);
    props.apiRef.current.selectRow(id, false);
  };

  function SelectedStudents(props) {
    return (
      <Box
        key={`student${props.index}`}
        sx={{
          borderRadius: "2px",
          backgroundColor: "#e3e6eb",
          padding: "2px",
          width: "114px",
          height: "45px",
        }}
      >
        {props.DisplayName +
          "(" +
          (props.GradeLevel +
            "," +
            (props.Gender === "M" ? "남" : "여") +
            ")")}
        <ButtonBase
          id={`xButton${props.index}`}
          onClick={(e) => handleXClick(e, props.index, props.id)}
          sx={{ marginBottom: 3, width: 14, height: 14, zIndex: 1000 }}
        >
          <IconX style={{ zIndex: 800 }} />
        </ButtonBase>
      </Box>
    );
  }

  const SearchSection = () => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [courseAnchorEl, setCourseAnchorEl] = useState(null);
    const [changeAnchorEl, setChangeAnchorEl] = useState(null);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [tempFilterOptions, setTempFilterOptions] = useState(filterOptions);

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCourse = (e) => {
      setCourseAnchorEl(e.currentTarget);
    };
    const handleCourseClose = () => {
      setCourseAnchorEl(null);
    };

    const handleChangeClassroom = (e) => {
      setChangeAnchorEl(e.currentTarget);
    };

    const handleChangeClose = () => {
      setChangeAnchorEl(null);
    };

    const handleFilterClick = (e) => {
      setFilterAnchorEl(e.currentTarget);
      setTempFilterOptions({...filterOptions});
    };

    const handleFilterClose = () => {
      setFilterAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const courseOpen = Boolean(courseAnchorEl);
    const changeOpen = Boolean(changeAnchorEl);

    return (
      <>
        <div style={{ display: "flex", gap: 10 }}>
          <Box sx={{ display: { xs: "none", md: "block" }, flex: 1 }}>
            <CustomQuickFilter
              variant="outlined"
              placeholder="학생 검색"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch
                      stroke={1.5}
                      size="1.5rem"
                      color={theme.palette.grey[500]}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ heigth: "60px" }}>
                    <ButtonBase 
                    sx={{ borderRadius: "12px" }} 
                    onClick={handleFilterClick}>
                      <HeaderAvatarStyle variant="rounded">
                        <IconAdjustmentsHorizontal stroke={1.5} size="1.0rem" />
                      </HeaderAvatarStyle>
                    </ButtonBase>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ButtonBase
            onClick={handleClick}
            sx={{ height: "60px", width: "100px", backgroundColor: "#f2f5fa" }}
          >
            <Typography fontSize={"1rem"}>Actions</Typography>
            <ArrowDropDown sx={{ marginLeft: "10px" }} />
          </ButtonBase>
          <Menu
            id="hamburger-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "150px", // 원하는 너비로 설정,
                fontSize: "1.7rem",
              },
            }}
            sx={{
              "& .MuiMenuItem-root": {
                fontSize: "1rem",
              },
            }}
            MenuListProps={{
              "aria-labelledby": "hamburger-button",
            }}
          >
            <MenuItem
              onClick={handleCourse}
              disabled={props.selectedRow.length === 0}
            >
              프로그램 수강등록
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={handleChangeClassroom}
              disabled={props.selectedRow.length === 0}
            >
              교실배정 변경
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={handleClose}
              disabled={props.selectedRow.length === 0}
            >
              메시지 발송
            </MenuItem>
          </Menu>
          <CourseAssign
            open={courseOpen}
            onClose={handleCourseClose}
            students={props.selectedRow}
          />
          <ChangeClassroom
            open={changeOpen}
            onClose={handleChangeClose}
            students={[props.selectedRow]}
          />
        </div>
        {/* Selected Students */}
        <div
          style={{
            overflowX: "auto",
            backgroundColor: alpha("#009eb2", 0.14),
            display: "flex",
          }}
        >
          {props.selectedRow.length > 0 && (
            <>
              <Box
                sx={{
                  borderLeft: `5px solid #009eb2`,

                  height: 60,
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <div></div>
                {props.selectedRow.map((row, index) => {
                  return (
                    <div key={`student${index}`}>
                      <SelectedStudents {...row} index={index} />
                    </div>
                  );
                })}
              </Box>
              <Box sx={{ width: 0 }}>{"ㅤ"}</Box>
            </>
          )}
        </div>
      </>
    );
  };

  function RenderStatus(props) {
    return <StatusChip type={"student"} value={props.value} />;
  }
  
  function RenderStudentName(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant='rounded'
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconUser size={36} />
        </Avatar>
        {props.value}
      </div>
    );
  }

  function RenderStudentGrade(props) {
    return props.value
  }

  function RenderHasMessage(props) {
    if (props.value === "true")
      return (
        <Avatar sx={{ bgcolor: "#fd726a", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
    else
      return (
        <Avatar sx={{ bgcolor: "#3a63de", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
  }

  const MenuButton = forwardRef((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [infoAnchorEl, setInfoAnchorEl] = React.useState(null);
    const [courseAnchorEl, setCourseAnchorEl] = React.useState(null);
    const [changeAnchorEl, setChangeAnchorEl] = React.useState(null);
    const [attend, setAttend] = React.useState("attend");
    const courseOpen = Boolean(courseAnchorEl);
    const changeOpen = Boolean(changeAnchorEl);
    const handleCourse = (e) => {
      setCourseAnchorEl(e.currentTarget);
      setAnchorEl(null);
    };
    const handleCourseClose = () => {
      setCourseAnchorEl(null);
    };
    const handleChangeClassroom = (e) => {
      setChangeAnchorEl(e.currentTarget);
    };
    const handleChangeClose = () => {
      setChangeAnchorEl(null);
    };
    const handleInfo = (event) => {
      setInfoAnchorEl(event.currentTarget.parentElement);
    };

    const handleInfoClose = () => {
      setInfoAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const infoOpen = Boolean(infoAnchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget.parentElement);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <div>
          <IconButton
            aria-label="more"
            size="small"
            sx={{ width: "40px", padding: 0 }}
            id="hamburger-button"
            aria-controls={open ? "hamburger-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            disableRipple={true}
            onClick={handleClick}
          >
            <IconDotsVertical color={grey[800]} />
          </IconButton>
          <Menu
            id="hamburger-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "hamburger-button",
            }}
          >
            <MenuItem onClick={handleInfo}>학생 상세정보 열기</MenuItem>
            <Divider />
            <MenuItem onClick={handleCourse}>프로그램 수강등록</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>수강중도 변경</MenuItem>
            <Divider />
            <MenuItem onClick={handleChangeClassroom}>교실배정 변경</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>메시지 발송</MenuItem>
          </Menu>
          <CourseAssign
            open={courseOpen}
            onClose={handleCourseClose}
            students={[props.row]}
          />
          <ChangeClassroom
            open={changeOpen}
            onClose={handleChangeClose}
            students={[props.row]}
          />
          <CRUDStudent open={infoOpen} onClose={handleInfoClose} readOnly={true} {...props.row} />
        </div>
      </>
    );
  });

  function RenderStudentMenu(props) {
    return <MenuButton {...props} />;
  }

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // Hide columns status and traderName, the other columns will remain visible
        UserId: false,
      },
    },
    pagination: {
      paginationModel: { pageSize: 25, page: 0 },
    },
  };

  function handleChange(item, e) {
    const selectedIds = new Set(item);
    const selectedRows = studentdata.filter((row) => selectedIds.has(row.id));
    props.setSelectedRow(selectedRows);
  }

  const columns = [
    {
      field: "UserId",
    },
    {
      field: "DisplayName",
      headerName: "이름",
      renderCell: RenderStudentName,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "GradeLevel",
      headerName: "학년",
      width: 40,
      renderCell: RenderStudentGrade,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "School",
      headerName: "학교",
      width: 60,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "hasMessage",
      headerName: "",
      minWidth: 20,
      width: 20,
      renderCell: RenderHasMessage,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "UserName",
      headerName: "아이디",
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "phone_num_own",
      headerName: "학생전번",
      headerAlign: "center",
      renderCell: (props) => {
        return props.row.PhoneOwn;
      },
      minWidth: 120,
      flex: 1,
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "phone_num_family",
      headerName: "보호자 전번",
      renderCell: (props) => {
        return props.row.PhoneParent;
      },
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "RegisterAt",
      headerName: "등록날짜",
      renderCell: (props) => {
        return props.value.slice(0, 10);
      },
      width: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "UserStatus",
      headerName: "상태",
      width: 70,
      renderCell: RenderStatus,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "menu",
      headerName: "",
      width: 70,
      renderCell: RenderStudentMenu,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const studenttable_data = {
    rows: studentdata,
    columns: columns,
    initialState: initialState,
    disableRowSelectionOnClick: true,
    checkboxSelection: true,
    onRowSelectionModelChange: handleChange,
    slots: {
      BaseCheckbox: React.forwardRef((props, _) => {
        return <Checkbox {...props} />;
      }),
      toolbar: SearchSection,
    },
    rowHeight: 90,
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <DataGridPro
         localeText={{
          noRowsLabel: '데이터가 없습니다',
        }}
          // experimentalFeatures={{ lazyLoading: true }}
          sx={{
            fontSize: "1rem",
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              px: "1px !important",
            },
          }}
          autoHeight
          {...studenttable_data}
          pagination
          apiRef={props.apiRef}
        />
      </div>
    </div>
  );
}

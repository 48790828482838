import StatusChip from "ui-component/StatusChip";
import { useLocation, useNavigate } from "react-router";
import * as React from "react";
import { IconBrandMiniprogram, IconUser } from "@tabler/icons";
import {
  Avatar,
  Divider,
  Box,
  ButtonBase,
  Typography,
  Select,
  FormControl,
  Checkbox
} from "@mui/material";
import { red, grey, common } from "@mui/material/colors";
import IconButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconDotsVertical } from "@tabler/icons";
import { forwardRef } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { AssignProgram, GetPrograms } from "data/ProgramDataAPI";



// assets
import { IconAdjustmentsHorizontal, IconX } from "@tabler/icons";
import { alpha } from "@mui/system";
import StudentCourseFilter from "ui-component/StudentCourseFilter";
import CourseDetail from "ui-component/modals/CourseDetail";
import ChangeClassroom from "ui-component/modals/ChangeClassroom";
import { GetStudentProgram } from "data/ProgramDataAPI";
import ProgramAssign from "ui-component/modals/ProgramAssign";


export default function O_StudentCourseTable(props) {
  const [programs, setPrograms] = useState([]);
  const [value, setValue] = useState({});
  const [student_program_data,setStudetnProgramData] = useState([]);
  React.useEffect(() => {
    GetPrograms().then((res) => {
      setPrograms(res);
      setValue(res[0]);
    });
  }, []);

  React.useEffect(()=>{
    GetStudentProgram(value).then((res)=>{
      setStudetnProgramData(res);
    })
  },[value])

  const reload = ()=>{
    const temp = value;
    setValue({});
    setTimeout(()=>{
      setValue(temp);
    },100);
    
  }

  const handleXClick = (e, index, id) => {
    const list = props.selectedRow.filter((_, i) => i !== index);
    props.setSelectedRow(list);
    props.apiRef.current.selectRow(id, false);
  };

  function SelectedStudents(props) {
    return (
      <Box
        key={`student${props.index}`}
        sx={{
          borderRadius: "2px",
          backgroundColor: "#e3e6eb",
          padding: "2px",
          width: "114px",
          height: "45px",
        }}
      >
        {props.DisplayName +
          "(" +
          (props.GradeLevel+
            "," +
            (props.Gender === "M" ? "남" : "여") +
            ")")}
        <ButtonBase
          id={`xButton${props.index}`}
          onClick={(e) => handleXClick(e, props.index, props.id)}
          sx={{ marginBottom: 3, width: 14, height: 14, zIndex: 1000 }}
        >
          <IconX style={{ zIndex: 800 }} />
        </ButtonBase>
      </Box>
    );
  }

  const SearchSection = () => {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [changeAnchorEl, setChangeAnchorEl] = useState(null);
    const [filterAnchorEl,setFilterAnchorEl] = useState(null);
    const [assignAnchorEl,setAssignAnchorEl] = useState(null);


    const handleFilter = (e)=>{
      setFilterAnchorEl(e.currentTarget)
    }
    
    const handleFilterClose=()=>{
      setFilterAnchorEl(null);
    }

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (e) => {
      setValue(e.target.value);
    };

    const handleChangeClassroom= (e)=>{
      setChangeAnchorEl(e.currentTarget);
    }

    const handleChangeClose=()=>{
      setChangeAnchorEl(null);
    }

    const handleAssign = (e)=>{
      setAssignAnchorEl(e.currentTarget);
      setAnchorEl(null);
    }

    const handleAssignClose = ()=>{
      setAssignAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const filterOpen = Boolean(filterAnchorEl);
    const changeOpen = Boolean(changeAnchorEl);
    const assignOpen = Boolean(assignAnchorEl);


    return (
      <>
        <div style={{ display: "flex", gap: 10 }}>
          <Box sx={{ display: { xs: "none", md: "block" }, flex: 1 }}>
            <FormControl fullWidth>
              <Select value={value} onChange={handleChange} SelectDisplayProps={{style:{padding:10}}}>
                {programs.map((program, index) => {
                  return (
                    <MenuItem value={program}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <Avatar
                          variant="square"
                          sx={{
                            width: "40px",
                            height: "40px",
                            backgroundColor: common.white,
                            color: grey[600],
                            border: "1px solid",
                          }}
                        >
                          <IconBrandMiniprogram size={36} />
                        </Avatar>
                        <Typography fontSize={'1.1rem'}>{program.ProgramTitle}</Typography>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <ButtonBase
          sx={{
            height: "60px",
            width: "60px",
            backgroundColor: "#ccf3f4",
            color: common.black,
          }}
          onClick={handleFilter}
        >
          <IconAdjustmentsHorizontal stroke={1.5} size="1.7rem" />
        </ButtonBase>
          <ButtonBase
            onClick={handleClick}
            sx={{ height: "60px", width: "100px", backgroundColor: "#f2f5fa" }}
          >
            <Typography fontSize={"1rem"}>Actions</Typography>
            <ArrowDropDown sx={{ marginLeft: "10px" }} />
          </ButtonBase>
          <Menu
            id="hamburger-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "220px", // 원하는 너비로 설정,
                fontSize: "1.7rem",
              },
            }}
            sx={{
              "& .MuiMenuItem-root": {
                fontSize: "1.1rem",
              },
            }}
            MenuListProps={{
              "aria-labelledby": "hamburger-button",
            }}
          >
            <MenuItem onClick={handleClose}>프로그램 상세정보</MenuItem>
            <Divider />
            <MenuItem onClick={handleAssign}>학생프로그램수강 추가</MenuItem>
            <Divider />
            <MenuItem onClick={handleChangeClassroom} disabled={props.selectedRow.length===0}>{'(선택) 교실배정 변경'}</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose} disabled={props.selectedRow.length===0}>{'(선택) 수강완강 변경'}</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose} disabled={props.selectedRow.length===0}>{'(선택) 수강취소 변경'}</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose} disabled={props.selectedRow.length===0}>{'(선택) 수강휴강 변경'}</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose} disabled={props.selectedRow.length===0}>{'(선택) 메시지 발송'}</MenuItem>
          </Menu>
          <StudentCourseFilter anchorEl={filterAnchorEl} open={filterOpen} handleClose={handleFilterClose}/>
          <ChangeClassroom open={changeOpen} onClose={handleChangeClose} students={props.selectedRow} programs={programs} reload={reload}/>
          <ProgramAssign open={assignOpen} onClose={handleAssignClose} programs={programs} reload={reload}/>
          
        </div>
         {/* Selected Students */}
         <div
          style={{
            overflowX: "auto",
            backgroundColor: alpha("#009eb2", 0.14),
            display: "flex",
          }}
        >
          {props.selectedRow.length > 0 && (
            <>
              <Box
                sx={{
                  borderLeft: `5px solid #009eb2`,

                  height: 60,
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <div></div>
                {props.selectedRow.map((row, index) => {
                  return (
                    <div key={`student${index}`}>
                      <SelectedStudents {...row} index={index} />
                    </div>
                  );
                })}
              </Box>
              <Box sx={{ width: 0 }}>{"ㅤ"}</Box>
            </>
          )}
        </div>
      </>
    );
  };

  function RenderStatus(props) {
    return <StatusChip type={'student_program'} value={props.value} />;
  }

  function RenderStudnetName(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant="square"
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconUser size={36} />
        </Avatar>
        {props.value}
      </div>
    );
  }

  function RenderHasMessage(props) {
    if (props.value === "true")
      return (
        <Avatar sx={{ bgcolor: "#fd726a", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
    else
      return (
        <Avatar sx={{ bgcolor: "#3a63de", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
  }

  const MenuButton = forwardRef((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [detailAnchorEl,setDetailAnchorEl] = React.useState(null);   
    const [changeAnchorEl,setChangeAnchorEl] = React.useState(null);   
    const open = Boolean(anchorEl);
    const detailOpen = Boolean(detailAnchorEl)
    const navigate = useNavigate();
    const location = useLocation();
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget.parentElement);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleDetail=(e)=>{
      setDetailAnchorEl(e.currentTarget.parentElement);
    }
    const handleDetailClose = ()=>{
      setDetailAnchorEl(null);
    }

    const changeOpen = Boolean(changeAnchorEl);
    
    const handleChangeClassroom = (e)=>{
      setChangeAnchorEl(e.currentTarget);
    }
    const handleChangeClose = ()=>{
      setChangeAnchorEl(null);
    }

    const handleLectures=()=>{

       navigate(location.pathname+'/studentlectures/'+props.row.id)
    }
    return (
      <div>
        <IconButton
          aria-label="more"
          size="small"
          sx={{ width: "40px", padding: 0 }}
          id="hamburger-button"
          aria-controls={open ? "hamburger-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          disableRipple={true}
          onClick={handleClick}
        >
          <IconDotsVertical color={grey[800]} />
        </IconButton>
        <Menu
          id="hamburger-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '200px', // 원하는 너비로 설정,
            }
          }}
          sx={{
            "& .MuiMenuItem-root": {
              fontSize: "1.1rem",
            },
          }}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
        >
          <MenuItem onClick={handleDetail}>수강 상세정보</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>메시지 발송</MenuItem>
          <Divider />
          <MenuItem onClick={handleLectures}>학생 단원목록 열기</MenuItem>
          <Divider />
          <MenuItem onClick={handleChangeClassroom}>교실배정 변경</MenuItem>
        </Menu>
        <CourseDetail {...props} open={detailOpen} onClose={handleDetailClose}/>
        <ChangeClassroom open={changeOpen} onClose = {handleChangeClose} students={[props.row]} reload={reload}/>
      </div>
    );
  });

  function RenderStudentCourseMenu(props) {
    return <MenuButton {...props}/>;
  }

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // Hide columns status and traderName, the other columns will remain visible
        id: false,
      },
    },
    pagination: {
      paginationModel: { pageSize: 25, page: 0 },
    },
  };

  function handleChange(item, e) {
    const selectedIds = new Set(item);
    const selectedRows =  student_program_data.filter((row) => selectedIds.has(row.id));
    props.setSelectedRow(selectedRows);
  }

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "DisplayName",
      headerName: "이름",
      renderCell: RenderStudnetName,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "GradeLevel",
      headerName: "학년",
      width: 40,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "School",
      headerName: "학교",
      width: 60,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "hasMessage",
      headerName: "",
      minWidth: 20,
      width: 20,
      renderCell: RenderHasMessage,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ProgramTitle",
      headerName: "수강단원",
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
    },
    {
      field: "student_lecture",
      headerName: "",
      width: 70,
      renderCell: ()=>{return ''},
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "classname",
      renderCell:(props)=>{return props.row.ClassroomTitle?props.row.ClassroomTitle : <Typography variant="h5" color={red[600]}>교실등록 필요</Typography>;},
      headerName: "교실",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "recent_class",
      renderCell: (props)=>{return props.row.RecentLectureAt>'0001-01-02' ? props.row.RecentLectureAt.split('T')[0] : null;},
      headerName: "최근수업",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "CreatedAt",
      renderCell : (props)=>{return props.row.ProgramAssignAt>'0001-01-02'? props.row.ProgramAssignAt.split('T')[0]:null;},
      headerName: "수강등록",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "UserprogramStatus",
      headerName: "상태",
      width: 70,
      renderCell: RenderStatus,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "menu",
      headerName: "",
      width: 70,
      renderCell: RenderStudentCourseMenu,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const student_course_table_data = {
    rows: student_program_data,
    columns: columns,
    initialState: initialState,
    disableRowSelectionOnClick: true,
    checkboxSelection: true,
    components: { toolbar: SearchSection },
    onRowSelectionModelChange: handleChange,
    slots: {
      BaseCheckbox: React.forwardRef((props, _) => {
        return <Checkbox {...props} />;
      }),
      toolbar: SearchSection,
    },
    rowHeight: 90,
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <DataGridPro
         localeText={{
          noRowsLabel: '데이터가 없습니다',
        }}
          // experimentalFeatures={{ lazyLoading: true }}
          sx={{
            fontSize: "1rem",
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              px: "1px !important",
            },
          }}
          autoHeight
          {...student_course_table_data}
          pagination
          apiRef={props.apiRef}
        />
      </div>
    </div>
  );
}

import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, ThemeProvider, Typography, createTheme } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { common } from '@mui/material/colors';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item, index, last }) => {
  const baseTheme = useTheme();
  const theme = createTheme({...baseTheme,
  typography:{
    ...baseTheme.typography,
    menuCaption:{
      ...baseTheme.typography.menuCaption,
      color:'#fff'
    }
  }})
  // menu list collapse & items
  const items = item.children?.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case 'item':
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });
  const Icon = item.icon;
  return (
    <ThemeProvider theme={theme} >
      <List
        style={{color:common.white, width:'100%', paddingBottom:'20px'}}
        subheader={
          item.title && (
            <div style={{display:'flex', gap:'6px', padding:'2px'}}>
            {item.icon && <Icon size="2rem" />}
            <Typography variant='body' sx={{ ...theme.typography.menuCaption, margin:0, fontSize:'1rem', alignItems:'center', display:'flex' }} align='center' display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
            </div>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      
    </ThemeProvider>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from 'App';
import {store} from 'store';
import 'assets/scss/style.scss';
import { LicenseInfo} from '@mui/x-license-pro'

const MUI_KEY = process.env.REACT_APP_LICENSE_KEY
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
LicenseInfo.setLicenseKey(MUI_KEY);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import * as React from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import MainCard from "ui-component/cards/MainCard";
import { useState } from "react";
import { Typography, Grid, Divider } from "@mui/material";
import MainMenuButton from "ui-component/buttons/MainMenuButton";

export default function GridTable(props) {
  const [selectedRow,setSelectedRow] = useState([]);
  const apiRef= useGridApiRef();

  return (
    <MainCard>
      <props.dataTable apiRef={apiRef} selectedRow={selectedRow} setSelectedRow={setSelectedRow}/>
    </MainCard>
  );
}

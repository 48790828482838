import StatusChip from "ui-component/StatusChip";
import * as React from "react";
import { IconBrandPython, IconPlus, IconUser } from "@tabler/icons";
import {
  Avatar,
  Divider,
  Box,
  ButtonBase,
  InputAdornment,
  Typography,
  Checkbox,
} from "@mui/material";
import { grey, common } from "@mui/material/colors";
import IconButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconDotsVertical } from "@tabler/icons";
import { forwardRef } from "react";
import {
  GridToolbarQuickFilter,
  DataGridPro,
} from "@mui/x-data-grid-pro";
import { useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";
import CRUDClassroom from "ui-component/modals/CRUDClassroom";

// material-ui
import { useTheme, styled } from "@mui/material/styles";

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { alpha, shouldForwardProp } from "@mui/system";
import {DeleteClassroom, GetClassrooms} from "data/ClassroomDataAPI";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import DeleteElement from "ui-component/modals/DeleteElement";




export default function I_ClassroomTable(props) {
  const [classroomdata,setClassroomdata] = useState([])
  const weekday = props.weekday;
  useEffect(()=>{
    GetClassrooms(weekday).then((Classrooms)=>{
      setClassroomdata(Classrooms);
    })
  },[weekday])

  const CustomQuickFilter = styled(GridToolbarQuickFilter, { shouldForwardProp })(
    ({ theme }) => ({
      width: "100%",
      fontSize: "1.0rem",
      "& input": {
        background: "transparent !important",
        paddingLeft: "4px !important",
        height:'29px',
        fontSize:'1.0rem',
        "&::placeholder": {
          fontSize: "1.0rem",
          color: grey[800],
        },
      },
      [theme.breakpoints.down("lg")]: {
        width: "90%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginLeft: 4,
        background: "#fff",
      },
    })
  );

  const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
    ({ theme }) => ({
      ...theme.typography.commonAvatar,
      ...theme.typography.mediumAvatar,
      background: theme.palette.grey[100],
      color: theme.palette.grey[500],
      "&:hover": {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light,
      },
    })
  );

  const handleXClick = (e, index, id) => {
    const list = props.selectedRow.filter((_, i) => i !== index);
    props.setSelectedRow(list);
    props.apiRef.current.selectRow(id, false);
  };

  function SelectedClassrooms(props) {
    console.log(props);
    
    return (
      <Box
      key={`classroom${props.index}`}
      sx={{
        borderRadius: "2px",
        backgroundColor: "#e3e6eb",
        padding: "10px",
        minWidth: "100px",
        height: "45px",
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
      >
      {props.ClassroomTitle}

      <ButtonBase
        id={`xButton${props.index}`}
        onClick={(e) => handleXClick(e, props.index, props.id)}
        sx={{
        position: "absolute",
        top: "2px",
        right: "2px",
        width: 14,
        height: 14,
        zIndex: 1000,
        }}
      >
        <IconX style={{ zIndex: 800 }} />
      </ButtonBase>
      </Box>
    );
  }

  const SearchSection = (props) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [courseAnchorEl,setCourseAnchorEl] = useState(null);
    const [changeAnchorEl,setChangeAnchorEl] = useState(null);
    // const [createMenuAnchorEl,setCreateMenuAnchorEl] = useState(null);
    const [createAnchorEl,setCreateAnchorEl] = useState(null);

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    // const handleCreateMenu = (e)=>{
    //   setCreateMenuAnchorEl(e.currentTarget);
    // }

    const handleCourse = (e)=>{
      setCourseAnchorEl(e.currentTarget);
    }
    // const handleCourseClose = ()=>{
    //   setCourseAnchorEl(null);
    // }

    const handleChangeClassroom=(e)=>{
      setChangeAnchorEl(e.currentTarget);
    }

    // const handleChangeClose=()=>{
    //   setChangeAnchorEl(null);
    // }

    const handleCreate = (e)=>{
      setCreateAnchorEl(e.currentTarget)
    }

    const handleCreateClose = ()=>{
      setCreateAnchorEl(null);
    }


    const open = Boolean(anchorEl);
    const courseOpen = Boolean(courseAnchorEl);
    const changeOpen = Boolean(changeAnchorEl);
    const createOpen = Boolean(createAnchorEl);

    return (
      <>
        <div style={{ display: "flex", gap: 10 }}>
          <Box sx={{ display: { xs: "none", md: "block" }, flex: 1 }}>
            <CustomQuickFilter
              variant="outlined"
              placeholder="교실 검색"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch
                      stroke={1.5}
                      size="1.5rem"
                      color={theme.palette.grey[500]}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{heigth:'60px'}}>
                    <ButtonBase sx={{ borderRadius: "12px" }}>
                      <HeaderAvatarStyle variant="rounded">
                        <IconAdjustmentsHorizontal stroke={1.5} size="1.0rem" />
                      </HeaderAvatarStyle>
                    </ButtonBase>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ButtonBase
            onClick={handleClick}
            sx={{ height: "60px", width: "100px", backgroundColor: "#f2f5fa" }}
          >
            <Typography fontSize={"1rem"}>Actions</Typography>
            <ArrowDropDown sx={{ marginLeft: "10px" }} />
          </ButtonBase>
          <Menu
            id="hamburger-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "150px", // 원하는 너비로 설정,
                fontSize: "1.7rem",
              },
            }}
            sx={{
              "& .MuiMenuItem-root": {
                fontSize: "1rem",
              },
            }}
            MenuListProps={{
              "aria-labelledby": "hamburger-button",
            }}
          >
            <MenuItem onClick={handleCourse} disabled={props.selectedRow.length===0}>교실 수강등록</MenuItem>
            <Divider />
            <MenuItem onClick={handleChangeClassroom} disabled={props.selectedRow.length===0}>교실배정 변경</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose} disabled={props.selectedRow.length===0}>메시지 발송</MenuItem>
          </Menu>
          
          <ButtonBase
          sx={{
            height: "60px",
            width: "60px",
            backgroundColor: "#3a63de",
            color: common.white,
          }}
          onClick={handleCreate}
        >
          <IconPlus />
        </ButtonBase>
        </div>
        {/* Selected Classrooms */}
        <div
          style={{
            overflowX: "auto",
            backgroundColor: alpha("#009eb2", 0.14),
            display: "flex",
          }}
        >
          {props.selectedRow.length > 0 && (
            <>
              <Box
                sx={{
                  borderLeft: `5px solid #009eb2`,

                  height: 60,
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <div></div>
                {props.selectedRow.map((row, index) => {
                  return (
                    <div key={`classroom${index}`}>
                      <SelectedClassrooms {...row} index={index} />
                    </div>
                  );
                })}
              </Box>
              <Box sx={{ width: 0 }}>{"ㅤ"}</Box>
            </>
          )}
        </div>
        <CRUDClassroom {...props} open={createOpen} onClose = {handleCreateClose} handleSave = {()=>{
          handleCreateClose();
          GetClassrooms(weekday).then((Classrooms) => {
            setClassroomdata(Classrooms);
          });
        
        }}/>
      </>
    );
  };


  function RenderStatus(props) {
    return <StatusChip type={"classroom"} value={props.value}/>
  }

  function RenderClassroomName(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant='rounded'
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconBrandPython size={36} />
        </Avatar>
        {props.value}
      </div>
    );
  }

  function RenderAdmin(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant='rounded'
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconUser size={36} />
        </Avatar>
        {props.row.AdminDisplayName}
      </div>
    );
  }

  function RenderClassroomGrade(props) {
    return props.value
  }

  function RenderHasMessage(props) {
    if (props.value === "true")
      return (
        <Avatar sx={{ bgcolor: "#fd726a", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
    else
      return (
        <Avatar sx={{ bgcolor: "#3a63de", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
  }

  const MenuButton = forwardRef((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [minianchorEl, setminiAnchorEl] = React.useState(null);
    const [infoAnchorEl, setInfoAnchorEl] = React.useState(null);
    const [courseAnchorEl, setCourseAnchorEl] = React.useState(null);
    const [changeAnchorEl, setChangeAnchorEl] = React.useState(null);
    const [deleteAnchorEl, setDeleteAnchorEl] = React.useState(null);
    const [attend, setAttend] = React.useState("attend");
    const courseOpen = Boolean(courseAnchorEl);
    const changeOpen = Boolean(changeAnchorEl);
    const infoOpen = Boolean(infoAnchorEl);
    const deleteOpen = Boolean(deleteAnchorEl);
    const location = useLocation();
    const navigate = useNavigate();

    const handleInfo = (e) => {
      setInfoAnchorEl(e.currentTarget);
      setAnchorEl(null);
    };
    const handleInfoClose = () => {
      setInfoAnchorEl(null);
    };
    const handleInfoSave = () => {
      setInfoAnchorEl(null);
      GetClassrooms(weekday).then((Classrooms) => {
        setClassroomdata(Classrooms);
      });
    };
    const handleLecture = () => {
      navigate(location.pathname + "/lectures/" + props.row.id);
    };
    // const handleCourseClose = () => {
    //   setCourseAnchorEl(null);
    // };
    // const handleChangeClassroom = (e) => {
    //   setChangeAnchorEl(e.currentTarget);
    // };
    // const handleChangeClose = () => {
    //   setChangeAnchorEl(null);
    // };
    const handleDelete = (e) => {
      setDeleteAnchorEl(e.currentTarget);
      setAnchorEl(null);
    };

    const handleDeleteClose = () => {
      setDeleteAnchorEl(null);
    };

    const handleSave =  () => {
      GetClassrooms(weekday).then((Classrooms) => {
        setClassroomdata(Classrooms);
        console.log(Classrooms);
      });
      setInfoAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget.parentElement);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          aria-label="more"
          size="small"
          sx={{ width: "40px", padding: 0 }}
          id="hamburger-button"
          aria-controls={open ? "hamburger-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          disableRipple={true}
          onClick={handleClick}
        >
          <IconDotsVertical color={grey[800]} />
        </IconButton>
        <Menu
          id="hamburger-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
        >
          <MenuItem onClick={handleInfo}>교실 정보</MenuItem>
          <Divider />
          <MenuItem onClick={handleLecture}>수업날짜 편집</MenuItem>
          <Divider />
          <MenuItem onClick={handleDelete}>교실 삭제</MenuItem>
        </Menu>
        <CRUDClassroom
          open={infoOpen}
          onClose={handleInfoClose}
          handleSave={handleSave}
          {...props.row}
        />
        <DeleteElement open={deleteOpen} onClose={handleDeleteClose} onDelete={async()=>{
          await DeleteClassroom(props.row.id);
          handleDeleteClose();
          GetClassrooms(weekday).then((Classrooms) => {
            setClassroomdata(Classrooms);
          });
        }} />
      </div>
    );
  });

  function RenderClassroomMenu(props) {
    return <MenuButton {...props} />;
  }

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // Hide columns status and traderName, the other columns will remain visible
        ID: false,
      },
    },
    pagination: {
      paginationModel: { pageSize: 25, page: 0 },
    },
  };

  function handleChange(item, e) {
    const selectedIds = new Set(item);
    const selectedRows = classroomdata.filter((row) => selectedIds.has(row.id));
    props.setSelectedRow(selectedRows);
  }

  const columns = [
    {
      field: "ID",
    },
    {
      field: "ClassroomTitle",
      headerName: "교실",
      renderCell: RenderClassroomName,
      minWidth: 160,
      headerAlign: "center",
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "Schedule",
      headerName: "수업스케쥴",
      renderCell: (props) => {
        return (
          props.row.StartTime.slice(0, 2) +
          ":" +
          props.row.StartTime.slice(2, 4) +
          "~" +
          props.row.EndTime.slice(0, 2) +
          ":" +
          props.row.EndTime.slice(2, 4)
        );
      },
      width: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "time",
      headerName: "시간",
      renderCell: (props) => {
        //분 단위로 EndAt- StartAt 계산해서 숫자로 리턴

        const start_time =
          Number(props.row.StartTime.slice(0, 2)) * 60 +
          Number(props.row.StartTime.slice(2, 4));
        const end_time =
          Number(props.row.EndTime.slice(0, 2)) * 60 +
          Number(props.row.EndTime.slice(2, 4));
        return end_time - start_time + "분";
      },
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "Students",
      headerName: "학생",
      minWidth: 160,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "Progress",
      headerName: "진행",
      width: 70,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "AdminName",
      headerName: "관리샘",
      headerAlign: "center",
      renderCell: RenderAdmin,
      minWidth: 120,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "StartAt",
      headerName: "시작날짜",
      renderCell: (props) => {
        return props.value.slice(0, 10);
      },
      width: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "EndAt",
      headerName: "종료날짜",
      renderCell: (props) => {
        return props.value.slice(0, 10);
      },
      width: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "Status",
      headerName: "상태",
      width: 70,
      renderCell: RenderStatus,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "menu",
      headerName: "",
      width: 70,
      renderCell: RenderClassroomMenu,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const classroomtable_data = {
    rows: classroomdata,
    columns: columns,
    initialState: initialState,
    disableRowSelectionOnClick: true,
    checkboxSelection: true,
    onRowSelectionModelChange: handleChange,
    slots: {
      BaseCheckbox: React.forwardRef((props, _) => {
        return <Checkbox {...props} />;
      }),
      toolbar: ()=>{return <SearchSection {...props}/>},
    },
    rowHeight: 90,
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <DataGridPro
          // experimentalFeatures={{ lazyLoading: true }}
          sx={{
            fontSize: "1rem",
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              px: "1px !important",
            },
          }}
          localeText={{
            noRowsLabel: '데이터가 없습니다',
          }}
          autoHeight
          {...classroomtable_data}
          pagination
          apiRef={props.apiRef}
        />
      </div>
    </div>
  );
}

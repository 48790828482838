const API_URL = process.env.REACT_APP_API_URL

export async function LoginRequest(username, password){
    const res = await fetch(API_URL + "/auth/login", {
        method: "POST",
        body: JSON.stringify({
            user_name: username,
            password: password,
        }),
    }).catch((error) => {
        return error;
    });
    const result = await res.json();
    return result;
}

export async function VerifyToken(token){
    console.log(token);
    const res = await fetch(API_URL + "/token/verify", {
        method: "POST",
        body: JSON.stringify({
            access_token: token,
        }),
    }).catch((error) => {
        return error;
    });
    const result = await res.json();
    return result;
}

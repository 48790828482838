import * as React from "react";
import MainCard from "ui-component/cards/MainCard";
import {
  Typography,
  Grid,
  Divider,
  Tabs,
  Tab,
  Box,
  Modal,
  Avatar,
  TextField,
  InputLabel,
  MenuItem,
  FilledInput,
  Select,
  Button,
  IconButton,
  ButtonBase,
  FormControl,
  Snackbar,
  Alert,
  InputAdornment,
} from "@mui/material";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import MainMenuButton from "ui-component/buttons/MainMenuButton";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { TabContext, TabPanel } from "@mui/lab";
import {
  IconBrandPython,
  IconCloudUpload,
  IconDotsVertical,
  IconEye,
  IconSearch,
  IconUser,
} from "@tabler/icons";
import StatusChip from "ui-component/StatusChip";
import { common, grey } from "@mui/material/colors";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { createTheme, height } from "@mui/system";
import theme from "themes";
import { TextFieldsRounded } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { GetImage, GetImages, UploadImage } from "data/ImageDataAPI";
import { useEffect } from "react";
import { GetTeacher, GetTeachers } from "data/TeacherDataAPI";

function ProgressingInfo(props) {
  console.log(props);
  const [teachers, setTeachers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    GetTeachers().then((res) => {
      setTeachers(res);
    }
    );
  }
  , []);
  console.log(props.formData);
  return (
    <Box sx={{padding:3}}> 
    <Grid container spacing={3} sx={{ overflow: "auto", maxHeight: 800 }}>
      <Grid item xs={9}>
        <TextField
          label="이름"
          variant="filled"
          defaultValue={props.formData.DisplayName}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.1rem" }, readOnly: true}}
          fullWidth
          onChange={(e) => {
            props.handleChange("DisplayName", e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <IconUser size={48} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="프로그램"
          variant="filled"
          defaultValue={props.formData.ProgramTitle}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.1rem" }, readOnly: true}}
          fullWidth
          onChange={(e) => {
            props.handleChange("ProgramTitle", e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="수업날짜시간"
          variant="filled"
          value = {props.formData.TheDate.split('T')[0] + " " + dayjs(props.formData.TheStartTime).format('HH:mm')+ " ~ " + dayjs(props.formData.TheEndTime).format('HH:mm')}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.1rem" }, readOnly: true }}
          
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          variant="filled"
          sx={{
            textAlign: "right",
            width: "100%",
            height: "100%",
            "& .MuiSelect-select": {
              paddingLeft: "12px",
            },
          }}
        >
          <InputLabel id="TeacherLabel" sx={{ fontSize: "1rem" }}>
            수업교사
          </InputLabel>
          <Select
            labelId="Teacher_label"
            id="Teacher"
            onChange={(e) => {
              props.handleChange("Teacher", e.target.value);
              props.handleChange("TeacherId", e.target.value.UserId);
            }}
            value={props.formData.Teacher}
            sx={{
              height: "100%",
              "& .MuiMenu-list": {
                width: "40%",
              },
            }}
            renderValue={(value) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    marginRight: 20,
                    gap: 10,
                  }}
                >
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: common.white,
                      color: grey[600],
                      border: "1px solid",
                    }}
                  >
                    <IconUser size={36} />
                  </Avatar>
                  {value.DisplayName}
                </div>
              );
            }}
          >
            <TextField
              variant="outlined"
              placeholder="찾기"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch stroke={1.5} size="1.5rem" color={grey[500]} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              fullWidth
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onBlur={(e) => {
                e.preventDefault();
                e.target.focus();
              }}
            />
            {teachers
              .filter((teacher) => {
                //영어검색과 한글 검색이 모두 되어야 하고, searchValue가 포함된 값은 true, searchValue 가 공백일 경우는 전체를 보여줌.
                if (
                  teacher.DisplayName.toLowerCase().includes(
                    searchValue.toLowerCase()
                  ) ||
                  teacher.DisplayName.includes(searchValue) ||
                  searchValue === ""
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              .map((teacher, index) => {
                return (
                  <MenuItem
                    sx={{ justifyContent: "left" }}
                    value={teacher}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 10,
                      }}
                    >
                      <Avatar
                        variant="rounded"
                        sx={{
                          width: "40px",
                          height: "40px",
                          backgroundColor: common.white,
                          color: grey[600],
                          border: "1px solid",
                        }}
                      >
                        <IconUser size={36} />
                      </Avatar>
                      {teacher.DisplayName}
                    </div>
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="교실"
          variant="filled"
          defaultValue={props.formData.ClassroomTitle}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.1rem" }, readOnly: true}}
          fullWidth
          onChange={(e) => {
            props.handleChange("ClassroomTitle", e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          variant="filled"
          sx={{
            textAlign: "right",
            width: "100%",
            height: "100%",
            "& .MuiSelect-select": {
              paddingLeft: "12px",
            },
          }}
        >
          <InputLabel id="StatusLabel" sx={{ fontSize: "1rem" }}>
            상태
          </InputLabel>
          <Select
            labelId="Status_label"
            id="Status"
            onChange={(e) => {
              props.handleChange("Status", e.target.value);
            }}
            value={props.formData.Attendance}
            sx={{ height: "100%" }}
            renderValue={(value) => (
              <StatusChip type={"attendance"} value={value} />
            )}
          >
            <MenuItem sx={{ justifyContent: "right" }} value="on">
              <StatusChip type={"attendance"} value={"attend"} />
            </MenuItem>
            <MenuItem sx={{ justifyContent: "right" }} value="waiting">
              <StatusChip type={"attendance"} value={"leave"} />
            </MenuItem>
            <MenuItem sx={{ justifyContent: "right" }} value="off">
              <StatusChip type={"attendance"} value={"waiting"} />
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TextareaAutosize
            style={{
              padding: 10, 
              width: '630px',
              minWidth: '200px',
              fontSize: "1.0rem",
              fontFamily: `'Roboto',sans-serif`,
            }}
            placeholder="출석메모 : 학교행사로 결석"
            minRows={5}
            defaultValue={props.formData.AttendanceMemo}
            onChange={(e) => {
              props.handleChange("AttendanceMemo", e.target.value);
            }}
          ></TextareaAutosize>
        </Box>
      </Grid>
      </Grid>
      
    </Box>
  );
}



function Image(props) {
  const [images, setImages] = useState(props.formData.Images || []);
  const {getRootProps, getInputProps,isDragActive,open} = useDropzone({
    accept: 'image/*',
    onDragOver: () => {
    },
    onDrop: async (acceptedFiles) => {
      await Promise.all(
        acceptedFiles.map(async (file) => {
          await UploadImage(file, props.formData.GuidId);
        })
      );
      const res = await GetImages(props.formData.GuidId);
      setImages(res);
    },
  });

  useEffect(() => {
    GetImages(props.formData.GuidId).then((res) => {
      if(res === null) res = [];
      else if(res.length === undefined){
        const temp = [];
        temp.push(res);
        res = temp;
      }
      setImages(res);
    });
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          fullWidth
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 3,
            backgroundColor: isDragActive?"rgba(0,0,0,0.2)": "rgba(0,0,0,0.05)",
            padding: 5,
          }}

          onClick={(e)=>{
            e.preventDefault();
            open();
          }}
         {...getRootProps()}
          
        >
          <input {...getInputProps()} />
          <IconCloudUpload size={36} />
          <Typography sx={{ fontSize: "1rem" }}>
            Drag and drop or Browse to upload
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Box sx ={{display:"flex",gap:3,flexDirection:"column",height: 400,overflowY : 'auto', width:"100%"}}>
      {images.map((image, index) => {
        return (
            <img
              src={process.env.REACT_APP_API_URL+"/image/?filepath="+props.formData.GuidId+"/"+image}
              alt={process.env.REACT_APP_API_URL+"/image/?filepath="+props.formData.GuidId+"/"+image}
              style={{ width: "30%" }}
            />
            );
          }
          )}     
          </Box>
        </Grid>

     
    </Grid>
  );
}

function ProgressingProcess(props) {
  return (
    <Box>
      진도처리
    </Box>
  );
}

function ProgressingLetter(props) {
  return (
    <Box>
      <TextField
        label="수업후기"
        variant="filled"
        defaultValue={props.formData.ProgressingExams}
        color=""
        sx={{ "& label": { fontSize: "1rem" } }}
        InputProps={{ style: { fontSize: "1.0rem" } }}
        onChange={(e) => {
          props.handleChange("ProgressingExams", e.target.value);
        }}
        fullWidth
      />
    </Box>
  );
}

function Memo() {
  return <Box>메모</Box>;
}
function Quiz() {
  return <Box>퀴즈</Box>;
}

const TabList = [
  {
    label: "진도처리",
    component: (props) => {
      return <ProgressingProcess {...props} />;
    },
  },
  {
    label: "수업후기",
    component: ProgressingLetter,
  },
  {
    label: "수업사진/영상",
    component: Image,
  },
  { label: "메모", component: Memo },
  { label: "평가/퀴즈", component: Quiz },
];

export default function CRUDProgressing(props) {
    const [formData, setFormData] = useState({
      GuidId: props.GuidId || uuidv4(),
      DisplayName : props.DisplayName,
      ProgramTitle : props.ProgramTitle,
      TheDate : props.TheDate,
      TheStartTime : props.TheStartTime,
      TheEndTime : props.TheEndTime,
      TeacherID : props.TeacherID,
      Teacher: {DisplayName: props.TeacherDisplayName, UserId: props.TeacherID},
      TeacherDisplayName : props.TeacherDisplayName,
      Attendance : props.Attendance,
      ClassroomTitle : props.ClassroomTitle,
      AttendanceMemo : props.AttendanceMemo
    });
  
    function formInit() {
      setFormData({
      DisplayName : props.DisplayName,
      ProgramTitle : props.ProgramTitle,
      TheDate : props.TheDate,
      TheStartTime : props.TheStartTime,
      TheEndTime : props.TheEndTime,
      TeacherID : props.TeacherID,
      Teacher: {DisplayName: props.TeacherDisplayName, UserId: props.TeacherID},
      TeacherDisplayName : props.TeacherDisplayName,
      Attendance : props.Attendance,
      ClassroomTitle : props.ClassroomTitle,
      AttendanceMemo : props.AttendanceMemo  
      });
    }
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [value, setValue] = useState(TabList[0].label);
  const [errorField, setErrorField] = useState("");
  const errorOpen = Boolean(errorField);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1400,
    height: 800,
    display: "flex",
    justifyContent: "space-between",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleError = (field) => {
    setErrorField(field);
  };

  const handleErrorClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorField("");
  };

  function checkForm() {
    for (const key in formData) {
      if (formData[key] == "") {
        handleError(key);
        return 0;
      }
    }
    return 1;
  }

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.onClose();
        formInit();
      }}
    >
      <Grid container sx={style}>
        <Grid item xs={6}>
          <ProgressingInfo formData = {formData}  handleChange={handleInputChange}/>
        </Grid>
        <Grid item xs={6} >

      <TabContext value={value}>
          <div style={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Tab Grids"
              >
                {TabList.map((tab, index) => {
                  return (
                    <Tab
                      key={"Table" + String(index)}
                      label={tab.label}
                      value={tab.label}
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        padding: 3,
                      }}
                    />
                  );
                })}
              </Tabs>
            </Box>
            {TabList.map((tab, index) => {
              return (
                <TabPanel value={tab.label} key={"Table" + String(index)}>
                  <tab.component
                    handleChange={handleInputChange}
                    formData={formData}
                    onClose={props.onClose}
                  />
                </TabPanel>
              );
            })}
          </div>
          <Grid container spacing={3} sx={{ padding: 3 }}>
            <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
              <Button
                // onClick={async () => {
                //   if (props.id) {
                //     await UpdateProgressing({
                //       ID: props.id,
                //       GuidId: formData.GuidId,
                //       ProgressingExams: formData.ProgressingExams,
                //       ProgramId: formData.ProgramId,
                //       ProgressingTitle: formData.ProgressingTitle,
                //       CenterId: formData.CenterId,
                //       ProgressingTags: formData.ProgressingTags,
                //       ContentPage: formData.ContentPage,
                //       CmsPostId: formData.CmsPostId,
                //       CmsPostUrl: formData.CmsPostUrl,
                //       ChapterNo: formData.ChapterNo,
                //       Status: formData.Status,
                //       CreatedAt: dayjs(props.CreatedAt) || dayjs(),
                //     });
                //   } else {
                //     await CreateProgressing({
                //       GuidId: formData.GuidId,
                //       ProgressingExams: formData.ProgressingExams,
                //       ProgramId: formData.ProgramId,
                //       ProgressingTitle: formData.ProgressingTitle,
                //       ProgressingTags: formData.ProgressingTags,
                //       ContentPage: formData.ContentPage,
                //       CmsPostId: formData.CmsPostId,
                //       CmsPostUrl: formData.CmsPostUrl,
                //       ChapterNo: formData.ChapterNo,
                //       Status: formData.Status,
                //       CreatedAt: dayjs(props.CreatedAt) || dayjs(),
                //     });
                //   }
                //   props.handleSave();
                // }}
                variant="contained"
                color="save"
                sx={{
                  borderRadius: 0,
                  width: 140,
                  height: 60,
                  fontSize: "1.0rem",
                }}
              >
                저장
              </Button>
              <Button
                onClick={() => {
                  props.onClose();
                  formInit();
                }}
                variant="contained"
                color="cancel"
                sx={{
                  borderRadius: 0,
                  width: 140,
                  height: 60,
                  fontSize: "1.0rem",
                }}
              >
                취소
              </Button>
            </Grid>
          </Grid>
          <Snackbar
            open={errorOpen}
            autoHideDuration={1800}
            onClose={handleErrorClose}
          >
            <Alert onClose={handleErrorClose} severity="error">
              Please Enter {errorField} field
            </Alert>
          </Snackbar>
      </TabContext>
      </Grid>
      </Grid>
    </Modal>
  );
}

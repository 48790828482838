// axios를 통해 서버에서 데이터 불러오는 코드 작성

//테스트 데이터
export const classdata = [
    {
      "id": 1,
      "name": "월 1800-PG@39",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 18:00~20:00",
      "classType": "regular",
      "classStart": "2023-06-25",
      "classEnd": "2023-11-18",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 2,
      "name": "수 2000-PG@39",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 20:00~22:00",
      "classType": "sub",
      "classStart": "2023-08-28",
      "classEnd": "2023-12-26",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 3,
      "name": "목 1600-PG@26",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 16:00~18:00",
      "classType": "sub",
      "classStart": "2023-09-02",
      "classEnd": "2023-10-01",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍감동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 4,
      "name": "금 1800-PG@16",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 18:00~20:00",
      "classType": "regular",
      "classStart": "2023-07-05",
      "classEnd": "2023-12-04",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 5,
      "name": "월 1900-PG@30",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 19:00~21:00",
      "classType": "sub",
      "classStart": "2023-08-05",
      "classEnd": "2023-11-28",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 6,
      "name": "목 1400-PG@28",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 14:00~16:00",
      "classType": "regular",
      "classStart": "2023-06-22",
      "classEnd": "2023-11-06",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 7,
      "name": "목 1500-PG@17",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 15:00~17:00",
      "classType": "regular",
      "classStart": "2023-07-29",
      "classEnd": "2023-12-23",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 8,
      "name": "목 1400-PG@30",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 14:00~16:00",
      "classType": "regular",
      "classStart": "2023-07-12",
      "classEnd": "2023-12-31",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 9,
      "name": "수 2000-PG@27",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 20:00~22:00",
      "classType": "regular",
      "classStart": "2023-06-28",
      "classEnd": "2023-11-11",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 10,
      "name": "금 1400-PG@21",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 14:00~16:00",
      "classType": "regular",
      "classStart": "2023-08-16",
      "classEnd": "2023-10-28",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 11,
      "name": "수 1900-PG@30",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 19:00~21:00",
      "classType": "regular",
      "classStart": "2023-07-26",
      "classEnd": "2023-11-19",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 12,
      "name": "월 1900-PG@18",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 19:00~21:00",
      "classType": "sub",
      "classStart": "2023-07-13",
      "classEnd": "2023-10-14",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 13,
      "name": "금 1900-PG@1",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 19:00~21:00",
      "classType": "regular",
      "classStart": "2023-09-12",
      "classEnd": "2023-10-29",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 14,
      "name": "화 1600-PG@37",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 16:00~18:00",
      "classType": "sub",
      "classStart": "2023-07-06",
      "classEnd": "2023-11-26",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 15,
      "name": "화 2000-PG@10",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 20:00~22:00",
      "classType": "sub",
      "classStart": "2023-09-02",
      "classEnd": "2023-11-15",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 16,
      "name": "목 2100-PG@12",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 21:00~23:00",
      "classType": "sub",
      "classStart": "2023-08-19",
      "classEnd": "2023-11-19",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 17,
      "name": "금 1900-PG@25",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 19:00~21:00",
      "classType": "sub",
      "classStart": "2023-06-20",
      "classEnd": "2023-12-30",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 18,
      "name": "금 1900-PG@38",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 19:00~21:00",
      "classType": "regular",
      "classStart": "2023-07-29",
      "classEnd": "2023-12-25",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 19,
      "name": "화 2100-PG@15",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 21:00~23:00",
      "classType": "regular",
      "classStart": "2023-06-30",
      "classEnd": "2023-11-15",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 20,
      "name": "수 1800-PG@4",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 18:00~20:00",
      "classType": "regular",
      "classStart": "2023-09-02",
      "classEnd": "2023-12-05",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 21,
      "name": "금 2100-PG@24",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 21:00~23:00",
      "classType": "sub",
      "classStart": "2023-08-03",
      "classEnd": "2023-11-14",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 22,
      "name": "화 2000-PG@18",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 20:00~22:00",
      "classType": "regular",
      "classStart": "2023-07-12",
      "classEnd": "2023-12-06",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 23,
      "name": "수 1400-PG@37",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 14:00~16:00",
      "classType": "sub",
      "classStart": "2023-07-02",
      "classEnd": "2023-11-03",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 24,
      "name": "화 1600-PG@17",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 16:00~18:00",
      "classType": "regular",
      "classStart": "2023-07-26",
      "classEnd": "2024-01-04",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 25,
      "name": "금 2000-PG@4",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 20:00~22:00",
      "classType": "sub",
      "classStart": "2023-07-20",
      "classEnd": "2023-09-25",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 26,
      "name": "금 1400-PG@27",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 14:00~16:00",
      "classType": "regular",
      "classStart": "2023-07-19",
      "classEnd": "2023-11-18",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 27,
      "name": "금 1900-PG@39",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 19:00~21:00",
      "classType": "sub",
      "classStart": "2023-07-10",
      "classEnd": "2023-12-29",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 28,
      "name": "월 1400-PG@18",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 14:00~16:00",
      "classType": "sub",
      "classStart": "2023-07-19",
      "classEnd": "2023-12-29",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 29,
      "name": "화 2000-PG@9",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 20:00~22:00",
      "classType": "sub",
      "classStart": "2023-07-06",
      "classEnd": "2023-10-20",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 30,
      "name": "목 1400-PG@40",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 14:00~16:00",
      "classType": "sub",
      "classStart": "2023-07-13",
      "classEnd": "2023-11-17",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 31,
      "name": "월 1600-PG@33",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 16:00~18:00",
      "classType": "sub",
      "classStart": "2023-08-24",
      "classEnd": "2024-01-07",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 32,
      "name": "금 1400-PG@5",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 14:00~16:00",
      "classType": "sub",
      "classStart": "2023-07-10",
      "classEnd": "2023-09-25",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 33,
      "name": "화 1700-PG@36",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 17:00~19:00",
      "classType": "regular",
      "classStart": "2023-08-28",
      "classEnd": "2023-10-23",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 34,
      "name": "화 1500-PG@38",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 15:00~17:00",
      "classType": "sub",
      "classStart": "2023-07-03",
      "classEnd": "2023-11-06",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 35,
      "name": "금 1900-PG@4",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 19:00~21:00",
      "classType": "sub",
      "classStart": "2023-07-28",
      "classEnd": "2023-12-03",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 36,
      "name": "화 1400-PG@17",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 14:00~16:00",
      "classType": "sub",
      "classStart": "2023-08-02",
      "classEnd": "2023-11-23",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 37,
      "name": "수 1800-PG@26",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 18:00~20:00",
      "classType": "sub",
      "classStart": "2023-07-03",
      "classEnd": "2023-11-28",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 38,
      "name": "월 1900-PG@13",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 19:00~21:00",
      "classType": "sub",
      "classStart": "2023-06-29",
      "classEnd": "2023-10-29",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 39,
      "name": "화 1700-PG@23",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 17:00~19:00",
      "classType": "regular",
      "classStart": "2023-06-26",
      "classEnd": "2023-12-05",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 40,
      "name": "월 1400-PG@25",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 14:00~16:00",
      "classType": "sub",
      "classStart": "2023-08-20",
      "classEnd": "2024-01-07",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 41,
      "name": "금 1400-PG@19",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 14:00~16:00",
      "classType": "sub",
      "classStart": "2023-09-03",
      "classEnd": "2024-01-12",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 42,
      "name": "화 1900-PG@31",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 19:00~21:00",
      "classType": "regular",
      "classStart": "2023-08-24",
      "classEnd": "2023-12-25",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 43,
      "name": "금 1400-PG@38",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 14:00~16:00",
      "classType": "regular",
      "classStart": "2023-07-18",
      "classEnd": "2023-09-27",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 44,
      "name": "목 1400-PG@24",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 14:00~16:00",
      "classType": "sub",
      "classStart": "2023-07-23",
      "classEnd": "2023-12-06",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 45,
      "name": "목 1900-PG@39",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 19:00~21:00",
      "classType": "sub",
      "classStart": "2023-07-12",
      "classEnd": "2023-10-19",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 46,
      "name": "목 1900-PG@29",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 19:00~21:00",
      "classType": "sub",
      "classStart": "2023-09-01",
      "classEnd": "2023-11-16",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 47,
      "name": "수 1400-PG@35",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 14:00~16:00",
      "classType": "sub",
      "classStart": "2023-08-19",
      "classEnd": "2024-01-07",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 48,
      "name": "목 2100-PG@3",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 21:00~23:00",
      "classType": "regular",
      "classStart": "2023-06-22",
      "classEnd": "2023-09-18",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 49,
      "name": "금 1800-PG@20",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 18:00~20:00",
      "classType": "sub",
      "classStart": "2023-08-12",
      "classEnd": "2023-10-14",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 50,
      "name": "수 1700-PG@34",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 17:00~19:00",
      "classType": "regular",
      "classStart": "2023-08-27",
      "classEnd": "2023-11-09",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 51,
      "name": "목 2100-PG@21",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 21:00~23:00",
      "classType": "regular",
      "classStart": "2023-08-23",
      "classEnd": "2023-12-05",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 52,
      "name": "목 1600-PG@20",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 16:00~18:00",
      "classType": "sub",
      "classStart": "2023-08-18",
      "classEnd": "2024-01-08",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 53,
      "name": "월 1500-PG@27",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 15:00~17:00",
      "classType": "sub",
      "classStart": "2023-09-01",
      "classEnd": "2023-09-23",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 54,
      "name": "월 2000-PG@17",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 20:00~22:00",
      "classType": "regular",
      "classStart": "2023-08-29",
      "classEnd": "2023-10-19",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 55,
      "name": "수 2000-PG@35",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 20:00~22:00",
      "classType": "regular",
      "classStart": "2023-07-14",
      "classEnd": "2023-11-08",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 56,
      "name": "월 1400-PG@13",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 14:00~16:00",
      "classType": "regular",
      "classStart": "2023-07-02",
      "classEnd": "2023-12-03",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 57,
      "name": "수 1900-PG@8",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 19:00~21:00",
      "classType": "regular",
      "classStart": "2023-09-05",
      "classEnd": "2023-11-21",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 58,
      "name": "금 1500-PG@6",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 15:00~17:00",
      "classType": "regular",
      "classStart": "2023-07-28",
      "classEnd": "2023-11-29",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 59,
      "name": "목 1900-PG@18",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 19:00~21:00",
      "classType": "regular",
      "classStart": "2023-09-01",
      "classEnd": "2024-01-08",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 60,
      "name": "목 1900-PG@35",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 19:00~21:00",
      "classType": "sub",
      "classStart": "2023-09-04",
      "classEnd": "2023-11-08",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 61,
      "name": "금 1500-PG@15",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 15:00~17:00",
      "classType": "regular",
      "classStart": "2023-08-26",
      "classEnd": "2023-12-02",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 62,
      "name": "화 1500-PG@33",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 15:00~17:00",
      "classType": "regular",
      "classStart": "2023-08-09",
      "classEnd": "2023-10-24",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 63,
      "name": "화 2100-PG@6",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 21:00~23:00",
      "classType": "sub",
      "classStart": "2023-08-19",
      "classEnd": "2023-11-15",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 64,
      "name": "목 1600-PG@37",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 16:00~18:00",
      "classType": "regular",
      "classStart": "2023-07-28",
      "classEnd": "2023-09-17",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 65,
      "name": "화 1600-PG@34",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 16:00~18:00",
      "classType": "sub",
      "classStart": "2023-07-24",
      "classEnd": "2023-10-26",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 66,
      "name": "화 2100-PG@1",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 21:00~23:00",
      "classType": "regular",
      "classStart": "2023-07-05",
      "classEnd": "2024-01-02",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 67,
      "name": "수 1900-PG@35",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 19:00~21:00",
      "classType": "regular",
      "classStart": "2023-07-21",
      "classEnd": "2023-09-24",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 68,
      "name": "목 1400-PG@4",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 14:00~16:00",
      "classType": "regular",
      "classStart": "2023-08-06",
      "classEnd": "2023-12-02",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 69,
      "name": "화 1400-PG@1",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 14:00~16:00",
      "classType": "regular",
      "classStart": "2023-08-19",
      "classEnd": "2023-11-16",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 70,
      "name": "화 1500-PG@37",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 15:00~17:00",
      "classType": "regular",
      "classStart": "2023-09-02",
      "classEnd": "2023-11-24",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 71,
      "name": "수 1600-PG@16",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 16:00~18:00",
      "classType": "regular",
      "classStart": "2023-07-03",
      "classEnd": "2023-10-19",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 72,
      "name": "화 1800-PG@25",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 18:00~20:00",
      "classType": "regular",
      "classStart": "2023-06-19",
      "classEnd": "2023-09-25",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 73,
      "name": "월 1800-PG@6",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 18:00~20:00",
      "classType": "sub",
      "classStart": "2023-07-26",
      "classEnd": "2023-10-14",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 74,
      "name": "월 1800-PG@21",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 18:00~20:00",
      "classType": "sub",
      "classStart": "2023-06-20",
      "classEnd": "2023-11-07",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 75,
      "name": "수 1900-PG@26",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 19:00~21:00",
      "classType": "sub",
      "classStart": "2023-08-26",
      "classEnd": "2023-12-29",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 76,
      "name": "수 1400-PG@12",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 14:00~16:00",
      "classType": "sub",
      "classStart": "2023-08-16",
      "classEnd": "2024-01-02",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 77,
      "name": "목 1800-PG@13",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 18:00~20:00",
      "classType": "sub",
      "classStart": "2023-07-29",
      "classEnd": "2023-09-18",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 78,
      "name": "화 2100-PG@16",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 21:00~23:00",
      "classType": "regular",
      "classStart": "2023-09-10",
      "classEnd": "2023-10-05",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 79,
      "name": "목 2100-PG@2",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 21:00~23:00",
      "classType": "sub",
      "classStart": "2023-08-22",
      "classEnd": "2023-12-28",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 80,
      "name": "목 1500-PG@36",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 15:00~17:00",
      "classType": "sub",
      "classStart": "2023-09-09",
      "classEnd": "2024-01-11",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 81,
      "name": "월 1800-PG@7",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 18:00~20:00",
      "classType": "sub",
      "classStart": "2023-08-11",
      "classEnd": "2023-10-20",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 82,
      "name": "월 2100-PG@33",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 21:00~23:00",
      "classType": "regular",
      "classStart": "2023-07-02",
      "classEnd": "2023-09-27",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 83,
      "name": "화 1600-PG@28",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 16:00~18:00",
      "classType": "regular",
      "classStart": "2023-08-27",
      "classEnd": "2023-11-30",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 84,
      "name": "목 1800-PG@32",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 18:00~20:00",
      "classType": "regular",
      "classStart": "2023-08-21",
      "classEnd": "2023-12-13",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 85,
      "name": "목 2000-PG@4",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 20:00~22:00",
      "classType": "sub",
      "classStart": "2023-08-20",
      "classEnd": "2023-11-17",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 86,
      "name": "금 1600-PG@34",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 16:00~18:00",
      "classType": "sub",
      "classStart": "2023-08-21",
      "classEnd": "2023-10-17",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 87,
      "name": "화 1700-PG@25",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 17:00~19:00",
      "classType": "sub",
      "classStart": "2023-08-27",
      "classEnd": "2023-09-22",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 88,
      "name": "월 1600-PG@33",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 16:00~18:00",
      "classType": "sub",
      "classStart": "2023-08-29",
      "classEnd": "2023-09-15",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 89,
      "name": "목 1600-PG@15",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 16:00~18:00",
      "classType": "sub",
      "classStart": "2023-06-29",
      "classEnd": "2023-11-07",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 90,
      "name": "금 1900-PG@10",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 19:00~21:00",
      "classType": "sub",
      "classStart": "2023-08-18",
      "classEnd": "2024-01-12",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 91,
      "name": "금 1800-PG@13",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 18:00~20:00",
      "classType": "regular",
      "classStart": "2023-08-23",
      "classEnd": "2023-12-04",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 92,
      "name": "수 2100-PG@36",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 21:00~23:00",
      "classType": "regular",
      "classStart": "2023-08-22",
      "classEnd": "2023-12-25",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 93,
      "name": "목 1600-PG@31",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 16:00~18:00",
      "classType": "regular",
      "classStart": "2023-08-26",
      "classEnd": "2023-10-10",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 94,
      "name": "금 1600-PG@32",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 16:00~18:00",
      "classType": "regular",
      "classStart": "2023-06-25",
      "classEnd": "2023-11-16",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 95,
      "name": "화 1900-PG@6",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 19:00~21:00",
      "classType": "regular",
      "classStart": "2023-07-13",
      "classEnd": "2023-10-29",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 96,
      "name": "화 1800-PG@5",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 18:00~20:00",
      "classType": "sub",
      "classStart": "2023-06-29",
      "classEnd": "2023-11-08",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 97,
      "name": "화 2000-PG@25",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 20:00~22:00",
      "classType": "sub",
      "classStart": "2023-08-01",
      "classEnd": "2023-12-15",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 98,
      "name": "수 1900-PG@27",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 19:00~21:00",
      "classType": "regular",
      "classStart": "2023-09-01",
      "classEnd": "2023-09-18",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 99,
      "name": "금 2000-PG@3",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 20:00~22:00",
      "classType": "regular",
      "classStart": "2023-09-11",
      "classEnd": "2023-12-13",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 100,
      "name": "금 1600-PG@33",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 16:00~18:00",
      "classType": "sub",
      "classStart": "2023-06-20",
      "classEnd": "2023-12-02",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 101,
      "name": "월 1700-PG@11",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 17:00~19:00",
      "classType": "sub",
      "classStart": "2023-08-15",
      "classEnd": "2023-09-22",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 102,
      "name": "금 2000-PG@27",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 20:00~22:00",
      "classType": "sub",
      "classStart": "2023-06-27",
      "classEnd": "2023-10-07",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 103,
      "name": "목 1400-PG@38",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 14:00~16:00",
      "classType": "sub",
      "classStart": "2023-06-23",
      "classEnd": "2023-09-23",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 104,
      "name": "화 1400-PG@24",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "화 14:00~16:00",
      "classType": "sub",
      "classStart": "2023-09-12",
      "classEnd": "2023-11-03",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 105,
      "name": "목 1700-PG@32",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 17:00~19:00",
      "classType": "sub",
      "classStart": "2023-06-15",
      "classEnd": "2023-12-03",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 106,
      "name": "월 1600-PG@27",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "월 16:00~18:00",
      "classType": "sub",
      "classStart": "2023-06-19",
      "classEnd": "2023-12-23",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 107,
      "name": "금 1800-PG@21",
      "status": "on",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "금 18:00~20:00",
      "classType": "regular",
      "classStart": "2023-08-26",
      "classEnd": "2023-12-30",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 108,
      "name": "수 1700-PG@18",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "수 17:00~19:00",
      "classType": "sub",
      "classStart": "2023-09-08",
      "classEnd": "2023-10-26",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 109,
      "name": "목 1400-PG@36",
      "status": "waiting",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 14:00~16:00",
      "classType": "regular",
      "classStart": "2023-08-08",
      "classEnd": "2023-11-07",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    },
    {
      "id": 110,
      "name": "목 1600-PG@19",
      "status": "off",
      "teacher": "아토샘",
      "teacherAvatar": null,
      "classTime": "목 16:00~18:00",
      "classType": "regular",
      "classStart": "2023-09-06",
      "classEnd": "2024-01-07",
      "room" : '1교실',
      "studentList":[
    {
      name: "홍길동",
      attend: "wait",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "강감찬",
      attend: "attend",
      type: "supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "late",
      type: "free_supply",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "김구",
      attend: "leaveEarly",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "유관순",
      attend: "online",
      type: "",
      grade: "초3",
      school: "강동초",
    },
    {
      name: "홍길동",
      attend: "absent",
      type: "",
      grade: "초3",
      school: "강동초",
    },
  ],
  "menu":'123'
    }
  ];

  export const counseldata=[
    {
      id:12341222,
      name: "황동희",
      status: "graduate",
      grade: 9,
      school: "서울초",
      title: '수업이 재미없어요~',
      program: '파이썬첫걸음@2023',
      tags:['수업','학부모'],
      teacher : '아토샘',
      date: Date('2023-03-02')
    },
    {
      id:12341224,
      name: "황동희",
      status: "graduate",
      grade: 9,
      school: "서울초",
      title: '수업이 재미없어요~',
      program: '파이썬첫걸음@2023',
      tags:['수업','학부모'],
      teacher : '아토샘',
      date: Date('2023-03-02')
    },
    {
      id:12341225,
      name: "황동희",
      status: "graduate",
      grade: 9,
      school: "서울초",
      title: '수업이 재미없어요~',
      program: '파이썬첫걸음@2023',
      tags:['수업','학부모'],
      teacher : '아토샘',
      date: Date('2023-03-02')
    },
    {
      id:12341226,
      name: "황동희",
      status: "graduate",
      grade: 9,
      school: "서울초",
      title: '수업이 재미없어요~',
      program: '파이썬첫걸음@2023',
      tags:['수업','학부모'],
      teacher : '아토샘',
      date: Date('2023-03-02')
    },
    {
      id:12341228,
      name: "황동희",
      status: "graduate",
      grade: 9,
      school: "서울초",
      title: '수업이 재미없어요~',
      program: '파이썬첫걸음@2023',
      tags:['수업','학부모'],
      teacher : '아토샘',
      date: Date('2023-03-02')
    },
    {
      id:12341227,
      name: "황동희",
      status: "graduate",
      grade: 9,
      school: "서울초",
      title: '수업이 재미없어요~',
      program: '파이썬첫걸음@2023',
      tags:['수업','학부모'],
      teacher : '아토샘',
      date: Date('2023-03-02')
    },
    {
      id:12341212,
      name: "황동희",
      status: "graduate",
      grade: 9,
      school: "서울초",
      title: '수업이 재미없어요~',
      program: '파이썬첫걸음@2023',
      tags:['수업','학부모'],
      teacher : '아토샘',
      date: Date('2023-03-02')
    },
  ]
  
  export const studentdata = [
    {
      id: 3195728,
      name: "황동희",
      status: "graduate",
      hasMessage:'true',
      grade: 9,
      school: "서울초",
      gender: "female",
      username: "user123",
      phone_num_own: "010-3456-7890",
      phone_num_family: "010-1234-5678",
      family_relation: "father",
      start_date: "2023-06-10",
    },
    {
      id: 4739819,
      name: "김현수",
      status: "off",
      hasMessage:'false',
      grade: 7,
      school: "서울중",
      gender: "male",
      username: "student456",
      phone_num_own: "010-9876-5432",
      phone_num_family: "010-5678-1234",
      family_relation: "mother",
      start_date: "2023-07-28",
    },
    {
      id: 8364297,
      name: "이영진",
      status: "graduate",
      hasMessage:'true',
      grade: 12,
      school: "고덕고",
      gender: "male",
      username: "youngjin12",
      phone_num_own: "010-2345-6789",
      phone_num_family: "010-1234-5678",
      family_relation: "father",
      start_date: "2023-06-15",
    },
    {
      id: 6253849,
      name: "박지영",
      status: "off",
      hasMessage:'false',
      grade: 5,
      school: "명지초",
      gender: "female",
      username: "user789",
      phone_num_own: "010-8765-4321",
      phone_num_family: "010-4321-8765",
      family_relation: "grandma",
      start_date: "2023-07-17",
    },
    {
      id: 9375264,
      name: "김태영",
      status: "on",
      hasMessage:'true',
      grade: 11,
      school: "서울고",
      gender: "male",
      username: "kimtae",
      phone_num_own: "010-5432-1098",
      phone_num_family: "010-9876-5432",
      family_relation: "father",
      start_date: "2023-05-03",
    },
    {
      id: 1793825,
      name: "정지수",
      status: "graduate",
      hasMessage:'true',
      grade: 10,
      school: "명지고",
      gender: "female",
      username: "jisooyoung",
      phone_num_own: "010-6789-1234",
      phone_num_family: "010-1234-6789",
      family_relation: "mother",
      start_date: "2023-05-21",
    },
    {
      id: 2947581,
      name: "이준호",
      status: "deleted",
      hasMessage:'false',
      grade: 3,
      school: "서울초",
      gender: "male",
      username: "junholee",
      phone_num_own: "010-8765-4321",
      phone_num_family: "010-4321-8765",
      family_relation: "father",
      start_date: "2023-06-02",
    },
    {
      id: 4290765,
      name: "윤은지",
      status: "off",
      hasMessage:'false',
      grade: 8,
      school: "강동중",
      gender: "female",
      username: "eunjipark",
      phone_num_own: "010-2345-6789",
      phone_num_family: "010-1234-5678",
      family_relation: "father",
      start_date: "2023-07-15",
    },
    {
      id: 5278419,
      name: "김영호",
      status: "graduate",
      hasMessage:'true',
      grade: 12,
      school: "고덕고",
      gender: "male",
      username: "youngkim",
      phone_num_own: "010-3456-7890",
      phone_num_family: "010-5678-1234",
      family_relation: "father",
      start_date: "2023-07-22",
    },
    {
      id: 8652175,
      name: "이지수",
      status: "graduate",
      hasMessage:'true',
      grade: 12,
      school: "고덕고",
      gender: "female",
      username: "jisulee",
      phone_num_own: "010-1234-5678",
      phone_num_family: "010-9876-5432",
      family_relation: "mother",
      start_date: "2023-06-01",
    },
    {
      id: 7189462,
      name: "김성민",
      status: "on",
      hasMessage:'true',
      grade: 9,
      school: "고덕중",
      gender: "male",
      username: "kimmin",
      phone_num_own: "010-9876-5432",
      phone_num_family: "010-2345-6789",
      family_relation: "father",
      start_date: "2023-05-28",
    },
    {
      id: 2467345,
      name: "박영희",
      status: "deleted",
      hasMessage:'false',
      grade: 2,
      school: "강동초",
      gender: "female",
      username: "yeonghee",
      phone_num_own: "010-3456-7890",
      phone_num_family: "010-8765-4321",
      family_relation: "mother",
      start_date: "2023-07-12",
    },
    {
      id: 3542916,
      name: "최성호",
      status: "graduate",
      hasMessage:'true',
      grade: 12,
      school: "고덕고",
      gender: "male",
      username: "choisungho",
      phone_num_own: "010-5678-1234",
      phone_num_family: "010-1234-5678",
      family_relation: "father",
      start_date: "2023-07-20",
    },
    {
      id: 9267451,
      name: "이수진",
      status: "graduate",
      hasMessage:'true',
      grade: 12,
      school: "서울고",
      gender: "female",
      username: "soojinlee",
      phone_num_own: "010-2345-6789",
      phone_num_family: "010-8765-4321",
      family_relation: "mother",
      start_date: "2023-05-15",
    },
    {
      id: 5928743,
      name: "김성민",
      status: "graduate",
      hasMessage:'true',
      grade: 12,
      school: "고덕고",
      gender: "male",
      username: "kimmin",
      phone_num_own: "010-9876-5432",
      phone_num_family: "010-2345-6789",
      family_relation: "father",
      start_date: "2023-06-28",
    },
    {
      id: 9284376,
      name: "이민지",
      status: "on",
      hasMessage:'true',
      grade: 9,
      school: "서울중",
      gender: "female",
      username: "minji",
      phone_num_own: "010-3456-7890",
      phone_num_family: "010-5678-1234",
      family_relation: "mother",
      start_date: "2023-06-05",
    },
    {
      id: 3195723,
      name: "황동희",
      status: "graduate",
      hasMessage:'true',
      grade: 9,
      school: "서울초",
      gender: "female",
      username: "user123",
      phone_num_own: "010-3456-7890",
      phone_num_family: "010-1234-5678",
      family_relation: "father",
      start_date: "2023-06-10",
    },
    {
      id: 4739818,
      name: "김현수",
      status: "off",
      hasMessage:'false',
      grade: 7,
      school: "서울중",
      gender: "male",
      username: "student456",
      phone_num_own: "010-9876-5432",
      phone_num_family: "010-5678-1234",
      family_relation: "mother",
      start_date: "2023-07-28",
    },
    {
      id: 8364298,
      name: "이영진",
      status: "graduate",
      hasMessage:'true',
      grade: 12,
      school: "고덕고",
      gender: "male",
      username: "youngjin12",
      phone_num_own: "010-2345-6789",
      phone_num_family: "010-1234-5678",
      family_relation: "father",
      start_date: "2023-06-15",
    },
    {
      id: 6253847,
      name: "박지영",
      status: "off",
      hasMessage:'false',
      grade: 5,
      school: "명지초",
      gender: "female",
      username: "user789",
      phone_num_own: "010-8765-4321",
      phone_num_family: "010-4321-8765",
      family_relation: "grandma",
      start_date: "2023-07-17",
    },
    {
      id: 9375267,
      name: "김태영",
      status: "on",
      hasMessage:'true',
      grade: 11,
      school: "서울고",
      gender: "male",
      username: "kimtae",
      phone_num_own: "010-5432-1098",
      phone_num_family: "010-9876-5432",
      family_relation: "father",
      start_date: "2023-05-03",
    },
    {
      id: 1793821,
      name: "정지수",
      status: "graduate",
      hasMessage:'true',
      grade: 10,
      school: "명지고",
      gender: "female",
      username: "jisooyoung",
      phone_num_own: "010-6789-1234",
      phone_num_family: "010-1234-6789",
      family_relation: "mother",
      start_date: "2023-05-21",
    },
    {
      id: 2947582,
      name: "이준호",
      status: "deleted",
      hasMessage:'false',
      grade: 3,
      school: "서울초",
      gender: "male",
      username: "junholee",
      phone_num_own: "010-8765-4321",
      phone_num_family: "010-4321-8765",
      family_relation: "father",
      start_date: "2023-06-02",
    },
    {
      id: 4290763,
      name: "윤은지",
      status: "off",
      hasMessage:'false',
      grade: 8,
      school: "강동중",
      gender: "female",
      username: "eunjipark",
      phone_num_own: "010-2345-6789",
      phone_num_family: "010-1234-5678",
      family_relation: "father",
      start_date: "2023-07-15",
    },
    {
      id: 5278413,
      name: "김영호",
      status: "graduate",
      hasMessage:'true',
      grade: 12,
      school: "고덕고",
      gender: "male",
      username: "youngkim",
      phone_num_own: "010-3456-7890",
      phone_num_family: "010-5678-1234",
      family_relation: "father",
      start_date: "2023-07-22",
    },
    {
      id: 8652174,
      name: "이지수",
      status: "graduate",
      hasMessage:'true',
      grade: 12,
      school: "고덕고",
      gender: "female",
      username: "jisulee",
      phone_num_own: "010-1234-5678",
      phone_num_family: "010-9876-5432",
      family_relation: "mother",
      start_date: "2023-06-01",
    },
    {
      id: 7189464,
      name: "김성민",
      status: "on",
      hasMessage:'true',
      grade: 9,
      school: "고덕중",
      gender: "male",
      username: "kimmin",
      phone_num_own: "010-9876-5432",
      phone_num_family: "010-2345-6789",
      family_relation: "father",
      start_date: "2023-05-28",
    },
    {
      id: 2467348,
      name: "박영희",
      status: "deleted",
      hasMessage:'false',
      grade: 2,
      school: "강동초",
      gender: "female",
      username: "yeonghee",
      phone_num_own: "010-3456-7890",
      phone_num_family: "010-8765-4321",
      family_relation: "mother",
      start_date: "2023-07-12",
    },
    {
      id: 3542915,
      name: "최성호",
      status: "graduate",
      hasMessage:'true',
      grade: 12,
      school: "고덕고",
      gender: "male",
      username: "choisungho",
      phone_num_own: "010-5678-1234",
      phone_num_family: "010-1234-5678",
      family_relation: "father",
      start_date: "2023-07-20",
    },
    {
      id: 9267452,
      name: "이수진",
      status: "graduate",
      hasMessage:'true',
      grade: 12,
      school: "서울고",
      gender: "female",
      username: "soojinlee",
      phone_num_own: "010-2345-6789",
      phone_num_family: "010-8765-4321",
      family_relation: "mother",
      start_date: "2023-05-15",
    },
    {
      id: 5928742,
      name: "김성민",
      status: "graduate",
      hasMessage:'true',
      grade: 12,
      school: "고덕고",
      gender: "male",
      username: "kimmin",
      phone_num_own: "010-9876-5432",
      phone_num_family: "010-2345-6789",
      family_relation: "father",
      start_date: "2023-06-28",
    },
    {
      id: 9284373,
      name: "이민지",
      status: "on",
      hasMessage:'true',
      grade: 9,
      school: "서울중",
      gender: "female",
      username: "minji",
      phone_num_own: "010-3456-7890",
      phone_num_family: "010-5678-1234",
      family_relation: "mother",
      start_date: "2023-06-05",
    },
  ];

  export const student_program_data = [
    {
      id: 3195728,
      name: "황동희",
      status: "off",
      grade: 9,
      school: "서울초",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "female",
      username: "user123",
      phone_num_own: "010-3456-7890",
      phone_num_family: "010-1234-5678",
      family_relation: "father",
      start_date: "2023-06-10",
    },
    {
      id: 4739819,
      name: "김현수",
      status: "off",
      hasMessage:'false',
      grade: 7,
      school: "서울중",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "male",
      username: "student456",
      phone_num_own: "010-9876-5432",
      phone_num_family: "010-5678-1234",
      family_relation: "mother",
      start_date: "2023-07-28",
    },
    {
      id: 8364297,
      name: "이영진",
      status: "off",
      grade: 12,
      school: "고덕고",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "male",
      username: "youngjin12",
      phone_num_own: "010-2345-6789",
      phone_num_family: "010-1234-5678",
      family_relation: "father",
      start_date: "2023-06-15",
    },
    {
      id: 6253849,
      name: "박지영",
      status: "off",
      hasMessage:'false',
      grade: 5,
      school: "명지초",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "female",
      username: "user789",
      phone_num_own: "010-8765-4321",
      phone_num_family: "010-4321-8765",
      family_relation: "grandma",
      start_date: "2023-07-17",
    },
    {
      id: 9375264,
      name: "김태영",
      status: "on",
      hasMessage:'true',
      grade: 11,
      school: "서울고",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "male",
      username: "kimtae",
      phone_num_own: "010-5432-1098",
      phone_num_family: "010-9876-5432",
      family_relation: "father",
      start_date: "2023-05-03",
    },
    {
      id: 1793825,
      name: "정지수",
      status: "off",
      grade: 10,
      school: "명지고",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "female",
      username: "jisooyoung",
      phone_num_own: "010-6789-1234",
      phone_num_family: "010-1234-6789",
      family_relation: "mother",
      start_date: "2023-05-21",
    },
    {
      id: 2947581,
      name: "이준호",
      status: "deleted",
      grade: 3,
      school: "서울초",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "male",
      username: "junholee",
      phone_num_own: "010-8765-4321",
      phone_num_family: "010-4321-8765",
      family_relation: "father",
      start_date: "2023-06-02",
    },
    {
      id: 4290765,
      name: "윤은지",
      status: "off",
      hasMessage:'false',
      grade: 8,
      school: "강동중",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "female",
      username: "eunjipark",
      phone_num_own: "010-2345-6789",
      phone_num_family: "010-1234-5678",
      family_relation: "father",
      start_date: "2023-07-15",
    },
    {
      id: 5278419,
      name: "김영호",
      status: "off",
      grade: 12,
      school: "고덕고",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "male",
      username: "youngkim",
      phone_num_own: "010-3456-7890",
      phone_num_family: "010-5678-1234",
      family_relation: "father",
      start_date: "2023-07-22",
    },
    {
      id: 8652175,
      name: "이지수",
      status: "off",
      grade: 12,
      school: "고덕고",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "female",
      username: "jisulee",
      phone_num_own: "010-1234-5678",
      phone_num_family: "010-9876-5432",
      family_relation: "mother",
      start_date: "2023-06-01",
    },
    {
      id: 7189462,
      name: "김성민",
      status: "on",
      hasMessage:'true',
      grade: 9,
      school: "고덕중",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "male",
      username: "kimmin",
      phone_num_own: "010-9876-5432",
      phone_num_family: "010-2345-6789",
      family_relation: "father",
      start_date: "2023-05-28",
    },
    {
      id: 2467345,
      name: "박영희",
      status: "deleted",
      grade: 2,
      school: "강동초",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "female",
      username: "yeonghee",
      phone_num_own: "010-3456-7890",
      phone_num_family: "010-8765-4321",
      family_relation: "mother",
      start_date: "2023-07-12",
    },
    {
      id: 3542916,
      name: "최성호",
      status: "off",
      grade: 12,
      school: "고덕고",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "male",
      username: "choisungho",
      phone_num_own: "010-5678-1234",
      phone_num_family: "010-1234-5678",
      family_relation: "father",
      start_date: "2023-07-20",
    },
    {
      id: 9267451,
      name: "이수진",
      status: "off",
      grade: 12,
      school: "서울고",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "female",
      username: "soojinlee",
      phone_num_own: "010-2345-6789",
      phone_num_family: "010-8765-4321",
      family_relation: "mother",
      start_date: "2023-05-15",
    },
    {
      id: 5928743,
      name: "김성민",
      status: "off",
      grade: 12,
      school: "고덕고",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "male",
      username: "kimmin",
      phone_num_own: "010-9876-5432",
      phone_num_family: "010-2345-6789",
      family_relation: "father",
      start_date: "2023-06-28",
    },
    {
      id: 9284376,
      name: "이민지",
      status: "on",
      hasMessage:'true',
      grade: 9,
      school: "서울중",
      classroom: '화1500A',
      program:'엔트리기본',
      counsel:1,
      memo:3,
      gender: "female",
      username: "minji",
      phone_num_own: "010-3456-7890",
      phone_num_family: "010-5678-1234",
      family_relation: "mother",
      start_date: "2023-06-05",
    },
  ];

  export const lecturedata= [
    {
      id:1234,
      name: '코딩환경 이해하기',
      lectureEval: 'https://e.codingschool.co.kr/abcdefg/',
      teacher: '아토샘',
      classroom: '화15000교실A',
      lecturedate: new Date('2023-03-02'),
      order: 1,
      status: 'on'
    },
    {
      id:1235,
      name: '코딩환경 이해하기',
      lectureEval: 'https://e.codingschool.co.kr/abcdefg/',
      teacher: '아토샘',
      classroom: '화15000교실A',
      lecturedate: new Date('2023-03-02'),
      order: 2,
      status: 'off'
    },
    {
      id:1236,
      name: '코딩환경 이해하기',
      lectureEval: 'https://e.codingschool.co.kr/abcdefg/',
      teacher: '아토샘',
      classroom: '화15000교실A',
      lecturedate: new Date('2023-03-02'),
      order: 3,
      status: 'off'
    },
    {
      id:1237,
      name: '코딩환경 이해하기',
      lectureEval: 'https://e.codingschool.co.kr/abcdefg/',
      teacher: '아토샘',
      classroom: '화15000교실A',
      lecturedate: new Date('2023-03-02'),
      order: 4,
      status: 'off'
    },
    {
      id:1238,
      name: '코딩환경 이해하기',
      lectureEval: 'https://e.codingschool.co.kr/abcdefg/',
      teacher: '아토샘',
      classroom: '화15000교실A',
      lecturedate: new Date('2023-03-02'),
      order: 5,
      status: 'on'
    },
    {
      id:1239,
      name: '코딩환경 이해하기',
      lectureEval: 'https://e.codingschool.co.kr/abcdefg/',
      teacher: '아토샘',
      classroom: '화15000교실A',
      lecturedate: new Date('2023-03-02'),
      order: 6,
      status: 'on'
    },
    {
      id:1224,
      name: '코딩환경 이해하기',
      lectureEval: 'https://e.codingschool.co.kr/abcdefg/',
      teacher: '아토샘',
      classroom: '화15000교실A',
      lecturedate: new Date('2023-03-02'),
      order: 7,
      status: 'on'
    },
  ]
  
  export const programdata = [
      {
        "id": 6789123,
        "name": "스파이크프라임",
        "status": "available",
        "subject": "lego",
        "admin": "아토샘",
        "lecture": 34,
        "register_date": "2023-08-05"
      },
      {
        "id": 8791234,
        "name": "해킹파이썬",
        "status": "waiting",
        "subject": "coding",
        "admin": "아토샘",
        "lecture": 26,
        "register_date": "2023-08-17"
      },
      {
        "id": 2345678,
        "name": "파이게임",
        "status": "available",
        "subject": "coding",
        "admin": "아토샘",
        "lecture": 43,
        "register_date": "2023-07-31"
      },
      {
        "id": 5678901,
        "name": "엔트리",
        "status": "waiting",
        "subject": "coding",
        "admin": "아토샘",
        "lecture": 21,
        "register_date": "2023-07-14"
      },
      {
        "id": 3456789,
        "name": "파이게임",
        "status": "available",
        "subject": "coding",
        "admin": "아토샘",
        "lecture": 15,
        "register_date": "2023-08-28"
      },
      {
        "id": 4567891,
        "name": "파이게임",
        "status": "available",
        "subject": "coding",
        "admin": "아토샘",
        "lecture": 39,
        "register_date": "2023-07-25"
      },
      {
        "id": 8901234,
        "name": "스파이크프라임",
        "status": "available",
        "subject": "lego",
        "admin": "아토샘",
        "lecture": 2,
        "register_date": "2023-08-07"
      },
      {
        "id": 1234567,
        "name": "엔트리",
        "status": "available",
        "subject": "coding",
        "admin": "아토샘",
        "lecture": 48,
        "register_date": "2023-08-03"
      },
      {
        "id": 2345677,
        "name": "스파이크프라임",
        "status": "ready",
        "subject": "lego",
        "admin": "아토샘",
        "lecture": 11,
        "register_date": "2023-08-09"
      },
      {
        "id": 4567890,
        "name": "EV3마인드스톰",
        "status": "ready",
        "subject": "lego",
        "admin": "아토샘",
        "lecture": 12,
        "register_date": "2023-08-12"
      },
      {
        "id": 6789012,
        "name": "해킹파이썬",
        "status": "ready",
        "subject": "coding",
        "admin": "아토샘",
        "lecture": 49,
        "register_date": "2023-07-30"
      }
    ];  
import * as React from "react";
import MainCard from "ui-component/cards/MainCard";
import {
  Typography,
  Grid,
  Divider,
  Tabs,
  Tab,
  Box,
  Modal,
  Avatar,
  TextField,
  InputLabel,
  MenuItem,
  FilledInput,
  Select,
  Button,
  IconButton,
  ButtonBase,
} from "@mui/material";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import MainMenuButton from "ui-component/buttons/MainMenuButton";
import { useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import { IconBrandPython, IconDotsVertical, IconEye, IconUser } from "@tabler/icons";
import StatusChip from "ui-component/StatusChip";
import { common, grey } from "@mui/material/colors";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { createTheme } from "@mui/system";
import theme from "themes";

function LectureInfo(props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
      <TextField
          label="제목"
          variant="filled"
          defaultValue={props.name}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ readOnly: true, style: { fontSize: "1.0rem" } }}a
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
      <Button
          onClick={props.onClose}
          variant="contained"
          color="save"
          sx={{
            borderRadius: 0,
            width: '100%',
            height: 60,
            fontSize: "1.0rem",
            gap:1
          }}
        >
          <IconEye></IconEye><Typography variant="h3" color={'inherit'}>CMS 열기</Typography>
        </Button>
      </Grid>
      <Grid item xs={9}>
      <TextField
          label="단원평가"
          variant="filled"
          defaultValue={props.lectureEval}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ readOnly: true, style: { fontSize: "1.0rem" } }}a
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
      <Button
          onClick={props.onClose}
          variant="contained"
          color="save"
          sx={{
            borderRadius: 0,
            width: '100%',
            height: 60,
            fontSize: "1.0rem",
            gap:1
          }}
        >
          <IconEye></IconEye><Typography variant="h3" color={'inherit'}>CMS 열기</Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Box >
          <TextareaAutosize  style={{
                  padding: 10,
                  width: '630px',
                  minWidth: '200px',
                  fontSize: "1.0rem",
                  fontFamily: `'Roboto',sans-serif`,
                }}
                minRows={5}></TextareaAutosize>  
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="프로그램 선택"
          variant="filled"
          defaultValue={props.program}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ readOnly: true, style: { fontSize: "1.0rem" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="기본교실"
          variant="filled"
          defaultValue={props.classroom}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ readOnly: true, style: { fontSize: "1.0rem" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel id="status_label">프로그램 수강상태</InputLabel>
        <Select
          labelId="status_label"
          id="status"
          value="on"
          input={<FilledInput label="프로그램 수강상태" />}
          sx={{ textAlign: "right" }}
          renderValue={(value) => (
            <StatusChip type={'student_program'} value={value} />
          )}
          fullWidth
        >
          <MenuItem
            sx={{ justifyContent: "right" }}
            value='on'
          >
            <StatusChip type={'student_program'} value={"on"} />
          </MenuItem>
        </Select>
      </Grid>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={6}>
          <DatePicker
            label={"등록 날짜"}
            defaultValue={dayjs(props.start_date)}
            format="YYYY-MM-DD"
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                textAlign: "left",
                fontSize: "1.0rem",
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label={"종료 날짜"}
            defaultValue={dayjs(props.start_date)}
            format="YYYY-MM-DD"
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                textAlign: "left",
                fontSize: "1.0rem",
              },
            }}
          />
        </Grid>
      </LocalizationProvider>
      <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="save"
          sx={{
            borderRadius: 0,
            width: 140,
            height: 60,
            fontSize: "1.0rem",
          }}
        >
          저장
        </Button>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="cancel"
          sx={{
            borderRadius: 0,
            width: 140,
            height: 60,
            fontSize: "1.0rem",
          }}
        >
          취소
        </Button>
      </Grid>
    </Grid>
  );
}

function Image(){
    return <Box>이미지</Box>
}

function Lecture(){
    return <Box>수업</Box>
}

function Metadata() {
  return <Box>평가</Box>;
}

export default function LecutreDetail(props) {
  const TabList = [
    {
      label: "정보",
      component: () => {
        return <LectureInfo {...props} onClose={props.onClose} />;
      },
    },
    {
      label: "이미지",
      component: Image
    },
    {
      label: "수업",
      component: Lecture
    },
    { label: "부가정보", component: Metadata },
  ];
  const [value, setValue] = useState(TabList[0].label);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <TabContext value={value}>
        <Box sx={style}>
          <div style={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Tab Grids"
              >
                {TabList.map((tab, index) => {
                  return (
                    <Tab
                      key={"Table" + String(index)}
                      label={tab.label}
                      value={tab.label}
                      sx={{ fontSize: "1rem", fontWeight: "bold" }}
                    />
                  );
                })}
              </Tabs>
            </Box>
            {TabList.map((tab, index) => {
              return (
                <TabPanel value={tab.label} key={"Table" + String(index)}>
                  <tab.component />
                </TabPanel>
              );
            })}
          </div>
        </Box>
      </TabContext>
    </Modal>
  );
}

import { SpaceBar } from "@mui/icons-material";
import { Button, Modal } from "@mui/material";
import { common } from "@mui/material/colors";
import { Box } from "@mui/system";

function DeleteElement(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          height: 140,
          padding: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          bgcolor: "background.paper",
          boxShadow: 24,
        }}
      >
        <div className="modal">
          <h2>정말 삭제하시겠습니까?</h2>
          <Button variant="contained" color="secondary" onClick={props.onClose}>취소</Button>
          <SpaceBar sx={{ width: 30, color:common.white }} />
          <Button variant="contained" color="error" onClick={props.onDelete}>삭제</Button>
        </div>
      </Box>
    </Modal>
  );
}

export default DeleteElement;

import * as React from "react";
import MainCard from "ui-component/cards/MainCard";
import {
  Typography,
  Grid,
  Divider,
  Tabs,
  Tab,
  Box,
  Modal,
  Avatar,
  TextField,
  InputLabel,
  MenuItem,
  FilledInput,
  Select,
  Button,
  IconButton,
  ButtonBase,
  FormControl,
  Snackbar,
  Alert,
} from "@mui/material";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import MainMenuButton from "ui-component/buttons/MainMenuButton";
import { useState } from "react";
import {v4 as uuidv4} from "uuid"
import { TabContext, TabPanel } from "@mui/lab";
import {
  IconBrandPython,
  IconCloudUpload,
  IconDotsVertical,
  IconEye,
  IconUser,
} from "@tabler/icons";
import StatusChip from "ui-component/StatusChip";
import { common, grey } from "@mui/material/colors";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { createTheme, height } from "@mui/system";
import theme from "themes";
import { TextFieldsRounded } from "@mui/icons-material";
import {CreateStudent, CreateUIC, UpdateStudent, UpdateUIC} from "data/StudentDataAPI";
import { useDropzone } from "react-dropzone";
import { GetImage, GetImages, UploadImage } from "data/ImageDataAPI";
import { useEffect } from "react";


function StudentInfo(props) {  
  return (
    <Grid container spacing={3} sx={{overflow:'auto', maxHeight:620}}>
      <Grid item xs={6}>
        <TextField
          label="이름"
          variant="filled"
          value={props.formData.DisplayName}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ readOnly:props.readOnly , style: { fontSize: "1.0rem" } }}
          onChange={(e) => {
            props.handleChange("DisplayName", e.target.value);
          }}
          fullWidth
          />
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="filled" sx={{
            textAlign: "left",
            width: "100%",
            height:'100%',
            "& .MuiSelect-select": {
              paddingLeft: "12px",
            },
          }}>
          <InputLabel id = "GenderLabel" sx={{fontSize:"1rem"}}>성별</InputLabel>
        <Select
          labelId="gender_label"
          id="gender"
          onChange={(e) => {
            props.handleChange("Gender", e.target.value);
          }}
          value={props.formData.Gender}
          sx={{height:"100%"}}
          renderValue={(value) => <Typography fontSize={"1.0rem"}>{value.label}</Typography>}
        >
          <MenuItem
            sx={{ justifyContent: "right" }}
            value={{ label: "남", value: "M" }}
          >
            <Typography fontSize={"1.0rem"}>남</Typography>
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: "right" }}
            value={{ label: "여", value: "W" }}
          >
            <Typography fontSize={"1.0rem"}>여</Typography>
          </MenuItem>
        </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="학교"
          variant="filled"
          onChange={(e) => {
            props.handleChange("School", e.target.value);
          }}
          value={props.formData.School}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.0rem" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="학년"
          variant="filled"
          value={props.formData.GradeLevel}
          onChange={(e) => {
            props.handleChange("GradeLevel", e.target.value);
          }}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.0rem" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="센터ID"
          variant="filled"
          value={props.formData.CenterId}
          onChange={(e) => {
            props.handleChange("CenterId", e.target.value);
          }}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.0rem" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="학년_연도이름"
          variant="filled"
          value={props.formData.DisplaySchoolage}
          onChange={(e) => {
            props.handleChange("DisplaySchoolage", e.target.value);
          }}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.0rem" } }}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="보호자 전화번호"
          variant="filled"
          value={props.formData.PhoneParent}
          onChange={(e) => {
            props.handleChange("PhoneParent", e.target.value);
          }}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.0rem" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="보호자 관계"
          variant="filled"
          value={props.formData.ParentRelation}
          onChange={(e) => {
            props.handleChange("ParentRelation", e.target.value);
          }}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.0rem" } }}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="코딩스쿨 아이디"
          variant="filled"
          value={props.formData.UserName}
          onChange={(e) => {
            props.handleChange("UserName", e.target.value);
          }}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.0rem" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="코딩스쿨 비밀번호"
          variant="filled"
          color=""
          defaultValue={"******"}
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ readOnly: true, style: { fontSize: "1.0rem" } }}
          fullWidth
        />
      </Grid>
      
      <Grid item xs={12}>
      <FormControl variant="filled" sx={{
            textAlign: "right",
            width: "100%",
            height:'100%',
            "& .MuiSelect-select": {
              paddingLeft: "12px",
            },
          }}>
          <InputLabel id = "StatusLabel" sx={{fontSize:"1rem"}}>상태</InputLabel>
        <Select
          labelId="Status_label"
          id="Status"
          onChange={(e) => {
            props.handleChange("UserStatus", e.target.value);
          }}
          value={props.formData.UserStatus}
          sx={{height:"100%"}}
          renderValue={(value) => <StatusChip type={"student"} value={value} />}
        >
          <MenuItem
            sx={{ justifyContent: "right" }}
            value= "on" 
          >
            <StatusChip type={"student"} value={"on"} />
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: "right" }}
            value="waiting"
          >
            <StatusChip type={"student"} value={"waiting"} />
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: "right" }}
            value="off"
          >
            <StatusChip type={"student"} value={"off"} />
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: "right" }}
            value="deleted"
          >
            <StatusChip type={"student"} value={"deleted"} />
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: "right" }}
            value="sleeping"
          >
            <StatusChip type={"student"} value={"sleeping"} />
          </MenuItem>
        </Select>
        </FormControl>
      </Grid>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={6}>
          <DatePicker
            label={"등록 날짜"}
            value={props.formData.RegisterAt}
            onChange={(e) => {
              props.handleChange("RegisterAt", e);
            }}
            format="YYYY-MM-DD"
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                textAlign: "left",
                fontSize: "1.0rem",
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label={"종료 날짜"}
            defaultValue={props.formData.EndAt}
            format="YYYY-MM-DD"
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                textAlign: "left",
                fontSize: "1.0rem",
              },
            }}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
}

function StudentDetail(props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          label="본인 전화번호"
          variant="filled"
          value={props.formData.PhoneOwn}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.0rem" } }}
          onChange={(e) => {
            props.handleChange("PhoneOwn", e.target.value);
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="본인 메일주소"
          variant="filled"
          value={props.formData.Email}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.0rem" } }}
          onChange={(e) => {
            props.handleChange("Email", e.target.value);
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
        <TextField
          label="아이핀 계정 정보"
          variant="filled"
          color=""
          defaultValue={"아이핀 계정 정보에 관한 메모를 기록합니다"}
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ readOnly: true, style: { fontSize: "1.0rem" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextareaAutosize
          style={{
            padding: 10,
            width: '630px',
            minWidth: '200px',
            fontSize: "1.0rem",
            fontFamily: `'Roboto',sans-serif`,
          }}
          minRows={10}
          defaultValue={props.formData.MemoCs}
          onChange={(e) => {
            props.handleChange("MemoCs", e.target.value);
          }}
        />
      </Grid>    
    </Grid>
  );
}

function Image(props) {
  const [images, setImages] = useState(props.formData.Images || []);
  const {getRootProps, getInputProps,isDragActive,open} = useDropzone({
    accept: 'image/*',
    onDragOver: () => {
    },
    onDrop: async (acceptedFiles) => {
      await Promise.all(
        acceptedFiles.map(async (file) => {
          await UploadImage(file, props.formData.GuidId);
        })
      );
      const res = await GetImages(props.formData.GuidId);
      setImages(res);
    },
  });

  useEffect(() => {
    GetImages(props.formData.GuidId).then((res) => {
      if(res === null) res = [];
      else if(res.length === undefined){
        const temp = [];
        temp.push(res);
        res = temp;
      }
      setImages(res);
    });
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          fullWidth
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 3,
            backgroundColor: isDragActive?"rgba(0,0,0,0.2)": "rgba(0,0,0,0.05)",
            padding: 5,
          }}

          onClick={(e)=>{
            e.preventDefault();
            open();
          }}
         {...getRootProps()}
          
        >
          <input {...getInputProps()} />
          <IconCloudUpload size={36} />
          <Typography sx={{ fontSize: "1rem" }}>
            Drag and drop or Browse to upload
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Box sx ={{display:"flex",gap:3,flexDirection:"column",height: 400,overflowY : 'auto', width:"100%"}}>
      {images.map((image, index) => {
        return (
            <img
              src={process.env.REACT_APP_API_URL+"/image/?filepath="+props.formData.GuidId+"/"+image}
              alt={process.env.REACT_APP_API_URL+"/image/?filepath="+props.formData.GuidId+"/"+image}
              style={{ width: "30%" }}
            />
            );
          }
          )}     
          </Box>
        </Grid>

     
    </Grid>
  );
}



function Metadata() {
  return <Box>평가</Box>;
}

const TabList = [
  {
    label: "기본정보",
    component: (props) => {
      return <StudentInfo {...props} onClose={props.onClose} />;
    },
  },
  {
    label: "상세정보",
    component: StudentDetail,
  },
  {
    label: "이미지",
    component: Image,
  },
  { label: "소셜정보", component: Metadata },
];

export default function CRUDStudent(props) {
  
  const [formData, setFormData] = useState({
    GuidId: props.GuidId || uuidv4(),
    CenterId: props.CenterId || 1000001,
    DisplayName : props.DisplayName || "",
    Gender: props.Gender? props.Gender=='M'? {label: "남",value: props.Gender} : {label : "여", value:props.Gender} :"",
    School: props.School || "",
    GradeLevel: props.GradeLevel || "",
    MemoCs: props.MemoCs || "",
    UserStatus: props.UserStatus || "waiting",
    RegisterAt: props.RegisterAt ? dayjs(props.RegisterAt): dayjs(dayjs().year()+"-03-02"),
    EndAt : props.EndAt ? dayjs(props.EndAt) : null,
    PhoneOwn : props.PhoneOwn || "",
    Email : props.Email || "",
    PhoneParent : props.PhoneParent || "",
    ParentRelation : props. ParentRelation || "",
    UserName : props.UserName || null,
    DisplaySchoolage : props.DisplaySchoolage || "",
  });

  function formInit(){
    setFormData({
      DisplayName : props.DisplayName || "",
      Gender: props.Gender? props.Gender=='M'? {label: "남",value: props.Gender} : {label : "여", value:props.Gender} :"",
      School: props.School || "",
      GradeLevel: props.GradeLevel || "",
      MemoCs: props.MemoCs || "",
      UserStatus: props.UserStatus || "waiting",
      RegisterAt: props.RegisterAt ? dayjs(props.RegisterAt): dayjs(dayjs().year()+"-03-02"),
      EndAt : props.EndAt? dayjs(props.EndAt) : null,
      PhoneOwn : props.PhoneOwn || "",
      Email : props.Email || "",
      PhoneParent : props.PhoneParent || "",
      ParentRelation : props. ParentRelation || "",
      UserName : props.UserName || null,  
    })
  }

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [value, setValue] = useState(TabList[0].label);
  const [errorField,setErrorField] = useState("");
  const errorOpen = Boolean(errorField);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 800,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleError = (field)=>{
    setErrorField(field);
  }

  const handleErrorClose=(_, reason)=>{
    if(reason === 'clickaway'){
      return;
    }
    setErrorField("");
  }

  function checkForm(){
    for( const key in formData){
      if(formData[key] == "") {
        handleError(key);
        return 0
      }
    }
    return 1;
    
  }

  return (
    <Modal open={props.open} onClose={()=>{props.onClose(); formInit();}}>
      <TabContext value={value}>
        <Box sx={style}>
          <div style={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Tab Grids"
              >
                {TabList.map((tab, index) => {
                  return (
                    <Tab
                      key={"Table" + String(index)}
                      label={tab.label}
                      value={tab.label}
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        padding: 3,
                      }}
                    />
                  );
                })}
              </Tabs>
            </Box>
            {TabList.map((tab, index) => {
              return (
                <TabPanel value={tab.label} key={"Table" + String(index)}>
                  <tab.component
                    handleChange={handleInputChange}
                    formData={formData}
                    onClose={props.onClose}
                    readOnly = {props.readOnly}
                  />
                </TabPanel>
              );
            })}
          </div>
          <Grid container spacing={3} sx={{padding:3}}>
          <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
            {props.readOnly===undefined ?
            <>
            <Button
              onClick={async ()=>{
                if(props.UserId){
                  const res = await UpdateStudent({
                    ID: props.UserId,
                    FirstName: formData.DisplayName.slice(1),
                    LastName : formData.DisplayName.slice(0,1),
                    Gender : formData.Gender.value,
                    UserName : formData.UserName,
                    UserRole : "member",
                    MemoCs : formData.MemoCs,
                    GuidId : formData.GuidId,
                    UserName : formData.UserName,
                    RegisterAt : formData.RegisterAt,
                    Password : "password123"
                  });
                  await UpdateUIC({
                    ID: props.UICID,
                    UserId : res.ID,
                    CenterId : 1000001,//현재 로그인 된 유저의 CenterId
                    UserStatus : formData.UserStatus,
                    UserType : "student",
                    GradeLevel : formData.GradeLevel,
                    DisplaySchoolage : formData.DisplaySchoolage,
                    School: formData.School,
                    Email : formData.Email,
                    UserPhones : JSON.stringify({
                      own : formData.PhoneOwn || "",
                      parent : formData.PhoneParent || "",
                      relation : formData.ParentRelation || ""
                    })
                  })
                }else{
                  const res = await CreateStudent({
                    FirstName: formData.DisplayName.slice(1),
                    LastName : formData.DisplayName.slice(0,1),
                    Gender : formData.Gender.value,
                    UserName : formData.UserName,
                    UserRole : "member",
                    MemoCs : formData.MemoCs,
                    GuidId : formData.GuidId,
                    UserName : formData.UserName,
                    RegisterAt : formData.RegisterAt,
                    Password : "password123"
                  });
                await CreateUIC({
                  UserId : res.ID,
                  UserStatus : formData.UserStatus,
                  UserType : "student",
                  GradeLevel : formData.GradeLevel,
                  DisplaySchoolage : formData.DisplaySchoolage,
                  School : formData.School,
                  Email : formData.Email,
                  UserPhones : JSON.stringify({
                    own : formData.PhoneOwn || "",
                    parent : formData.PhoneParent || "",
                    relation : formData.ParentRelation || ""
                  })
                });
              }
              props.handleSave()
              }}
              variant="contained"
              color="save"
              sx={{
                borderRadius: 0,
                width: 140,
                height: 60,
                fontSize: "1.5rem",
              }}
            >
              저장
            </Button>
             <Button
             onClick={()=>{props.onClose();formInit()}}
             variant="contained"
             color="cancel"
             sx={{
               borderRadius: 0,
               width: 140,
               height: 60,
               fontSize: "1.5rem",
             }}
           >
             취소
           </Button>
           </>
           :
           <>
              <Button
             onClick={()=>{props.onClose();formInit()}}
             variant="contained"
             color="cancel"
             sx={{
               borderRadius: 0,
               width: 140,
               height: 60,
               fontSize: "1.5rem",
             }}
           >
             닫기
           </Button>
           </>
           }
           
          </Grid>
        </Grid>
        <Snackbar open={errorOpen} autoHideDuration={1800} onClose={handleErrorClose}>
          <Alert onClose={handleErrorClose} severity="error" >Please Enter {errorField} field</Alert>
        </Snackbar>
        </Box>
       
      </TabContext>
    </Modal>
  );
}

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  IconButton
} from '@mui/material';
import { IconX } from '@tabler/icons';
import { ChangePassword } from 'data/StudentDataAPI';

const PasswordChangeModalView = (props) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleClose = () => {
    setNewPassword('');
    setConfirmPassword('');
    setError('');
    props.onClose();
  };

  const handleSubmit = async () => {
    // 비밀번호 유효성 검사
    if (!newPassword || !confirmPassword) {
      setError('비밀번호를 입력해주세요.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('비밀번호가 일치하지 않습니다.');
      return;
    }

    if (newPassword.length < 6) {
      setError('비밀번호는 최소 6자 이상이어야 합니다.');
      return;
    }

    try {
      // API 호출
      await ChangePassword(props.students[0].UserId, props.students[0].GuidId, props.students[0].UserName, newPassword);
      handleClose();
    } catch (err) {
      setError('비밀번호 변경에 실패했습니다.');
      console.error('Failed to change password:', err);
    }
  };

  return (
    <Dialog 
      open={props.open} 
      onClose={props.onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          비밀번호 변경
          <IconButton onClick={props.onClose} size="small">
            <IconX />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {props.students[0].DisplayName} 학생의 비밀번호를 변경합니다.
          </Typography>
          <TextField
            label="새 비밀번호"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="비밀번호 확인"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {error && (
            <Typography color="error" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>취소</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          변경
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordChangeModalView; 
import { IconLayoutGrid } from '@tabler/icons';
import { IconHome } from '@tabler/icons';


const dashboard = {
    id:'dashboard',
    title: '대시보드',
    icon : IconLayoutGrid,
    // iconProps: { size: 18, stroke: 1.5 },
    type : 'group',
    children:[
        {
            id: 'timetable',
            title : '시간표',
            type : 'item',
            breadcrumbs : false,
            url : '/dashboard/timetable'
        },
        {
            id: 'progress',
            title : '진도표',
            type : 'item',
            breadcrumbs : false,
            url : '/dashboard/progress'
        },
        {
            id: 'operationmanage',
            title : '운영관리',
            type : 'item',
            breadcrumbs : false,
            url : '/dashboard/operationmanage'
        }
    ]
}
export default dashboard;
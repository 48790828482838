import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  Modal,
  ThemeProvider,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { common, grey } from "@mui/material/colors";
import { IconBrandMiniprogram, IconUser, IconX } from "@tabler/icons";
import { GetPrograms, AssignProgram } from "data/ProgramDataAPI";
import React from "react";
import { useState, useEffect } from "react";
import theme from "themes";
import StatusChip from "ui-component/StatusChip";

function CourseAssign(props) {
  
  const [programs, setPrograms] = useState([]);
  
  useEffect(() => {
    GetPrograms().then((res) => {
      setPrograms(res);
      setValue(res[0]);
    }
    );
  }, []);
  
  const [value, setValue] = useState({});

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onClose={() => {
          props.onClose();
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "5%" }} />
            <Typography variant="h2">프로그램 수강등록</Typography>
            <div style={{ flex: 1 }} />
            <ButtonBase onClick={props.onClose}>
              <IconX />
            </ButtonBase>
            <div style={{ width: "2%" }} />
          </Box>
          <Divider />
          <Grid
            container
            alignItems={"center"}
            spacing={3}
            justifyContent={"center"}
            sx={{ padding: 5 }}
          >
            {Array.isArray(props.students) &&
              props.students.map((student, index) => {
                return (
                  <Grid item xs={12}>
                    <div
                      style={{ display: "flex", gap: 10, alignItems: "center" }}
                    >
                      <Avatar
                        variant="rounded"
                        sx={{
                          width: "60px",
                          height: "60px",
                          backgroundColor: common.white,
                          color: grey[600],
                          border: "1px solid",
                        }}
                      >
                        <IconUser size={60} />
                      </Avatar>
                      <Typography variant="h4" sx={{ justifySelf: "flex-end" }}>
                        {`${student.DisplayName} ${
                          student.Gender === "M" ? "남" : "여"
                        },${student.GradeLevel},${student.School}`}
                      </Typography>
                      <div style={{ marginLeft: "auto" }}>
                        <StatusChip
                          type={"student"}
                          value={student.UserStatus}
                        />
                      </div>
                    </div>
                  </Grid>
                );
              })}
            <Grid item xs={12} sx={{ width: "90%" }}>
              <FormControl fullWidth>
                <Select
                  value={value}
                  onChange={handleChange}
                  SelectDisplayProps={{ style: { padding: 10 } }}
                >
                  {programs.map((program, index) => {
                    return (
                      <MenuItem value={program}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <Avatar
                            variant="square"
                            sx={{
                              width: "40px",
                              height: "40px",
                              backgroundColor: common.white,
                              color: grey[600],
                              border: "1px solid",
                            }}
                          >
                            <IconBrandMiniprogram size={36} />
                          </Avatar>
                          <Typography fontSize={"1.0rem"}>
                            {program.ProgramTitle}
                          </Typography>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
              <Button
                onClick={() => {
                  props.onClose();
                  props.students.map((student, _) => {
                    return AssignProgram(student, value).then((res) => {
                      return res;
                    });
                  });
                }}
                variant="contained"
                color="save"
                sx={{
                  borderRadius: 0,
                  width: 140,
                  height: 60,
                  fontSize: "1.5rem",
                }}
              >
                등록
              </Button>
              <Button
                onClick={props.onClose}
                variant="contained"
                color="cancel"
                sx={{
                  borderRadius: 0,
                  width: 140,
                  height: 60,
                  fontSize: "1.5rem",
                }}
              >
                취소
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}

export default CourseAssign;

import dayjs from "dayjs";

const API_URL = process.env.REACT_APP_API_URL

export async function GetHolidays(year) {
    console.log('called');
    const res = await fetch(API_URL + `/centers/holidays?year=` + year).catch((error) => {
        console.log(error);
    });
    var result = await res.json();
    if (result === null) result = [];
    if (result.length === undefined) {
        const temp = [];
        temp.push(result); 
        result = temp;
    }
    result.map((holiday, _) => {
        holiday.id = holiday.ID;
    });
    return result;
}

export async function CreateHoliday(holiday) {
    const res = await fetch(API_URL + "/centers/holidays", {
        method: "POST",
        body: JSON.stringify({
            TheDate: holiday,
        }),
    });
    const result = await res.json();
    return result;
}

export async function DeleteHoliday(date) {
    const res = await fetch(API_URL + "/centers/holidays/" + date, {
        method: "DELETE",
    });
    const result = await res.json();
    return result;
}
import ClassIcon from '@mui/icons-material/Class'
import { IconUser, IconChalkboard , IconUserCheck, IconSettings} from '@tabler/icons';

const icons = {
  ClassIcon,
  IconUser,
  IconChalkboard,
  IconUserCheck,
  IconSettings
}
// ==============================|| Classwork MENU ITEMS ||============================== //

const setting = {
  id: 'setting',
  title: '설정',
  icon: icons.IconSettings,
  type: 'group',
  children: [
    {
      id: 'account',
      title: '계정',
      type: 'item',
      breadcrumbs: false,
      url : '/setting/account'
    },
    {
      id: 'system',
      title: '시스템',
      type: 'item',
      icon: icons.IconChalkboard,
      breadcrumbs: false,
      url : '/setting/system'
    }
  ]
};

export default setting;

// import { lazy } from 'react';

// project imports
import MainLayout from "layout/MainLayout";
// import Loadable from 'ui-component/Loadable';

// dashboard routing
import SamplePage from "views/defaultView";
import GridTable from "views/GridTable";
import GridTab from "views/GridTab";
import ProgressTab from 'views/tables/dashboard/ProgressTab'
import OperationTab from 'views/OperationTab'

import {ClassroomTable} from "views/tables/information/ClassroomTable";

import { StudentTable } from 'views/tables/dashboard/StudentManage/StudentTable'
import {StudentCourseTable} from 'views/tables/dashboard/StudentManage/StudentCourseTable'
import { CounselTable } from "views/tables/dashboard/StudentManage/CounselTable";
import { MessageTable } from "views/tables/dashboard/StudentManage/MessageTable";
import Timetable from 'views/tables/dashboard/Timetable'
import generateRandomData from "data/ProgressDataGen";
import { studentdata } from "data";
import I_ClassroomTable from "views/tables/information/ClassroomTable";
import I_StudentTable from "views/tables/information/StudentTable";
import I_ProgramTable from "views/tables/information/ProgramTable";
import O_StudentTable from 'views/tables/dashboard/OperationManage/StudentTable'
import O_CourseProgress from "views/tables/dashboard/OperationManage/CourseProgress";
import O_CounselTable from "views/tables/dashboard/OperationManage/CounselTable";
import O_MessageTable from "views/tables/dashboard/OperationManage/MessageTable";
import O_StudentCourseTable from "views/tables/dashboard/OperationManage/StudentCourseTable";
import O_StudentClassroomTable from "views/tables/dashboard/OperationManage/StudentClassroom";
import StudnetLectures from "views/tables/dashboard/OperationManage/StudentLectures";
import ProgramLectures from "views/tables/information/ProgramLectures";
import InformationTab from "views/InformationTab";
import CalendarPage from "views/settings/CalendarPage";
import { Navigate } from "react-router";

// const stduent_data = StudentTable();
// const student_course_data = StudentCourseTable();
// const timetable_data = Timetable();
// const counsel_table_data = CounselTable();
// const message_table_data = MessageTable();

const dayTimetableTabs = [{label:'월',table:Timetable},{label:'화',table:Timetable},{label:'수',table:Timetable},{label:'목',table:Timetable},{label:'금',table:Timetable},{label:'토',table:Timetable},{label:'일',table:Timetable},{label:'전체',table:Timetable}];
const dayClassroomTabs = [{label:'월',table:I_ClassroomTable},{label:'화',table:I_ClassroomTable},{label:'수',table:I_ClassroomTable},{label:'목',table:I_ClassroomTable},{label:'금',table:I_ClassroomTable},{label:'토',table:I_ClassroomTable},{label:'일',table:I_ClassroomTable},{label:'전체',table:I_ClassroomTable}];
const operationTabs = [{label:'학생',table:O_StudentTable},{label:'수업진행',table:O_CourseProgress},{label:'상담',table:O_CounselTable},{label:'메시지',table:O_MessageTable},{label:'수업후기',table:O_StudentTable},{label:'학생수강',table:O_StudentCourseTable},{label:'교실배정',table:O_StudentClassroomTable}]

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path:"/",
      element: <Navigate to="/dashboard/timetable"/>
    },
    {
      path:"login",
      element: <Navigate to="/dashboard/timetable"/>
    },
    {
      path: "dashboard",
      children:[
        {
          path: "timetable",
          element: (
            <GridTab
              Title="시간표"
              key={"timetabledata"}
              TabList={dayTimetableTabs}
            />
          ),
        },
        {
          path: "progress",
          element: (
            <ProgressTab
              Title="진도표"
              TabData={ generateRandomData(25)}
              key={"progressdata"}
              TabList={dayTimetableTabs}
            />
          ),
        },
        {
          path: "operationmanage",
          children:[
            {
              path:'',
              element: (
                <OperationTab
                  Title='운영관리'
                  key={'operationmanage'}
                  TabList={operationTabs}
                  />
              ),
            },
            {
              path:'studentlectures/:id',
              element:(
                <StudnetLectures/>
              )
            }
          ]
        },
      ]
    },
    {
      path: "info",
      children: [
        {
          path: "class",
          element: (
            <InformationTab
              Title="교실"
              key={"classroomdata"}
              TabList={dayClassroomTabs}
            />
          ),
        },
        {
          path: "student",
          element: (
            <GridTable
            Title={"학생"}
            dataTable={I_StudentTable}
          />
          ),
        },
        {
          path: "program",
          children: [{
            path:'',
            element: (
              <GridTable
              Title={"프로그램"}
              dataTable={I_ProgramTable}
            />
            )
          },
          {
            path:'lectures/:id',
            element:(
              <ProgramLectures/>
            )
          }
          ]
        },
        {
          path: "employee",
          element:  <GridTable
          Title="정보관리>프로그램"
          dataTable={O_CourseProgress}
          key="programinfo"
        />
        },
        {
          path: "cms",
          element: <SamplePage />,
        },
      ],
    },
    {
      path: "setting",
      children: [
        {
          path: "account",
          element: (
            <SamplePage/>
          ),
        },
        {
          path: "system",
          element: (
            <CalendarPage/>
          ),
          }
      ],
    },
    {
      path: "statistics",
      children: [
        {
          path: "lecture",
          element: (
            <SamplePage/>
          ),
        },
        {
          path: "student",
          element: (
            <SamplePage/>
          ),
        }
      ],
    },
  ],
};

export default MainRoutes;

import { ButtonBase, Card, Paper, Popover, Select } from "@mui/material";
import { useState } from "react";
import { IconUser, IconX } from "@tabler/icons";
import { grey, red } from "@mui/material/colors";
import { Button, Avatar,createTheme, ThemeProvider } from "@mui/material";
import {
  ListItem,
  ListItemText,
  Typography,
  Grid,
  List,
  Menu,
  Divider,
  MenuItem
} from "@mui/material";
import AttendenceChip from '../AttendenceChip'
import origin_theme from "themes";
function StudentModal(props) {
  const anchorEl = props.anchorEl;
  const handleClose = props.handleClose
  const handleSingleMemo = props.handleSingleMemo;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const [program,setProgram] = useState(0);
  // const handleChange = (event)=>{
  //   setProgram(event.target.value)
  // }
  // const theme = createTheme(origin_theme(),{
  //   components:{
  //     MuiButtonBase:{
  //       overrides:{
  //         root:{
  //           minWidth:40,
  //           width:'40px'
  //         }
  //       }
  //     }
  //   }
  // })
  return (
    <div style={{width:50, margin:2}}>
       <Menu
          id="hamburger-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'hamburger-button',
          }}
        >
          <MenuItem onClick={()=>{props.setAttend('wait',props.index); handleClose()}}><AttendenceChip label={'대기'} color={'wait'} /> </MenuItem>
          <Divider/>
          <MenuItem onClick={()=>{props.setAttend('attend',props.index); handleClose()}}><AttendenceChip label={'출석'} color={'attend'} /> </MenuItem>
          <Divider/>
          <MenuItem onClick={()=>{props.setAttend('absent',props.index); handleClose()}}><AttendenceChip label={'결석'} color={'absent'} /> </MenuItem>
          <Divider/>
          <MenuItem onClick={()=>{props.setAttend('late',props.index); handleClose()}}><AttendenceChip label={'지각'} color={'late'} /> </MenuItem>
          <Divider/>
          <MenuItem onClick={()=>{props.setAttend('leaveEarly',props.index); handleClose()}}><AttendenceChip label={'조퇴'} color={'leaveEarly'} /> </MenuItem>
          <Divider/>
          <MenuItem onClick={()=>{props.setAttend('online',props.index); handleClose()}}><AttendenceChip label={'온라인'} color={'online'} /> </MenuItem>
          <Divider/>
          <MenuItem onClick={handleSingleMemo}>출결메모</MenuItem>
          <Divider/>
          <MenuItem onClick={handleClose}>메시지 발송</MenuItem>
          <Divider/>
          <MenuItem onClick={handleClose}>학생정보</MenuItem>
        </Menu>
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card sx={{ width: 400, height: 250 }}>
          <Paper
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div></div>
            <Button onClick={handleClose}>
              <IconX color={grey[400]} />
            </Button>
          </Paper>
          <Grid container spacing={2}>
            <Grid
              item
              sx={{ borderRight: "2px solid #ccc", width: 300 }}
              id="123"
            >
              <Grid container direction="column" spacing={2}>
                <Grid item xs>
                  <Grid container spacing={2}>
                    <Grid item xs sx={{ height: 170 }}>
                      <Avatar
                        variant="circle"
                        sx={{ width: 100, height: 100, margin: 2 }}
                      >
                        <IconUser size={100} />
                      </Avatar>
                    </Grid>
                    <Grid item xs sx={{ marginTop: 5 }}>
                      <Typography variant="h2">{props.name}({props.grade})</Typography>
                      <Typography variant="h4">{props.school}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs sx={{alignSelf:'center'}}>
                  <Select
                    value={program}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                    </MenuItem>
                    {props.programs.map((pr,index)=>(<MenuItem value={index} key={`menuItem-${index}`} >{pr.name}</MenuItem>))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <List sx={{ padding: 0 }}>
                <ListItem
                  button
                  sx={{ padding: 0.5, paddingRight: 1, textAlign: "right" }}
                >
                  <ListItemText primary="메시지" />
                </ListItem>
                <ListItem
                  button
                  sx={{ padding: 0.5, paddingRight: 1, textAlign: "right" }}
                >
                  <ListItemText primary="보강예약" />
                </ListItem>
                <ListItem
                  button
                  sx={{ padding: 0.5, paddingRight: 1, textAlign: "right" }}
                >
                  <ListItemText primary="수업후기" />
                </ListItem>
                <ListItem
                  button
                  sx={{ padding: 0.5, paddingRight: 1, textAlign: "right" }}
                >
                  <ListItemText primary="메모" />
                </ListItem>
                <ListItem
                  button
                  sx={{ padding: 0.5, paddingRight: 1, textAlign: "right" }}
                >
                  <ListItemText primary="기본정보" />
                </ListItem>
                <ListItem
                  button
                  sx={{ padding: 0.5, paddingRight: 1, textAlign: "right" }}
                >
                  <ListItemText primary="상세정보" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Card>
      </Popover> */}
    </div>
  );
}

export default StudentModal;

import * as React from "react";
import { IconBrandMiniprogram, IconUser } from "@tabler/icons";
import {
  Avatar,
  Divider,
  Box,
  ButtonBase,
  Typography,
  Select,
  FormControl,
  Checkbox,
} from "@mui/material";
import { grey, common } from "@mui/material/colors";
import IconButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconDotsVertical } from "@tabler/icons";
import { forwardRef } from "react";
import {  DataGridPro } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";


// assets
import ClassroomAssign from "ui-component/modals/ClassroomAssign";
import ChangeClassroom from "ui-component/modals/ChangeClassroom";
import { GetClassrooms, GetClassroomStudents } from "data/ClassroomDataAPI";


export default function O_StudentClassroomTable(props) {
  const [classrooms,setClassrooms] = useState([]);
  const [value, setValue] = useState({});
  const [changed, setChanged] = useState([]);

  React.useEffect(()=>{
    GetClassrooms().then((res)=>{
      setClassrooms(res);
      setValue(res[0]);
    })
  },[])

  const [studentClassroom, setStudentClassroom] = useState([]);

  React.useEffect(() => {
    GetClassroomStudents(value).then((res) => {
      setStudentClassroom(res);
    });
  }, [value]);

  const reload = ()=>{
    const temp = value;
    setValue({});
    setTimeout(()=>{
      setValue(temp);
    },100);
    
  }

  const SearchSection = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [changeAnchorEl, setChangeAnchorEl] = useState(null);
    const [assignAnchorEl, setAssignAnchorEl] = useState(null);

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (e) => {
      setValue(e.target.value);
    };

    const handleAssign = (e)=>{
      setAssignAnchorEl(e.target);
    }

    const handleAssignClose=()=>{
      setAssignAnchorEl(null);
    }

    const handleChangeClassroom = (e)=>{
      setChangeAnchorEl(e.currentTarget); 
    }

    const handleChangeClose=()=>{
      setChangeAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const assignOpen = Boolean(assignAnchorEl);
    const changeOpen = Boolean(changeAnchorEl);

    return (
      <>
        <div style={{ display: "flex", gap: 10 }}>
          <Box sx={{ display: { xs: "none", md: "block" }, flex: 1 }}>
            <FormControl fullWidth>
              <Select value={value} onChange={handleChange} SelectDisplayProps={{style:{padding:10}}}>
                {classrooms.map((classroom, index) => {
                  return (
                    <MenuItem value={classroom}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <Avatar
                          variant="square"
                          sx={{
                            width: "40px",
                            height: "40px",
                            backgroundColor: common.white,
                            color: grey[600],
                            border: "1px solid",
                          }}
                        >
                          <IconBrandMiniprogram size={36} />
                        </Avatar>
                        <Typography fontSize={'1.1rem'}>{classroom.ClassroomTitle}</Typography>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <ButtonBase
            onClick={handleClick}
            sx={{ height: "60px", width: "100px", backgroundColor: "#f2f5fa" }}
          >
            <Typography fontSize={"1rem"}>Actions</Typography>
            <ArrowDropDown sx={{ marginLeft: "10px" }} />
          </ButtonBase>
          <Menu
            id="hamburger-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "200px", // 원하는 너비로 설정,
                fontSize: "1.7rem",
              },
            }}
            sx={{
              "& .MuiMenuItem-root": {
                fontSize: "1.1rem",
              },
            }}
            MenuListProps={{
              "aria-labelledby": "hamburger-button",
            }}
          >
            <MenuItem onClick={handleAssign}>학생배정 추가</MenuItem>
            <Divider />
            <MenuItem disabled={props.selectedRow.length<1} onClick={handleChangeClassroom}>{`(선택)교실배정 변경`}</MenuItem>
          </Menu>
          <ChangeClassroom {...props} open={changeOpen} onClose={handleChangeClose} students={props.selectedRow} classrooms={classrooms}/>
          <ClassroomAssign {...props} open={assignOpen} onClose={handleAssignClose} classrooms = {classrooms} classroom={value} reload={reload}/>
        </div>
      </>
    );
  };


  function RenderStudnetName(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            width: "50px",
            height: "50px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconUser size={50} />
        </Avatar>
        {props.row.DisplayName}
      </div>
    );
  }


  function RenderHasMessage(props) {
    if (props.value === "true")
      return (
        <Avatar sx={{ bgcolor: "#fd726a", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
    else
      return (
        <Avatar sx={{ bgcolor: "#3a63de", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
  }

  const MenuButton = forwardRef((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [minianchorEl, setminiAnchorEl] = React.useState(null);
    const [changeAnchorEl, setChangeAnchorEl] = React.useState(null);
    // const [attend, setAttend] = React.useState("attend");
    const open = Boolean(anchorEl);
    // const miniopen = Boolean(minianchorEl);
    const changeOpen = Boolean(changeAnchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget.parentElement);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleChangeClassroom = (e)=>{
      setChangeAnchorEl(e.currentTarget);
    }
    const handleChangeClose = ()=>{
      setChangeAnchorEl(null);
    }

    return (
      <div>
        <IconButton
          aria-label="more"
          size="small"
          sx={{ width: "40px", padding: 0 }}
          id="hamburger-button"
          aria-controls={open ? "hamburger-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          disableRipple={true}
          onClick={handleClick}
        >
          <IconDotsVertical color={grey[800]} />
        </IconButton>
        <Menu
          id="hamburger-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
        >
          
          <MenuItem onClick={handleChangeClassroom}>교실배정 변경</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}></MenuItem>
        </Menu>
        <ChangeClassroom open={changeOpen} onClose={handleChangeClose} students={[props.row]} classrooms={classrooms}/>
      </div>
    );
  });

  function RenderClassroomAssignMenu(props) {
    return <MenuButton {...props}/>;
  }

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // Hide columns status and traderName, the other columns will remain visible
        id: false,
      },
    },
    pagination: {
      paginationModel: { pageSize: 25, page: 0 },
    },
  };

  function handleChange(item, e) {
    const selectedIds = new Set(item);
    const selectedRows = studentClassroom.filter((row) => selectedIds.has(row.id));
    props.setSelectedRow(selectedRows);
  }

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "DisplayName",
      headerName: "이름",
      renderCell: RenderStudnetName,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "grade",
      headerName: "학년",
      width: 40,
      renderCell: (props)=>{return props.row.GradeLevel},
      headerAlign: "center",
      align: "center",
    },
    {
      field: "school",
      headerName: "학교",
      renderCell: (props)=>{return props.row.School},
      width: 60,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "hasMessage",
      headerName: "",
      minWidth: 20,
      width: 20,
      renderCell: RenderHasMessage,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lecture_progress",
      headerName: "수업진행",
      renderCell:()=>{
        return '15/48'
      },
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
    },
    {
      field: "program",
      headerName: "프로그램",
      minWidth: 120,
      renderCell:(props)=>{
        return props.row.ProgramTitle
      },
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "classroom_start",
      headerName: "교실시작",
      width: 120,
      renderCell:(props)=>{
        return props.row.StartAt? props.row.StartAt.split('T')[0] : null
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "classroom_end",
      headerName: "교실종료",
      width: 120,
      renderCell:(props)=>{
        return props.row.EndAt? props.row.EndAt.split('T')[0] : null
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "menu",
      headerName: "",
      width: 70,
      renderCell: RenderClassroomAssignMenu,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const student_course_table_data = {
    rows: studentClassroom,
    columns: columns,
    initialState: initialState,
    disableRowSelectionOnClick: true,
    checkboxSelection: true,
    onRowSelectionModelChange: handleChange,
    slots: {
      BaseCheckbox: React.forwardRef((props, _) => {
        return <Checkbox {...props} />;
      }),
      toolbar:SearchSection
    },
    rowHeight: 90,
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <DataGridPro
         localeText={{
          noRowsLabel: '데이터가 없습니다',
        }}
          // experimentalFeatures={{ lazyLoading: true }}
          sx={{
            fontSize: "1rem",
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              px: "1px !important",
            },
          }}
          autoHeight
          {...student_course_table_data}
          pagination
          apiRef={props.apiRef}
        />
      </div>
    </div>
  );
}

import * as React from 'react';
import IconButton from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconMenu } from '@tabler/icons';
import { forwardRef } from 'react';
import { grey } from '@mui/material/colors';

const MenuButton= forwardRef((props,ref)=>{
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="hamburger-button"
        aria-controls={open ? 'hamburger-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <IconMenu color={grey[800]} />
      </IconButton>
      <Menu
        id="hamburger-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'hamburger-button',
        }}
      >
        <MenuItem onClick={handleClose}>상세정보</MenuItem>
        <MenuItem onClick={handleClose}>교실 수정/삭제</MenuItem>
        <MenuItem onClick={handleClose}>수강생 목록</MenuItem>
      </Menu>
    </div>
  );
})

export default MenuButton;
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  Modal,
  ThemeProvider,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Avatar,
  TextField,
  InputAdornment,
} from "@mui/material";
import { common, grey } from "@mui/material/colors";
import {
  IconDotsVertical,
  IconPlus,
  IconSearch,
  IconUser,
  IconX,
} from "@tabler/icons";
import { AssignClassroom } from "data/ClassroomDataAPI";
import { AssignProgram, GetStudentProgram } from "data/ProgramDataAPI";
import { GetStudents } from "data/StudentDataAPI";
import React from "react";
import { useState } from "react";
import theme from "themes";
import StatusChip from "ui-component/StatusChip";

function ClassroomAssign(props) {
  const [value, setValue] = useState(props.classroom);
  const [searchValue, setSearchValue] = useState("");
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);

  

  React.useEffect(() => {
    GetStudentProgram().then((res) => {
      const s = res.sort(function (a, b) {
        return a.DisplayName > b.DisplayName ? 1 : -1;
      });
      setStudents(s);
    });
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onClose={() => {
          props.onClose();
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "5%" }} />
            <Typography variant="h2">학생교실배정 추가</Typography>
            <div style={{ flex: 1 }} />
            <ButtonBase onClick={props.onClose}>
              <IconX />
            </ButtonBase>
            <div style={{ width: "2%" }} />
          </Box>
          <Divider />
          <Grid
            container
            alignItems={"center"}
            spacing={3}
            justifyContent={"center"}
            sx={{ padding: 5 }}
          >
            <Grid item xs={12} sx={{ width: "90%" }}>
              <FormControl
                variant="filled"
                sx={{
                  textAlign: "left",
                  width: "100%",
                  height: "100%",
                  "& .MuiSelect-select": {
                    paddingLeft: "12px",
                  },
                }}
              >
                <InputLabel id="ClassroomLabel" sx={{ fontSize: "1rem" }}>
                  기본교실
                </InputLabel>
                <Select value={value} onChange={handleChange}>
                  {props.classrooms.map((classroom, index) => {
                    return (
                      <MenuItem value={classroom}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <Typography fontSize={"1.1rem"}>
                            {classroom.ClassroomTitle}
                          </Typography>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="filled"
                sx={{
                  textAlign: "right",
                  width: "100%",
                  height: "100%",
                  "& .MuiSelect-select": {
                    paddingLeft: "12px",
                  },
                }}
              >
                <InputLabel id="StudentLabel" sx={{ fontSize: "1rem" }}>
                  학생 검색
                </InputLabel>
                <Select
                  labelId="Student_label"
                  id="Student"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: '420px',
                        overflow: 'auto',
                      },
                    },
                  }}
                  value ={null}
                >
                  <Box sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white'}}>
                  <TextField
                    variant="outlined"
                    placeholder="찾기"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconSearch
                            stroke={1.5}
                            size="1.5rem"
                            color={grey[500]}
                          />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    fullWidth
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    onBlur={(e) => {
                      e.preventDefault();
                      e.target.focus();
                    }}
                     onClick={(e) => {
                      e.stopPropagation();
                     }}
                  />
                  </Box>
                  {students
                    .filter((student) => {
                      //영어검색과 한글 검색이 모두 되어야 하고, searchValue가 포함된 값은 true, searchValue 가 공백일 경우는 전체를 보여줌.
                      if (
                        student.DisplayName.toLowerCase().includes(
                          searchValue.toLowerCase()
                        ) ||
                        student.DisplayName.includes(searchValue)
                        || student.ProgramTitle.toLowerCase().includes(
                          searchValue.toLowerCase()
                        ) ||
                        student.ProgramTitle.includes(searchValue) 
                        || searchValue === ""
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    })
                    .map((student, index) => {
                      return (
                        <MenuItem
                          sx={{ justifyContent: "left" }}
                          value={student}
                          key={index}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                              width: "100%",
                            }}
                          >
                            <Avatar
                              variant="rounded"
                              sx={{
                                width: "40px",
                                height: "40px",
                                backgroundColor: common.white,
                                color: grey[600],
                                border: "1px solid",
                              }}
                            >
                              <IconUser size={36} />
                            </Avatar>
                            <Typography sx={{ width: "60px" }}>
                              {student.DisplayName}
                            </Typography>
                            <Typography sx={{ width: "30px" }}>
                              {student.GradeLevel}
                            </Typography>
                            <Typography sx={{ width: "60px" }}>
                              {student.School}
                            </Typography>
                            <Typography sx={{ width: "100px" }}>
                              {student.ProgramTitle}
                            </Typography>
                            <div
                              style={{
                                flex: "1 0 auto",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <StatusChip
                                type={"student"}
                                value={student.UserStatus}
                              />
                            </div>
                            <ButtonBase
                              sx={{
                                height: "30px",
                                width: "30px",
                                backgroundColor: "#3a63de",
                                color: common.white,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (!selectedStudents.includes(student)) {
                                  setSelectedStudents([
                                    ...selectedStudents,
                                    student,
                                  ]);
                                }
                              }}
                            >
                              <IconPlus />
                            </ButtonBase>
                          </div>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Divider sx={{ width: "100%", marginTop: "20px" }} />
            <Typography
              variant="h4"
              sx={{ justifySelf: "left", marginTop: "10px" }}
            >
              배정 학생 목록
            </Typography>
            {selectedStudents.map((student, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap : 2,
                    paddingLeft: "0",
                    marginTop: "10px",
                    width:'80%'
                  }}
                >
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: common.white,
                      color: grey[600],
                      border: "1px solid",
                    }}
                  >
                    <IconUser size={36} />
                  </Avatar>
                  <Typography sx={{ width: "60px" }}>
                    {student.DisplayName}
                  </Typography>
                  <Typography sx={{ width: "30px" }}>
                    {student.GradeLevel}
                  </Typography>
                  <Typography sx={{ width: "60px" }}>
                    {student.School}
                  </Typography>
                  <Typography sx={{ width: "120px" }}>
                    {student.ProgramTitle}
                  </Typography>
                  <div
                    style={{
                      flex: "1 0 auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <StatusChip type={"student"} value={student.UserStatus} />
                  </div>
                  <ButtonBase
                    sx={{
                      height: "20px",
                      width: "20px",
                      color: grey[600],
                      flex: "1 0 auto",
                    }}
                    onClick={(e) => {
                    }}
                  >
                    <IconDotsVertical />
                  </ButtonBase>
                </Grid>
              );
            })}
            <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
              <Button
                onClick={() => {
                  props.onClose();
                  if(selectedStudents.length > 0){
                    selectedStudents.forEach((studentprogram)=>{
                      AssignClassroom(studentprogram, value)
                    })
                    props.reload();
                  }
                }}
                variant="contained"
                color="save"
                sx={{
                  borderRadius: 0,
                  width: 140,
                  height: 60,
                  fontSize: "1.0rem",
                }}
              >
                등록
              </Button>
              <Button
                onClick={props.onClose}
                variant="contained"
                color="cancel"
                sx={{
                  borderRadius: 0,
                  width: 140,
                  height: 60,
                  fontSize: "1.0rem",
                }}
              >
                취소
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}

export default ClassroomAssign;

import * as React from "react";
import {
  DataGridPro,
} from "@mui/x-data-grid-pro";
import {
  Typography,
  Grid,
  Tabs,
  Tab,
  Box,
  Modal,
  Avatar,
  TextField,
  InputLabel,
  MenuItem,
  FilledInput,
  Select,
  Button,
  ButtonBase,
} from "@mui/material";
import { useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import { IconBrandPython, IconDotsVertical, IconUser } from "@tabler/icons";
import StatusChip from "ui-component/StatusChip";
import { common, grey } from "@mui/material/colors";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { GetClassrooms } from "data/ClassroomDataAPI";


function CourseInfo(props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <Avatar
            variant="rounded"
            sx={{
              width: "60px",
              height: "60px",
              backgroundColor: common.white,
              color: grey[600],
              border: "1px solid",
            }}
          >
            <IconUser size={60} />
          </Avatar>
          <Typography variant="h3" sx={{ justifySelf: "flex-end" }}>
            {props.DisplayName}
          </Typography>
          <div style={{ marginLeft: "auto" }}>
            <StatusChip
              type={"student"}
              value={"on"}
              sx={{ width: "100px", height: "30px" }}
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="성별"
          variant="filled"
          defaultValue={props.Gender === "M" ? "남" : "여"}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ readOnly: true, style: { fontSize: "1.1rem" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="학교"
          variant="filled"
          defaultValue={props.School}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ readOnly: true, style: { fontSize: "1.1rem" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="학년"
          variant="filled"
          defaultValue= {props.GradeLevel}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ readOnly: true, style: { fontSize: "1.1rem" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="프로그램"
          variant="filled"
          defaultValue={props.ProgramTitle}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ readOnly: true, style: { fontSize: "1.1rem" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel id="status_label">프로그램 수강상태</InputLabel>
        <Select
          labelId="status_label"
          id="status"
          defaultValue= "on"
          value={props.UserprogramStatus}
          input={<FilledInput label="프로그램 수강상태" />}
          sx={{ textAlign: "right" }}
          renderValue={(value) => (
            <StatusChip type={'student_program'} value={value} />
          )}
          fullWidth
        >
          <MenuItem
            sx={{ justifyContent: "right" }}
            value='on'
          >
            <StatusChip type={'student_program'} value={'on'} />
          </MenuItem>
        </Select>
      </Grid>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={6}>
          <DatePicker
            label={"등록 날짜"}
            defaultValue={dayjs(props.start_date)}
            format="YYYY-MM-DD"
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                textAlign: "left",
                fontSize: "1.1rem",
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label={"종료 날짜"}
            defaultValue={dayjs(props.start_date)}
            format="YYYY-MM-DD"
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                textAlign: "left",
                fontSize: "1.1rem",
              },
            }}
          />
        </Grid>
      </LocalizationProvider>
      <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
        <Button
          onClick={()=>{
            props.onClose()
            
          }}
          variant="contained"
          color="save"
          sx={{
            borderRadius: 0,
            width: 140,
            height: 60,
            fontSize: "1.4rem",
          }}
        >
          저장
        </Button>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="cancel"
          sx={{
            borderRadius: 0,
            width: 140,
            height: 60,
            fontSize: "1.4rem",
          }}
        >
          취소
        </Button>
      </Grid>
    </Grid>
  );
}

function StudentProgress(props) {
  const [studentLectures, setStudentLectures] = useState([]);

  
    
  const initialState = {
    columns: {
      columnVisibilityModel: {
        // Hide columns status and traderName, the other columns will remain visible
        id: false,
      },
    },
  };

  function RenderMenu(){
    return <ButtonBase>
      <IconDotsVertical></IconDotsVertical>
    </ButtonBase>
  }

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "order",
      headerName: "",
      minWidth: 25,
      width:25
        },
    {
      field: "avatar",
      renderCell: () => {
        return (
          <Avatar variant="square">
            <IconBrandPython />
          </Avatar>
        );
      },
      headerName: "",
      width: 60,
    },
    {
      field: "name",
      headerName: "이름",
      flex:1,
      align: "center",
    },
    {
      field: "lecture_date",
      width: 120,
    },
    {
      field: "status",
      headerAlign: "center",
      width:40,
      align: "center",
    },
    {
      field: "menu",
      width:40,
      align: "center",
      renderCell: RenderMenu
    },
  ];

  const lecture_data = {
    rows: rowData,
    columns: columns,
    initialState: initialState,
    rowHeight: 70,
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
        <DataGridPro
         localeText={{
          noRowsLabel: '데이터가 없습니다',
        }}
          {...lecture_data}
          columnHeaderHeight={0}
          checkboxSelection
          hideFooter
          sx={{
            "& .MuiDataGrid-cellContent": { fontSize: "1rem" },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-virtualScroller":
              {
                overflow: "hidden !important",
              },
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="save"
          sx={{
            borderRadius: 0,
            width: 140,
            height: 60,
            fontSize: "1.0rem",
          }}
        >
          닫기
        </Button>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="cancel"
          sx={{
            borderRadius: 0,
            width: 140,
            height: 60,
            fontSize: "1.0rem",
          }}
        >
          취소
        </Button>
      </Grid>
    </Grid>
  );
}

const rowData = [
  {
    id: "1",
    name: "화1500교실A",
    start_date: "2023-03-02",
    register_date: "2023-03-02",
  },
  {
    id: "2",
    name: "수1500교실A",
    start_date: "2023-03-02",
    register_date: "2023-03-02",
  },
  {
    id: "3",
    name: "목1500교실A",
    start_date: "2023-03-02",
    register_date: "2023-03-02",
  },
  {
    id: "4",
    name: "금1500교실A",
    start_date: "2023-03-02",
    register_date: "2023-03-02",
  },
  {
    id: "5",
    name: "화1300교실A",
    start_date: "2023-03-02",
    register_date: "2023-03-02",
  },
  {
    id: "6",
    name: "화1400교실A",
    start_date: "2023-03-02",
    register_date: "2023-03-02",
  },
  {
    id: "7",
    name: "화1600교실A",
    start_date: "2023-03-02",
    register_date: "2023-03-02",
  },
  {
    id: "8",
    name: "화1500교실A",
    start_date: "2023-03-02",
    register_date: "2023-03-02",
  },
];
const initialState = {
  columns: {
    columnVisibilityModel: {
      // Hide columns status and traderName, the other columns will remain visible
      id: false,
    },
  },
};

function RenderMenu(){
  return <ButtonBase>
    <IconDotsVertical></IconDotsVertical>
  </ButtonBase>
}
const columns = [
  {
    field: "id",
    hide: true,
  },
  {
    field: "avatar",
    renderCell: () => {
      return (
        <Avatar variant="square">
          <IconBrandPython />
        </Avatar>
      );
    },
    headerName: "",
    width: 60,
  },
  {
    field: "name",
    headerName: "교실",
    headerAlign:'center',
    flex:1,
    align: "center",
  },
  {
    headerName: "시작날짜",
    field: "start_date",
    width: 120,
  },
  {
    headerName: "등록날짜",
    field: "register_date",
    width:120,
    align: "center",
  },
  {
    field: "menu",
    headerName:'',
    width:40,
    align: "center",
    renderCell: RenderMenu,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },
];

const classroom_data = {
  rows: rowData,
  columns: columns,
  initialState: initialState,
  rowHeight: 70,
};


function ClassroomAssign(props) {
  const [classrooms,setClassrooms] = useState([]);
  const [value, setValue] = useState(props.classroom);

  React.useEffect(()=>{
    
    GetClassrooms().then((data)=>{
      setClassrooms(data);
      setValue(data.find((classroom)=>classroom.id===props.classroom))
    })

  },[]) 
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
      <InputLabel id="status_label">기본교실</InputLabel>
        <Select
          labelId="status_label"
          id="status"
          value={value}
          onChange={(event)=>{setValue(event.target.value)}}
          input={<FilledInput label="기본교실" />}
          inputProps={{style:{fontSize:'1.1rem'}}}
          sx={{ textAlign: "left", '& .MuiMenuItem-root':{fontSize:'1.1rem'} }}
          fullWidth
        >
          {classrooms.map((classroom,index)=>{
            return <MenuItem
              key={index}
              value={classroom}
              sx={{ justifyContent: "right" }}
            >
              <Typography variant="h5">{classroom.ClassroomTitle}</Typography>
            </MenuItem>
          })}
        </Select>
      </Grid>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={6}>
          <DatePicker
            label={"시작 날짜"}
            value={value?dayjs(value.StartAt):null}
            format="YYYY-MM-DD"
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                textAlign: "left",
                fontSize: "1.1rem",
              },
            }}
            />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label={"종료 날짜"}
            value={value?dayjs(value.EndAt):null}
            format="YYYY-MM-DD"
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                textAlign: "left",
                fontSize: "1.1rem",
              },
            }}
          />
        </Grid>
      </LocalizationProvider>
      <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
      <DataGridPro
       localeText={{
        noRowsLabel: '데이터가 없습니다',
      }}
          {...classroom_data}
          checkboxSelection
          hideFooter
          sx={{
            maxWidth:'652px',
            maxHeight: '408px',
            "& .MuiDataGrid-cellContent": { fontSize: "1rem" },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-virtualScroller":
              {
                overflowX: "hidden !important",
                overflowY : 'auto'
              },
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="save"
          sx={{
            borderRadius: 0,
            width: 140,
            height: 60,
            fontSize: "1.0rem",
          }}
        >
          닫기
        </Button>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="cancel"
          sx={{
            borderRadius: 0,
            width: 140,
            height: 60,
            fontSize: "1.0rem",
          }}
        >
          취소
        </Button>
      </Grid>
    </Grid>
  );
}

function Evaluation() {
  return <Box>평가</Box>;
}

export default function CourseDetail(props) {
  const TabList = [
    {
      label: "수강정보",
      component: () => {
        return <CourseInfo {...props.row} onClose={props.onClose} />;
      },
    },
    {
      label: "학생단원",
      component: () => {
        return <StudentProgress {...props.row} onClose={props.onClose} />;
      },
    },
    {
      label: "교실배정",
      component: () => {
        return <ClassroomAssign {...props.row} onClose={props.onClose} />;
      },
    },
    { label: "평가", component: Evaluation },
  ];
  const [value, setValue] = useState(TabList[0].label);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <TabContext value={value}>
        <Box sx={style}>
          <div style={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Tab Grids"
              >
                {TabList.map((tab, index) => {
                  return (
                    <Tab
                      key={"Table" + String(index)}
                      label={tab.label}
                      value={tab.label}
                      sx={{ fontSize: "1rem", fontWeight: "bold" }}
                    />
                  );
                })}
              </Tabs>
            </Box>
            {TabList.map((tab, index) => {
              return (
                <TabPanel value={tab.label} key={"Table" + String(index)}>
                  <tab.component />
                </TabPanel>
              );
            })}
          </div>
        </Box>
      </TabContext>
    </Modal>
  );
}

import ClassIcon from '@mui/icons-material/Class'
import { IconUser, IconChalkboard , IconUserCheck, IconStack2} from '@tabler/icons';

const icons = {
  ClassIcon,
  IconUser,
  IconChalkboard,
  IconUserCheck,
  IconStack2
}
// ==============================|| Classwork MENU ITEMS ||============================== //

const info = {
  id: 'info',
  title: '정보관리',
  type: 'group',
  icon: icons.IconStack2,
  children: [
    {
      id: 'info_student',
      title: '학생',
      type: 'item',
      icon: icons.IconUser,
      breadcrumbs: false,
      url : '/info/student'
    },
    {
      id: 'program',
      title: '프로그램',
      type: 'item',
      icon: icons.IconChalkboard,
      breadcrumbs: false,
      url : '/info/program'
    },
    {
      id: 'info_class',
      title: '교실',
      type: 'item',
      icon: icons.ClassIcon,
      breadcrumbs: false,
      url : '/info/class'
    },
    {
      id: 'employee',
      title: '직원',
      type: 'item',
      icon: icons.IconUserCheck,
      breadcrumbs: false,
      url : '/info/employee',
      disabled:false
    },
    {
      id: 'cms',
      title: 'CMS',
      type: 'item',
      icon: icons.IconUserCheck,
      breadcrumbs: false,
      url : '/info/cms'
    },
  ]
};

export default info;

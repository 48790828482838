// project imports

// action - state management
import * as actionTypes from './actions';



export const initialState = {
  token: null,
  user: null
};
// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const authenticationReducer =  (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        user : action.payload.user,
        token: action.payload.token
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        user: null
      };
    case 'BeforeLogin':
      return {
        ...state,
        before: action.payload
      };
    default:
      return state;
  }
};

export default authenticationReducer;

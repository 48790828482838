// material-ui
import { Typography, Grid, Button, Box, Tab, Tabs, IconButton } from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';

// project imports
import MainCard from "ui-component/cards/MainCard";
import CSCalendar from "ui-component/CSCalendar";
import dayjs from "dayjs";
import React from "react";

import { GetHolidays } from "data/CalandarDataAPI";
import { KeyboardArrowLeft, KeyboardArrowRight, ViewModule, ViewWeek } from "@mui/icons-material";

// ==============================|| CALENDAR PAGE ||============================== //

function CalendarView({ year, setYear, holidays, reload }) {
  const [viewMode, setViewMode] = React.useState('year');
  const [currentMonth, setCurrentMonth] = React.useState(dayjs().month() + 1);

  const handleViewModeToggle = () => {
    setViewMode(prev => prev === 'year' ? 'month' : 'year');
  };

  const handlePrevMonth = () => {
    if (currentMonth === 1) {
      setYear(year - 1);
      setCurrentMonth(12);
    } else {
      setCurrentMonth(prev => prev - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 12) {
      setYear(year + 1);
      setCurrentMonth(1);
    } else {
      setCurrentMonth(prev => prev + 1);
    }
  };

  return (
    <Box sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column'
    }}>
      {/* 헤더 영역 */}
      <Box sx={{ 
        display: "flex", 
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        py: 2
      }}>
        <Box sx={{ 
          display: "flex", 
          alignItems: "center",
          border: '1px solid #e0e0e0',
          borderRadius: 1,
          px: 1
        }}>
          <IconButton 
            size="small"
            onClick={viewMode === 'year' ? () => setYear(year - 1) : handlePrevMonth}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Typography variant="h3" sx={{ mx: 2 }}>
            {viewMode === 'year' ? year : `${year}. ${currentMonth}월`}
          </Typography>
          <IconButton 
            size="small"
            onClick={viewMode === 'year' ? () => setYear(year + 1) : handleNextMonth}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Box>
        <IconButton 
          onClick={handleViewModeToggle}
          sx={{ ml: 2 }}
        >
          {viewMode === 'year' ? <ViewWeek /> : <ViewModule />}
        </IconButton>
      </Box>

      {/* 달력 영역 */}
      <Box sx={{ 
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}>
        {viewMode === 'year' ? (
          // 연간 보기
          <Grid
            container
            spacing={1}
            sx={{ width: "100%", flex: 1 }}
          >
            {[...Array(12)].map((_, index) => (
              <Grid item xs={4} key={index + 1}>
                <CSCalendar
                  year={year}
                  month={(index + 1).toString().padStart(2, '0')}
                  holidays={holidays}
                  reload={reload}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          // 월간 보기
          <Box sx={{ 
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
            <Box sx={{ 
              width: '100%',
              maxWidth: '1200px',
              mx: 'auto',
              px: 4,
              '& .calendar-container': {
                '& .MuiTypography-root': {
                  fontSize: '2.5rem'
                },
                '& .MuiButton-root': {
                  fontSize: '2.5rem',
                  padding: '8px 16px'
                },
                '& .MuiIconButton-root': {
                  padding: '12px'
                }
              }
            }}>
              <CSCalendar
                year={year}
                month={currentMonth.toString().padStart(2, '0')}
                holidays={holidays}
                reload={reload}
                isMonthView={true}
                className="calendar-container"
                sx={{
                  '& .MuiPickersCalendarHeader-root': {
                    fontSize: '2.5rem',
                    padding: '16px 0'
                  },
                  '& .MuiDayCalendar-weekDayLabel': {
                    fontSize: '2.5rem',
                    width: '48px',
                    height: '48px'
                  },
                  '& .MuiPickersDay-root': {
                    fontSize: '2.5rem',
                    width: '48px',
                    height: '48px'
                  },
                  '& .MuiPickersDay-today': {
                    fontSize: '2.5rem'
                  }
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

function SystemView() {
  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <Typography variant="h4">시스템 설정</Typography>
      </Grid>
      {/* 시스템 설정 관련 컴포넌트들을 여기에 추가 */}
    </Grid>
  );
}

function CalendarPage() {
  const [year, setYear] = React.useState(dayjs().year());
  const [holidays, setHolidays] = React.useState([]);
  const [tabValue, setTabValue] = React.useState("calendar");
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const res = await GetHolidays(year);
        if (!res) {
          setHolidays([]);
          return;
        }
        setHolidays(res);
        setError(null);
      } catch (err) {
        console.error("Failed to fetch holidays:", err);
        setHolidays([]);
        setError("휴일 정보를 가져오는데 실패했습니다.");
      }
    };
    
    fetchHolidays();
  }, [year]);

  const reload = async () => {
    try {
      const res = await GetHolidays(year);
      if (!res) {
        setHolidays([]);
        return;
      }
      setHolidays(res);
      setError(null);
    } catch (err) {
      console.error("Failed to reload holidays:", err);
      setHolidays([]);
      setError("휴일 정보를 새로고침하는데 실패했습니다.");
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <MainCard title="설정" sx={{ overflow: "auto", mt:0 }}>
      <TabContext value={tabValue} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: 0 }}>
          <TabList onChange={handleTabChange}>
            <Tab label="달력" value="calendar" />
            <Tab label="시스템" value="system" />
          </TabList>
        </Box>
        <TabPanel value="calendar" sx={{ p: 0, pt: 1 }}>
          {error ? (
            <Box sx={{ 
              p: 2, 
              display: 'flex', 
              justifyContent: 'center',
              color: 'error.main'
            }}>
              <Typography>{error}</Typography>
            </Box>
          ) : (
            <CalendarView 
              year={year} 
              setYear={setYear} 
              holidays={holidays} 
              reload={reload} 
            />
          )}
        </TabPanel>
        <TabPanel value="system" sx={{ p: 0, pt: 2 }}>
          <SystemView />
        </TabPanel>
      </TabContext>
    </MainCard>
  );
}

export default CalendarPage;
    
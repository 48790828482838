import * as React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import MainCard from "ui-component/cards/MainCard";
import {
  Typography,
  Grid,
  Divider,
  Tabs,
  Tab,
  Box,
  Avatar,
  Button,
  ButtonBase,
  Card,
  InputAdornment,
  OutlinedInput,
  Menu,
  MenuItem,
} from "@mui/material";
import MainMenuButton from "ui-component/buttons/MainMenuButton";
import { TabContext, TabPanel } from "@mui/lab";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { IconAdjustmentsHorizontal, IconPlus, IconSearch } from "@tabler/icons";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import generateRandomData from "data/ProgressDataGen";
import { common, grey } from "@mui/material/colors";
import StatusChip from "ui-component/StatusChip";
import ProgressCell from "ui-component/ProgressCell";
import { ArrowDropDown, Person } from "@mui/icons-material";
import { shouldForwardProp } from "@mui/system";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import { useState } from "react";
// material-ui
import { useTheme, styled } from "@mui/material/styles";
import { useEffect } from "react";
import { GetThisMonthStudent, GetThisMonthStudentSchedules } from "data/ClassroomDataAPI";

// assets

export default function FixedSizeGrid({ Title, TabData, TabList }) {
  const componentRef = React.useRef(null);
  const [componentWidth,setComponentWidth] = useState(0);
  const [scrollCell,setScrollCell]=useState(0);
  const today = new Date();
  const [value, setValue] = useState(`${((today.getDay() + 6) % 7)}`);//월요일이 0이 되도록 설정 1->0, 2->1, 3->2, 4->3, 5->4, 6->5, 0->6

  const [student_progress_data, setStudents] = useState([]);

  React.useEffect(()=>{
    if(componentRef.current){
      const width = componentRef.current.getBoundingClientRect().width;
      setComponentWidth(width);
    }
  },[componentRef]);

  React.useEffect(()=>{
    GetThisMonthStudent(dayName()).then(async(data)=>{
      const updatedData = await Promise.all(data.map(async(item)=>{
        const schedule = await GetThisMonthStudentSchedules(item.UserId);
        item.progress_count = schedule.length;
        item.progresses = schedule;
        return item;
      }
      ))
      setStudents(updatedData);
      console.log(updatedData);
    })
  },[value]);
  

  const max_cell_cnt = Math.floor((componentWidth-232.21)/220);
  const cellWidth = ((componentWidth-232.21)/max_cell_cnt);

  
  
  const dataCount = 12;
  const apiRef = useGridApiRef();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dayName = ()=>{
    switch(value){
      case '0': return 'MON';
      case '1': return 'TUE';
      case '2': return 'WED';
      case '3': return 'THU';
      case '4': return 'FRI';
      case '5': return 'SAT';
      case '6': return 'SUN';
    }
  }
 
  const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
    ({ theme }) => ({
      width: "100%",
      paddingLeft: 16,
      paddingRight: 16,
      fontSize: "1.5rem",
      "& input": {
        background: "transparent !important",
        paddingLeft: "4px !important",
        "&::placeholder": {
          fontSize: "1.5rem",
          color: grey[800],
        },
      },
      [theme.breakpoints.down("lg")]: {
        width: "90%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginLeft: 4,
        background: "#fff",
      },
    })
  );

  const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
    ({ theme }) => ({
      ...theme.typography.commonAvatar,
      ...theme.typography.mediumAvatar,
      background: theme.palette.grey[100],
      color: theme.palette.grey[500],
      "&:hover": {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light,
      },
    })
  );

  const SearchSection = () => {
    const theme = useTheme();
    const [value, setValue] = useState("");
    const [anchorEl,setAnchorEl] = useState(null);

    const handleClick=((e)=>{
      componentRef.current.parentElement.style.width=`${componentWidth}px`;
      setAnchorEl(e.currentTarget);
    })

    const handleClose= (()=>{
      componentRef.current.parentElement.style='100%';
      setAnchorEl(null);
    })

    const open = Boolean(anchorEl);

    return (
      <div style={{ display: "flex", gap: 10}}>
        <Box sx={{ display: { xs: "none", md: "block" }, flex: 1 }}>
          <OutlineInputStyle
            id="input-search-header"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onFocus={()=>{componentRef.current.parentElement.style.width=`${componentWidth}px`;}}
            onBlur={()=>{console.log(value==='');if(value==='')componentRef.current.parentElement.style.width=`100%`;}}
            placeholder="진도 검색"
            startAdornment={
              <InputAdornment position="start">
                <IconSearch
                  stroke={1.5}
                  size="1.5rem"
                  color={theme.palette.grey[500]}
                />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <ButtonBase sx={{ borderRadius: "12px" }}>
                  <HeaderAvatarStyle variant="rounded">
                    <IconAdjustmentsHorizontal stroke={1.5} size="1.0rem" />
                  </HeaderAvatarStyle>
                </ButtonBase>
              </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ "aria-label": "weight" }}
          />
        </Box>
        <ButtonBase
          onClick={handleClick} sx={{ height: "60px", width: "100px", backgroundColor: "#f2f5fa" }}
        >
          <Typography fontSize={"1rem"}>Actions</Typography>
          <ArrowDropDown sx={{ marginLeft: "10px" }} />
        </ButtonBase>
        <Menu
          id="hamburger-menu3"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "150px", // 원하는 너비로 설정,
              fontSize: "1.7rem",
            },
          }}
          sx={{
            "& .MuiMenuItem-root": {
              fontSize: "1rem",
            },
          }}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
        >
          <MenuItem onClick={handleClose}>진도처리</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>보강예약</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>보충예약</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>스킵처리</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>메모</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>평가(시험)</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>수업후기</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>메시지</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>진도정보</MenuItem>
        </Menu>
        <ButtonBase
          sx={{
            height: "60px",
            width: "60px",
            backgroundColor: "#3a63de",
            color: common.white,
          }}
        >
          <IconPlus />
        </ButtonBase>
      </div>
    );
  };

  const max_progress_cnt = (()=>{
    let MaxVal = -Infinity;
    for(const item of student_progress_data){
      if(item.progress_count>MaxVal) MaxVal= item.progress_count;
    }
    return MaxVal;
  })()

  function RenderStatus(props) {
    return <StatusChip type={"student_program"} value={props.value} />;
  }

  function RenderStudentAvatar(props) {
    if (props.value) return <Avatar src={props.value} />;
    else {
      return (
        <Avatar
          variant="rounded"
          sx={{
            border: `1px solid`,
            borderColor: grey[600],
            backgroundColor: common.white,
            width: "50px",
            height: "50px",
            color: grey[600],
            fontSize: "50px",
          }}
        >
          <Person fontSize="inherit" />
        </Avatar>
      );
    }
  }

  function StatusBox(status) {
    const color = status === true ? "#ed5b53" : "#20a144";
    return (
      <Box
        sx={{
          width: "14px",
          height: "14px",
          backgroundColor: color,
          borderRadius: "3px",
        }}
      />
    );
  }

  function RenderNameCell(props) {
    setComponentWidth(componentRef.current.getBoundingClientRect().width);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const grade = props.row.GradeLevel;
    const handleNameMenu = (e) => {
      setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
      <>
        {" "}
        <ButtonBase
          onClick={handleNameMenu}
          sx={{ width: "100%", height: "70px" }}
        >
          <div
            style={{
              display: "grid",
              rowGap: 15,
              marginLeft: 10,
              marginTop: 10,
            }}
          >
            <StatusBox status={false} />
            <Typography variant="h3">
              {props.row.DisplayName + " (" + grade + ")"}
            </Typography>
          </div>
        </ButtonBase>
        <Menu
          id="hamburger-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "150px", // 원하는 너비로 설정,
              fontSize: "1.0rem",
            },
          }}
          sx={{
            "& .MuiMenuItem-root": {
              fontSize: "1rem",
            },
          }}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
        >
          <MenuItem onClick={handleClose}>메시지</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>메모</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>상담</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>학생정보</MenuItem>
        </Menu>
      </>
    );
  }

  

  function RenderProgressCell(props) {
    const number = props.field.split("_")[1];
    if (props.row.progress_count >= number)
      return <ProgressCell progress={props.row.progresses[number - 1]} />;
    else return null;
  }

  const handleScroll = () => {
    apiRef.current.scrollToIndexes({ colIndex: Math.floor((apiRef.current.getScrollPosition().left+5)/cellWidth)+3+max_cell_cnt });
    setScrollCell(Math.floor((apiRef.current.getScrollPosition().left+5)/cellWidth)+3+max_cell_cnt);
  };
  const handleScrollLeft = () => {
    apiRef.current.scrollToIndexes({ colIndex:Math.floor((apiRef.current.getScrollPosition().left+5)/cellWidth)+2 });
    setScrollCell(Math.floor((apiRef.current.getScrollPosition().left+5)/cellWidth)+2);
  };


  function RenderStudentMenu(props) {
    if (props.row.progress_count > max_cell_cnt && scrollCell<props.row.progress_count+3)
      return (
        <ButtonBase
          onClick={handleScroll}
          sx={{ width: "20px", height: "70px" }}
        >
          <ArrowRightIcon fontSize="large" sx={{ color: grey[800] }} />
        </ButtonBase>
      );
  }

  

  function RenderStudentLeftMenu(props) {
    if (scrollCell>2)
      return (
        <ButtonBase
          onClick={handleScrollLeft}
          sx={{ width: "20px", height: "70px" }}
        >
          <ArrowLeftIcon fontSize="large" sx={{ color: grey[800] }} />
        </ButtonBase>
      );
  }


  const cellPadingZero = {
    padding: "1px !important",
  };

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // Hide columns status and traderName, the other columns will remain visible
        id: false,
      },
    },
    pinnedColumns: {
      left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "avatar", "name",'leftmenu'],
      right: ["menu"],
    },
  };
  
  

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "avatar",
      renderCell: RenderStudentAvatar,
      headerName: "",
      width: 60,
    },
    {
      field: "name",
      headerName: "이름",
      renderCell: RenderNameCell,
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "leftmenu",
      renderCell: RenderStudentLeftMenu,
      width: 20,
      minWidth: 20,
      headerName: "",
      align: "left",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "progress_1",
      headerName: "진도1",
      minWidth: cellWidth,
      flex: 1,
      renderCell: RenderProgressCell,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "progress_2",
      headerName: "진도2",
      minWidth: cellWidth,
      flex: 1,
      renderCell: RenderProgressCell,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "progress_3",
      headerName: "진도3",
      minWidth: cellWidth,
      flex: 1,
      renderCell: RenderProgressCell,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "progress_4",
      headerName: "진도4",
      minWidth: cellWidth,
      flex: 1,
      renderCell: RenderProgressCell,
      headerAlign: "center",
      align: "center",
    },
    
  ];

  for(let i =5;i<=max_progress_cnt;i++){
    columns.push({
      field: `progress_${i}`,
    headerName: `진도${i}`,
    minWidth: cellWidth,
    flex: 1,
    renderCell: RenderProgressCell,
    headerAlign: 'center',
    align: 'center',
    });
  }

  columns.push({
      field: "menu",
      renderCell: RenderStudentMenu,
      width: 20,
      minWidth: 20,
      headerName: "",
      align: "left",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    });


  const progress_data = {
    rows: student_progress_data,
    columns: columns,
    initialState: initialState,
    columnHeaderHeight: 0,
    components: { toolbar: SearchSection },
    rowHeight: 70,
  };

  return (
    <MainCard>
      <TabContext value={value}>
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Tab Grids"
              >
                {TabList.map((tab, index) => (
                  <Tab
                    label={tab.label}
                    value={String(index)}
                    key={"Tab" + String(index)}
                    sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                  />
                ))}
              </Tabs>
            </Box>
            {TabList.map((_, index) => (
              <TabPanel value={String(index)} key={"Table" + String(index)}>
                <div style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <DataGridPro
                     localeText={{
                      noRowsLabel: '데이터가 없습니다',
                    }}
                      experimentalFeatures={{ lazyLoading: true }}
                      ref={componentRef}
                      sx={{
                        fontSize: "1rem",
                        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell":
                          { px: "1px !important" },
                        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-virtualScroller":{
                          overflow:'hidden !important'
                        }
                      }}
                      autoHeight
                      {...progress_data}
                      pagination
                      apiRef={apiRef}
                      disableRowSelectionOnClick
                    />
                  </div>
                </div>
              </TabPanel>
            ))}
          </div>
        </div>
      </TabContext>
    </MainCard>
  );
}

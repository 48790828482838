import React from "react";
import { Chip, ThemeProvider, createTheme} from "@mui/material";
import { common } from "@mui/material/colors";

//상태 표시를 위한 Chip 컴포넌트
// label : 제목,  color : palette 색상(코드가 아닌 테마 내의 색상 이름 ex- 'success', 'info' )

function StatusChip({label, color }) {
  const theme = createTheme({
    palette:{
        attend:{
            main: '#859eea',
            contrastText:common.white,
        },
        late:{
            main: '#ffde9d',
            contrastText:common.white,
        },
        leaveEarly:{
            main:'#8bdfaf',
            contrastText:common.white,
        },
        online:{
            main:'#c096f2',
            contrastText:common.white,
        },
        absent:{
            main: '#fea7a2',
            contrastText:common.white,
        },
        wait:{
            main:'#b1b2b4',
            contrastText:common.white,
        }
    }
  })
  var fontSize = "1rem";
  if (label.length === 4) fontSize = "1rem";
  return (
    <ThemeProvider theme={theme}>
      <Chip
        label={label}
        color={color}
        sx={{
          width: 60,
          height: "25px",
          color: "white",
          borderRadius: "0px",
          fontSize: { fontSize },
          "& .MuiChip-label": {
            padding: "0px", // 라벨 내의 padding을 0으로 설정
          },
        }}
      />
    </ThemeProvider>
  );
}

export default StatusChip;

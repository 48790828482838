
export async function GetTeachers() {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + `/users/?role=teacher`);
  const result = await res.json();
  result.map((teacher, _) => {
    teacher.id = teacher.UserId;
  });
  return result;
}
export async function GetTeacher(id) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/" + id);
  const result = await res.json();
  return result;
}

export async function CreateTeacher(teacher) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/", {
    method: "POST",
    body: JSON.stringify(teacher),
  });
  const result = await res.json();
  return result;
}

export async function CreateUIC(uic) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/center", {
    method: "POST",
    body: JSON.stringify(uic),
  });
  const result = await res.json();
  return result;
}

export async function UpdateTeacher(teacher) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/"+teacher.ID, {
    method: "PUT",
    body: JSON.stringify(teacher),
  });
  const result = await res.json();
  return result;
}

export async function UpdateUIC(uic) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/center/"+uic.ID, {
    method: "PUT",
    body: JSON.stringify(uic),
  });
  const result = await res.json();
  return result;
}


import StatusChip from "ui-component/StatusChip";
import AttendenceChip from "ui-component/AttendenceChip";
import * as React from "react";
import { IconBrandPython, IconUser } from "@tabler/icons";
import {
  Avatar,
  Button,
  Divider,
  Box,
  ButtonBase,
  Card,
  InputAdornment,
  OutlinedInput,
  Typography,
  Popper,
  Input,
  Checkbox,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import { lecturedata } from "data";
import { red, grey, common } from "@mui/material/colors";
import ClassStudent from "ui-component/buttons/ClassStudent";
import IconButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconDotsVertical } from "@tabler/icons";
import { forwardRef } from "react";
import {
  GridToolbarQuickFilter,
  DataGridPro,
  useGridApiRef,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";

// material-ui
import { useTheme, styled } from "@mui/material/styles";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { alpha, shouldForwardProp } from "@mui/system";
import { useRef } from "react";
import CourseAssign from "ui-component/modals/CourseAssign";
import ChangeClassroom from "ui-component/modals/ChangeClassroom";
import MainCard from "ui-component/cards/MainCard";
import LecutreDetail from "ui-component/modals/LectureDeatil";

const programs = [
  { name: "파이썬기본@2023" },
  { name: "파이썬기본@2022" },
  { name: "파이썬기본@2021" },
  { name: "파이썬기본@2025" },
  { name: "파이썬기본@2026" },
  { name: "파이썬기본@2027" },
];

export default function StudnetLectures(props) {
  const [selectedRow, setSelectedRow] = useState([]);
  const apiRef = useGridApiRef();
  const CustomQuickFilter = styled(GridToolbarQuickFilter, {
    shouldForwardProp,
  })(({ theme }) => ({
    width: "100%",
    fontSize: "1.0rem",
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
      height: "29px",
      fontSize: "1.0rem",
      "&::placeholder": {
        fontSize: "1.0rem",
        color: grey[800],
      },
    },
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background: "#fff",
    },
  }));

  const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
    ({ theme }) => ({
      ...theme.typography.commonAvatar,
      ...theme.typography.mediumAvatar,
      background: theme.palette.grey[100],
      color: theme.palette.grey[500],
      "&:hover": {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light,
      },
    })
  );

  const handleXClick = (e, index, id) => {
    const list = selectedRow.filter((_, i) => i !== index);
    setSelectedRow(list);
    apiRef.current.selectRow(id, false);
  };

  function SelectedStudents(props) {
    return (
      <Box
        key={`student${props.index}`}
        sx={{
          borderRadius: "2px",
          backgroundColor: "#e3e6eb",
          padding: "2px",
          width: "114px",
          height: "45px",
        }}
      >
        <ButtonBase
          id={`xButton${props.index}`}
          onClick={(e) => handleXClick(e, props.index, props.id)}
          sx={{ marginBottom: 3, width: 14, height: 14, zIndex: 1000 }}
        >
          <IconX style={{ zIndex: 800 }} />
        </ButtonBase>
      </Box>
    );
  }

  const SearchSection = () => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [courseAnchorEl, setCourseAnchorEl] = useState(null);
    const [changeAnchorEl, setChangeAnchorEl] = useState(null);

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleSkip=()=>{

    }
    const handleCourseClose = () => {
      setCourseAnchorEl(null);
      setAnchorEl(null);
    };

    const handleChangeClassroom = (e) => {
      setChangeAnchorEl(e.currentTarget);
    };

    const handleChangeClose = () => {
      setChangeAnchorEl(null);
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const courseOpen = Boolean(courseAnchorEl);
    const changeOpen = Boolean(changeAnchorEl);

    return (
      <>
        <div style={{ display: "flex", gap: 10 }}>
          <Box sx={{ display: { xs: "none", md: "block" }, flex: 1 }}>
            <CustomQuickFilter
              variant="outlined"
              placeholder="단원 검색"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch
                      stroke={1.5}
                      size="1.5rem"
                      color={theme.palette.grey[500]}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ heigth: "60px" }}>
                    <ButtonBase sx={{ borderRadius: "12px" }}>
                      <HeaderAvatarStyle variant="rounded">
                        <IconAdjustmentsHorizontal stroke={1.5} size="1.0rem" />
                      </HeaderAvatarStyle>
                    </ButtonBase>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ButtonBase
            onClick={handleClick}
            sx={{ height: "60px", width: "100px", backgroundColor: "#f2f5fa" }}
          >
            <Typography fontSize={"1rem"}>Actions</Typography>
            <ArrowDropDown sx={{ marginLeft: "10px" }} />
          </ButtonBase>
          <Menu
            id="hamburger-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "150px", // 원하는 너비로 설정,
                fontSize: "1.7rem",
              },
            }}
            sx={{
              "& .MuiMenuItem-root": {
                fontSize: "1rem",
              },
            }}
            MenuListProps={{
              "aria-labelledby": "hamburger-button",
            }}
          >
            <MenuItem
              onClick={handleSkip}
              disabled={selectedRow.length === 0}
            >
              {`(선택) 스킵으로 변경`}
            </MenuItem>
          </Menu>
          <CourseAssign
            open={courseOpen}
            onClose={handleCourseClose}
            students={selectedRow}
            programs={programs}
          />
          <ChangeClassroom
            open={changeOpen}
            onClose={handleChangeClose}
            students={[selectedRow]}
          />
        </div>
        {/* Selected Students */}
        <div
          style={{
            overflowX: "auto",
            backgroundColor: alpha("#009eb2", 0.14),
            display: "flex",
          }}
        >
          {selectedRow.length > 0 && (
            <>
              <Box
                sx={{
                  borderLeft: `5px solid #009eb2`,

                  height: 60,
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <div></div>
                {selectedRow.map((row, index) => {
                  return (
                    <div key={`student${index}`}>
                      <SelectedStudents {...row} index={index} />
                    </div>
                  );
                })}
              </Box>
              <Box sx={{ width: 0 }}>{"ㅤ"}</Box>
            </>
          )}
        </div>
      </>
    );
  };

  function RenderDate(props){
    const date = new Date(props.value);
    return date.toISOString().slice(0, 10);
  }

  function RenderStatus(props) {
    return <StatusChip type={'lecture'} value={props.value} />;
  }

  function RenderLectureName(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconBrandPython size={36} />
        </Avatar>
        {props.value}
      </div>
    );
  }

  function RenderTeacher(props) {
    return (
      <div
        style={{
          width: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            border: `1px solid`,
            borderColor: grey[600],
            backgroundColor: common.white,
            color: grey[600],
            fontSize: "40px",
          }}
        >
          <Person fontSize="inherit" />
        </Avatar>
        {props.value}
      </div>
    );
  }

  

  const MenuButton = forwardRef((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [minianchorEl, setminiAnchorEl] = React.useState(null);
    const [courseAnchorEl, setCourseAnchorEl] = React.useState(null);
    const [changeAnchorEl, setChangeAnchorEl] = React.useState(null);
    const [lectureAnchorEl, setLectureAnchorEl] = React.useState(null);
    const [attend, setAttend] = React.useState("attend");
    const courseOpen = Boolean(courseAnchorEl);
    const changeOpen = Boolean(changeAnchorEl);
    const lectureOpen = Boolean(lectureAnchorEl);
    const handleCourse = (e) => {
      setCourseAnchorEl(e.currentTarget);
    };
    const handleCourseClose = () => {
      setCourseAnchorEl(null);
    };
    const handleChangeClassroom = (e) => {
      setChangeAnchorEl(e.currentTarget);
    };
    const handleChangeClose = () => {
      setChangeAnchorEl(null);
    };

    const handleLectures=(e)=>{
        setLectureAnchorEl(e.currentTarget);
    }
    const handleLectureClose=()=>{
        setLectureAnchorEl(null);
    }

    const chipLabel = (() => {
      switch (attend) {
        case "attend":
          return "출석";
        case "late":
          return "지각";
        case "leaveEarly":
          return "조퇴";
        case "online":
          return "온라인";
        case "absent":
          return "결석";
        case "wait":
          return "대기";
        default:
          return "출석";
      }
    })();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget.parentElement);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <div>
        <IconButton
          aria-label="more"
          size="small"
          sx={{ width: "40px", padding: 0 }}
          id="hamburger-button"
          aria-controls={open ? "hamburger-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          disableRipple={true}
          onClick={handleClick}
        >
          <IconDotsVertical color={grey[800]} />
        </IconButton>
        <Menu
          id="hamburger-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
        >
          <MenuItem onClick={handleLectures}>단원 상세정보</MenuItem>
          <Divider />
          <MenuItem onClick={handleCourse}>스킵으로 변경</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>새 단원 추가</MenuItem>
        </Menu>
        <CourseAssign
          open={courseOpen}
          onClose={handleCourseClose}
          students={[props.row]}
          programs={programs}
        />
        <ChangeClassroom
          open={changeOpen}
          onClose={handleChangeClose}
          students={[props.row]}
        />
        <LecutreDetail
          open={lectureOpen}
          onClose={handleLectureClose}
          {...props.row}
        />
      </div>
    );
  });

  function RenderStudentMenu(props) {
    return <MenuButton {...props} />;
  }

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // Hide columns status and traderName, the other columns will remain visible
        id: false,
      },
    },
    pagination: {
      paginationModel: { pageSize: 25, page: 0 },
    },
  };

  function handleChange(item, e) {
    const selectedIds = new Set(item);
    const selectedRows = lecturedata.filter((row) => selectedIds.has(row.id));
    setSelectedRow(selectedRows);
  }

  const columns = [
    {
      field: "id",
      hide: true,
      disableColumnMenu: true,
    },
    {
      field: "order",
      headerName: "순서",
      width: 60,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "단원제목",
      renderCell: RenderLectureName,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "teacher",
      headerName: "담임강사",
      renderCell: RenderTeacher,
      width: 150,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "classroom",
      headerName: "교실",
      width: 120,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "lectureReview",
      headerName: "수업후기",
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "evaluate",
      headerName: "평가",
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "lecturedate",
      headerName: "수업날짜",
      renderCell:RenderDate,
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "상태",
      width: 70,
      renderCell: RenderStatus,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "menu",
      headerName: "",
      width: 70,
      renderCell: RenderStudentMenu,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const studenttable_data = {
    rows: lecturedata,
    columns: columns,
    initialState: initialState,
    disableRowSelectionOnClick: true,
    checkboxSelection: true,
    onRowSelectionModelChange: handleChange,
    slots: {
      BaseCheckbox: React.forwardRef((props, _) => {
        return <Checkbox {...props} />;
      }),
      toolbar: SearchSection,
    },
    rowHeight: 90,
  };

  return (
    <MainCard>
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <DataGridPro
           localeText={{
            noRowsLabel: '데이터가 없습니다',
          }}
            // experimentalFeatures={{ lazyLoading: true }}
            sx={{
              fontSize: "1rem",
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                px: "1px !important",
              },
            }}
            autoHeight
            {...studenttable_data}
            pagination
            apiRef={apiRef}
          />
        </div>
      </div>
    </MainCard>
  );
}

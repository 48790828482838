import dashboard from './dashboard';
import info from './info'
import setting from './setting';
import statistics from './statistics';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, info, setting, statistics]
};

export default menuItems;

import {useRoutes} from 'react-router-dom';
import MainRoutes from './MainRoutes'
import DetailRoutes from './DetailRoutes'
import LoginRoutes from './LoginRoutes'
import { useSelector } from "react-redux";


export default function ThemeRoutes() {//MPA 추가 시 Router 분리
    let route = null;
    const token = useSelector((state) => state.auth.token);
    console.log(token);
    if (token) {
      route = MainRoutes
    } else {
      route = LoginRoutes
    }
    return useRoutes([route]);
  }
  
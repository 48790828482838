/* eslint-disable array-callback-return */
// Data CRUD for Classrooms using fetch

const API_URL = process.env.REACT_APP_API_URL;

export async function GetClassrooms(weekday) {
    if (weekday === undefined) weekday = ""
    const res = await fetch(API_URL + "/classrooms?weekday=" + weekday);
    var result = await res.json();
    if (result.length === undefined) {
        const temp = [];
        temp.push(result);
        result = temp;
    }
    result.map((classroom, _) => {
        classroom.id = classroom.ID;
    });
    return result;
}

export async function GetLocations(){
    const res = await fetch(API_URL + "/classrooms/location");
    var result = await res.json();
    if (result.length === undefined) {
        const temp = [];
        temp.push(result);
        result = temp;
    }

    return result;
}

export async function GetLocation(id){
    
    const res = await fetch(API_URL + "/classrooms/location/" + id);
    var result = await res.json();
    return result;
}

export async function GetClassroomSchedule(id) {
    if (id === undefined) return [];
    const res = await fetch(API_URL + "/classrooms/" + id + "/schedule");
    var result = await res.json();
    if (result===null) result = [];
    else if (result.length === undefined) {
        const temp = [];
        temp.push(result);
        result = temp;
    }
    result.map((schedule, _) => {
        schedule.id = schedule.ID;
    });

    return result;
}

export async function CreateClassroomSchedule(classroomSchedule) {
    const res = await fetch(API_URL + "/classrooms/schedule", {
        method: "POST",
        body: JSON.stringify(classroomSchedule),
    });
    const result = await res.json();
    return result;
}

export async function GetClassroom(id) {
    const res = await fetch(API_URL + "/classrooms" + id);
    const result = await res.json();
    return result;
}

export async function CreateClassroom(classroom) {
    const res = await fetch(API_URL + "/classrooms/", {
        method: "POST",
        body: JSON.stringify(classroom),
    });
    const result = await res.json();
    return result;
}

export async function UpdateClassroom(classroom) {
    const res = await fetch(API_URL + "/classrooms/"+classroom.ID, {
        method: "PUT",
        body: JSON.stringify(classroom),
    });
    const result = await res.json();
    return result;
}

export async function DeleteClassroom(id) {
    const res = await fetch(API_URL + "/classrooms/" + id, {
        method: "DELETE",
    });
    const result = await res.json();
    return result;
}

export async function GetClassroomStudents(classroom){
    const res = await fetch(API_URL + "/classrooms/" + classroom.ID + "/students");
    var result = await res.json();
    if (result.length === undefined) {
        const temp = [];
        temp.push(result);
        result = temp;
    }
    result.map((studentClassroom, _) => {
        studentClassroom.id = studentClassroom.ID;
        return studentClassroom;
    });
    return result;
}

export async function AssignClassroom(studentprogram, classroom){
    studentprogram.ClassroomId = classroom.ID
    const res = await fetch(API_URL + "/classrooms/assign", {
        method: "POST",
        body: JSON.stringify(studentprogram),
    });
    if (res.status === 200) return true;
}

export async function ThisweekSchedules(weekday){
    if (weekday === undefined) weekday = 'all'
    const res = await fetch(API_URL + "/classrooms/schedule/thisweek/" + weekday).catch((error)=>{
	    console.log(error);
    });
    var result = await res.json();
    if (result===null) result = [];
    result.map((schedule, _) => {
        schedule.id = schedule.ID;
    });
    return result;
}

export async function GetScheduleStudents(scheduleId){
    const res = await fetch(API_URL + "/classrooms/schedule/" + scheduleId + "/students");
    var result = await res.json();
    if (result.length === undefined) {
        const temp = [];
        temp.push(result);
        result = temp;
    }
    result.map((studentClassroom, _) => {
        studentClassroom.id = studentClassroom.ID;
        return studentClassroom;
    });
    return result;
}

export async function SetScheduleAttendance(stduentSchdule, attend){
    const res = await fetch(API_URL + "/classrooms/schedule/students/attendance", {
        method: "PUT",
        body: JSON.stringify({
            ScheduleId : stduentSchdule.ClassroomScheduleId,
            UserId: stduentSchdule.UserId,
            Attend: attend
        }),
    });
    if (res.status === 200) return true;
}

export async function SetScheduleAttendanceMemo(stduentSchdule, memo){
    const res = await fetch(API_URL + "/classrooms/schedule/students/memo", {
        method: "PUT",
        body: JSON.stringify({
            ScheduleId : stduentSchdule.ClassroomScheduleId,
            UserId: stduentSchdule.UserId,
            Memo: memo
        }),
    });
    if (res.status === 200) return true;
}

export async function GetThisMonthStudent(weekday){
    if (weekday === undefined) weekday = 'all'
    const res = await fetch(API_URL + "/classrooms/schedule/thismonth/students?weekday=" + weekday);
    var result = await res.json();
    if (result===null) result = [];
    result.map((schedule, _) => {
        schedule.id = schedule.UserId;
    });
    return result;
}

export async function GetThisMonthStudentSchedules(userId){
    const res = await fetch(API_URL + "/classrooms/schedule/thismonth/" + userId);
    var result = await res.json();
    if (result===null) result = [];
    result.map((schedule, _) => {
        schedule.id = schedule.ID;
    });
    return result;
}

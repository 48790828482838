import StatusChip from "ui-component/StatusChip";
import AttendenceChip from "ui-component/AttendenceChip";
import * as React from "react";
import { IconBrandPython, IconPlus, IconUser } from "@tabler/icons";
import {
  Avatar,
  Button,
  Divider,
  Box,
  ButtonBase,
  Card,
  InputAdornment,
  OutlinedInput,
  Typography,
  Popper,
  Input,
  Checkbox,
} from "@mui/material";
import { Delete, Person } from "@mui/icons-material";
import { red, grey, common } from "@mui/material/colors";
// import ClassProgram from "ui-component/buttons/ClassProgram";
import IconButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconDotsVertical } from "@tabler/icons";
import { forwardRef } from "react";
import {
  GridToolbarQuickFilter,
  DataGridPro,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";
import CRUDProgram from "ui-component/modals/CRUDProgram";

// material-ui
import { useTheme, styled } from "@mui/material/styles";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { alpha, shouldForwardProp } from "@mui/system";
import { useRef } from "react";
import CourseAssign from "ui-component/modals/CourseAssign";
import ChangeClassroom from "ui-component/modals/ChangeClassroom";
import { DeleteProgram, GetPrograms } from "data/ProgramDataAPI";
import { useEffect } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router";
import DeleteElement from "ui-component/modals/DeleteElement";

export default function I_ProgramTable(props) {
  const [programdata, setProgramdata] = useState([]);
  useEffect(() => {
    GetPrograms().then((Programs) => {
      setProgramdata(Programs);
    });
  }, []);
  const ref = useRef();
  const CustomQuickFilter = styled(GridToolbarQuickFilter, {
    shouldForwardProp,
  })(({ theme }) => ({
    width: "100%",
    fontSize: "1.0rem",
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
      height: "29px",
      fontSize: "1.0rem",
      "&::placeholder": {
        fontSize: "1.0rem",
        color: grey[800],
      },
    },
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background: "#fff",
    },
  }));

  const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
    ({ theme }) => ({
      ...theme.typography.commonAvatar,
      ...theme.typography.mediumAvatar,
      background: theme.palette.grey[100],
      color: theme.palette.grey[500],
      "&:hover": {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light,
      },
    })
  );

  const handleXClick = (e, index, id) => {
    const list = props.selectedRow.filter((_, i) => i !== index);
    props.setSelectedRow(list);
    props.apiRef.current.selectRow(id, false);
  };

  function SelectedPrograms(props) {
    return (
      <Box
      key={`classroom${props.index}`}
      sx={{
        borderRadius: "2px",
        backgroundColor: "#e3e6eb",
        padding: "12px",
        minWidth: "100px",
        height: "45px",
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
      >
      {props.ProgramTitle}

      <ButtonBase
        id={`xButton${props.index}`}
        onClick={(e) => handleXClick(e, props.index, props.id)}
        sx={{
        position: "absolute",
        top: "2px",
        right: "2px",
        width: 14,
        height: 14,
        zIndex: 1000,
        }}
      >
        <IconX style={{ zIndex: 800 }} />
      </ButtonBase>
      </Box>
    );
  }

  const SearchSection = () => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [courseAnchorEl, setCourseAnchorEl] = useState(null);
    const [changeAnchorEl, setChangeAnchorEl] = useState(null);
    const [createMenuAnchorEl, setCreateMenuAnchorEl] = useState(null);
    const [createAnchorEl, setCreateAnchorEl] = useState(null);

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCreateMenu = (e) => {
      setCreateMenuAnchorEl(e.currentTarget);
    };

    const handleCourse = (e) => {
      setCourseAnchorEl(e.currentTarget);
    };
    const handleCourseClose = () => {
      setCourseAnchorEl(null);
    };

    const handleChangeClassroom = (e) => {
      setChangeAnchorEl(e.currentTarget);
    };

    const handleChangeClose = () => {
      setChangeAnchorEl(null);
    };

    const handleCreate = (e) => {
      setCreateAnchorEl(e.currentTarget);
    };

    const handleCreateClose = () => {
      setCreateAnchorEl(null);
    };

    const handleSave = () => {
      setAnchorEl(null);
      GetPrograms().then((Programs) => {
        setProgramdata(Programs);
      });
    };

    const open = Boolean(anchorEl);
    const courseOpen = Boolean(courseAnchorEl);
    const changeOpen = Boolean(changeAnchorEl);
    const createOpen = Boolean(createAnchorEl);

    return (
      <>
        <div style={{ display: "flex", gap: 10 }}>
          <Box sx={{ display: { xs: "none", md: "block" }, flex: 1 }}>
            <CustomQuickFilter
              variant="outlined"
              placeholder="프로그램 검색"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch
                      stroke={1.5}
                      size="1.5rem"
                      color={theme.palette.grey[500]}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ heigth: "60px" }}>
                    <ButtonBase sx={{ borderRadius: "12px" }}>
                      <HeaderAvatarStyle variant="rounded">
                        <IconAdjustmentsHorizontal stroke={1.5} size="1.0rem" />
                      </HeaderAvatarStyle>
                    </ButtonBase>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ButtonBase
            onClick={handleClick}
            sx={{ height: "60px", width: "100px", backgroundColor: "#f2f5fa" }}
          >
            <Typography fontSize={"1rem"}>Actions</Typography>
            <ArrowDropDown sx={{ marginLeft: "10px" }} />
          </ButtonBase>
          <Menu
            id="hamburger-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            slotProps={{
              style: {
                width: "150px", // 원하는 너비로 설정,
                fontSize: "1.7rem",
              },
            }}
            sx={{
              "& .MuiMenuItem-root": {
                fontSize: "1rem",
              },
            }}
            MenuListProps={{
              "aria-labelledby": "hamburger-button",
            }}
          >
            <MenuItem
              onClick={handleCourse}
              disabled={props.selectedRow.length === 0}
            >
              프로그램 수강등록
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={handleChangeClassroom}
              disabled={props.selectedRow.length === 0}
            >
              교실배정 변경
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={handleClose}
              disabled={props.selectedRow.length === 0}
            >
              메시지 발송
            </MenuItem>
          </Menu>
          <ChangeClassroom
            open={changeOpen}
            onClose={handleChangeClose}
            programs={[props.selectedRow]}
          />
          <ButtonBase
            sx={{
              height: "60px",
              width: "60px",
              backgroundColor: "#3a63de",
              color: common.white,
            }}
            onClick={handleCreate}
          >
            <IconPlus />
          </ButtonBase>
        </div>
        {/* Selected Programs */}
        <div
          style={{
            overflowX: "auto",
            backgroundColor: alpha("#009eb2", 0.14),
            display: "flex",
          }}
        >
          {props.selectedRow.length > 0 && (
            <>
              <Box
                sx={{
                  borderLeft: `5px solid #009eb2`,

                  height: 60,
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <div></div>
                {props.selectedRow.map((row, index) => {
                  return (
                    <div key={`program${index}`}>
                      <SelectedPrograms {...row} index={index} />
                    </div>
                  );
                })}
              </Box>
              <Box sx={{ width: 0 }}>{"ㅤ"}</Box>
            </>
          )}
        </div>
        <CRUDProgram
          {...props}
          open={createOpen}
          onClose={handleCreateClose}
          handleSave={handleSave}
        />
      </>
    );
  };

  function RenderStatus(props) {
    return <StatusChip type={"program"} value={props.value} />;
  }

  function RenderProgramName(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          gap: 10,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconBrandPython size={36} />
        </Avatar>
        {props.value}
      </div>
    );
  }

  function RenderAdmin(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconUser size={36} />
        </Avatar>
        {props.value}
      </div>
    );
  }

  function RenderProgramGrade(props) {
    return props.value;
  }

  function RenderHasMessage(props) {
    if (props.value === "true")
      return (
        <Avatar sx={{ bgcolor: "#fd726a", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
    else
      return (
        <Avatar sx={{ bgcolor: "#3a63de", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
  }

  const MenuButton = forwardRef((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [minianchorEl, setminiAnchorEl] = React.useState(null);
    const [infoAnchorEl, setInfoAnchorEl] = React.useState(null);
    const [courseAnchorEl, setCourseAnchorEl] = React.useState(null);
    const [changeAnchorEl, setChangeAnchorEl] = React.useState(null);
    const [attend, setAttend] = React.useState("attend");
    const [deleteAnchorEl, setDeleteAnchorEl] = React.useState(null);
    const courseOpen = Boolean(courseAnchorEl);
    const changeOpen = Boolean(changeAnchorEl);
    const infoOpen = Boolean(infoAnchorEl);
    const deleteOpen = Boolean(deleteAnchorEl);
    const location = useLocation();
    const navigate = useNavigate();

    const handleInfo = (e) => {
      setInfoAnchorEl(e.currentTarget);
      setAnchorEl(null);
    };
    const handleInfoClose = () => {
      setInfoAnchorEl(null);
    };
    const handleInfoSave = () => {
      setInfoAnchorEl(null);
      GetPrograms().then((Programs) => {
        setProgramdata(Programs);
      });
    };
    const handleLecture = () => {
      navigate(location.pathname + "/lectures/" + props.row.id);
    };
    const handleCourseClose = () => {
      setCourseAnchorEl(null);
    };
    const handleChangeClassroom = (e) => {
      setChangeAnchorEl(e.currentTarget);
    };
    const handleChangeClose = () => {
      setChangeAnchorEl(null);
    };

    const handleSave = () => {
      setInfoAnchorEl(null);
      GetPrograms().then((Programs) => {
        setProgramdata(Programs);
      });
    };

    const handleDelete = (e) => {
      setDeleteAnchorEl(e.currentTarget);
      setAnchorEl(null);
    };

    const handleDeleteClose = () => {
      setDeleteAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget.parentElement);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          aria-label="more"
          size="small"
          sx={{ width: "40px", padding: 0 }}
          id="hamburger-button"
          aria-controls={open ? "hamburger-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          disableRipple={true}
          onClick={handleClick}
        >
          <IconDotsVertical color={grey[800]} />
        </IconButton>
        <Menu
          id="hamburger-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
        >
          <MenuItem onClick={handleInfo}>프로그램 정보</MenuItem>
          <Divider />
          <MenuItem onClick={handleLecture}>단원정보 화면</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>수강중도 변경</MenuItem>
          <Divider />
          <MenuItem onClick={handleChangeClassroom}>교실배정 변경</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>메시지 발송</MenuItem>
          <Divider />
          <MenuItem onClick={handleDelete}>프로그램 삭제</MenuItem>
        </Menu>
        <ChangeClassroom
          open={changeOpen}
          onClose={handleChangeClose}
          programs={[props.row]}
        />
        <CRUDProgram
          open={infoOpen}
          onClose={handleInfoClose}
          handleSave={handleSave}
          {...props.row}
        />
        <DeleteElement
          open={deleteOpen}
          onClose={handleDeleteClose}
          onDelete={async () => {
            await DeleteProgram(props.row.id);
            handleDeleteClose();
            GetPrograms().then((Programs) => {
              setProgramdata(Programs);
            });
          }}
        />
        {/* <CRUDProgram open={infoOpen} onClose={handleInfoClose} handleSave={handleInfoSave} {...props.row} /> */}
      </div>
    );
  });

  function RenderProgramMenu(props) {
    return <MenuButton {...props} />;
  }

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // Hide columns status and traderName, the other columns will remain visible
        UserId: false,
      },
    },
    pagination: {
      paginationModel: { pageSize: 25, page: 0 },
    },
  };

  function handleChange(item, e) {
    const selectedIds = new Set(item);
    const selectedRows = programdata.filter((row) => selectedIds.has(row.id));
    props.setSelectedRow(selectedRows);
  }

  const columns = [
    {
      field: "UserId",
    },
    {
      field: "ProgramTitle",
      headerName: "프로그램",
      renderCell: RenderProgramName,
      minWidth: 240,
      headerAlign: "center",
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "Subject",
      headerName: "과목",
      width: 70,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "space",
      headerName: "",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "AdminDisplayName",
      headerName: "관리자",
      headerAlign: "center",
      renderCell: RenderAdmin,
      minWidth: 120,
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "lecture_count",
      headerName: "단원수",
      headerAlign: "center",
      width: 80,
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "CreatedAt",
      headerName: "등록날짜",
      renderCell: (props) => {
        return props.value.slice(0, 10);
      },
      width: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "Status",
      headerName: "상태",
      width: 70,
      renderCell: RenderStatus,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "menu",
      headerName: "",
      width: 70,
      renderCell: RenderProgramMenu,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const programtable_data = {
    rows: programdata,
    columns: columns,
    initialState: initialState,
    disableRowSelectionOnClick: true,
    checkboxSelection: true,
    onRowSelectionModelChange: handleChange,
    slots: {
      BaseCheckbox: React.forwardRef((props, _) => {
        return <Checkbox {...props} />;
      }),
      toolbar: SearchSection,
    },
    rowHeight: 90,
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <DataGridPro
          localeText={{
            noRowsLabel: "데이터가 없습니다",
          }}
          // experimentalFeatures={{ lazyLoading: true }}
          sx={{
            fontSize: "1rem",
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              px: "1px !important",
            },
          }}
          autoHeight
          {...programtable_data}
          pagination
          apiRef={props.apiRef}
        />
      </div>
    </div>
  );
}

import * as React from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import MainCard from "ui-component/cards/MainCard";
import {  Tabs, Tab, Box } from "@mui/material";
import { useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";

export default function InformationTab({ Title, TabList }) {
  const today = new Date();
  const [value, setValue] = useState(TabList[0].label==='월'?`${(today.getDay()+6)%7}`:'0');//월요일이 0이 되도록 설정 1->0, 2->1, 3->2, 4->3, 5->4, 6->5, 0->6
  const [selectedRow,setSelectedRow] = useState([]);
  const apiRef= useGridApiRef();
  const tabDef = {
    '월': 'MON',
    '화': 'TUE',
    '수': 'WED',
    '목': 'THU',
    '금': 'FRI',
    '토': 'SAT',
    '일': 'SUN',
    '전체': ''
  }



  const handleChange = (event, newValue) => {
    setSelectedRow([]);
    setValue(newValue);
  };
  return (
    <MainCard>
      <TabContext value={value}>
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Tab Grids"
              >
                {TabList.map((tab, index) => (
                  <Tab
                    label={tab.label}
                    value={String(index)}
                    key={'Tab'+String(index)}
                    sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                  />
                ))}
              </Tabs>
            </Box>
            {TabList.map((tab, index) => {
              return (
              <TabPanel value={String(index)} key={'Table'+String(index)}>
                <tab.table weekday={tabDef[tab.label]} apiRef={apiRef} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
              </TabPanel>
            )})}
          </div>
        </div>
      </TabContext>
    </MainCard>
  );
}

import LoginLayout from "layout/LoginLayout";
import { Navigate } from "react-router";
import LoginView from "views/LoginView";
const LoginRoutes = {
    path:"/",
    element:(
        <LoginLayout/>
    ),
    children:[
        {
            path:'',
            element:(
                <Navigate to="/login"/>   
            )
        },
        {
            path:'login',
            element:(
                <LoginView/>
            )
        },{
            path:'*',
            element:(
                <Navigate to="/login"/>
            )
        }
    ]

};
export default LoginRoutes;
export async function GetPrograms() {
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/programs");
    const result = await res.json();
    result.map((program, _) => {
        program.id = program.ID;
        return program; // Add return statement
    });
    return result;
    }

export async function GetProgram(id) {
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/programs/" + id);
    const result = await res.json();
    return result;
    }

export async function CreateProgram(program) {
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/programs/", {
        method: "POST",
        body: JSON.stringify(program),
    });
    const result = await res.json();
    return result;
    }

export async function UpdateProgram(program) {
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/programs/"+program.ID, {
        method: "PUT",
        body: JSON.stringify(program),
    });
    const result = await res.json();
    return result;
    }

export async function DeleteProgram(id) {
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/programs/" + id, {
        method: "DELETE",
    });
    const result = await res.json();
    return result;
    }

export async function AssignProgram(student, program) {
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/programs/assign", {
        method: "POST",
        body: JSON.stringify({
            UserId: student.id,
            CenterId : student.CenterId,
            ProgramId : program.ID,
            ProgramExams : program.ProgramExams,
        }),
    });
    const result = await res.json();
    if(result.error){
        alert(result.error);
    }
    return result;
    }

export async function GetStudentProgram(program){
    const API_URL = process.env.REACT_APP_API_URL;
    let request = API_URL + "/programs/students/"
    if(program) request += program.ID 
    else request += "0"
    const res = await fetch(request);
    const result = await res.json();
    result.map((student_program, _) => {
        student_program.id = student_program.ID;
        return student_program; // Add return statement
    });
    return result;
}

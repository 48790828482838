import {
  Card,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
  Typography,
} from "@mui/material";
import {
  blue,
  common,
  green,
  grey,
  purple,
  red,
  yellow,
} from "@mui/material/colors";
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  CreateHoliday,
  DeleteHoliday,
} from "data/CalandarDataAPI";
import React from "react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

function CSCalendar(props) {
  const reload = props.reload; 
  const selectedDate = props.selected;
  const addMonth = props.addCurrentMonth;
  const subMonth = props.subCurrentMonth;
  const [date, setDate] = React.useState(dayjs(`${props.year}-${props.month}-01`));
  const handleDayClick = props.handleDayClick;

  React.useEffect(() => {    
    setDate(dayjs(`${props.year}-${props.month}-01`));
  }, [props.year]);
  React.useEffect(() => {
    setDate(dayjs(`${props.year}-${props.month}-01`));
  }, [props.month]);
    

  function CustomHeader(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingX: 10,
        }}
      >
        {selectedDate  && (
          <IconButton onClick={subMonth}>
            <ArrowBackIos />
          </IconButton>
        )}
        <Typography
          variant="h3"
          sx={{ textAlign: "right", flex: 1 }}
          paddingY={2}
        >
          {props.currentMonth.$M + 1}
        </Typography>
        <Typography sx={{ marginLeft: "auto", textAlign: "center", flex: 1 }}>
          {props.currentMonth.$y}
        </Typography>
        {selectedDate && (
          <IconButton onClick={addMonth}>
            <ArrowForwardIos />
          </IconButton>
        )}
      </div>
    );
  }
  function CustomDayFormat(day) {
    const dayKOR = (() => {
      switch (day) {
        case "Su":
          return "일";
        case "Mo":
          return "월";
        case "Tu":
          return "화";
        case "We":
          return "수";
        case "Th":
          return "목";
        case "Fr":
          return "금";
        case "Sa":
          return "토";
      }
    })();
    return <Typography variant="h4">{dayKOR}</Typography>;
  }

  function isHoliday(day) {
    if (
      day.$W < 2 ||
      props.holidays.some((holiday) => dayjs(holiday.date).isSame(day, "day"))
    )
      return true;
    else return false;
  }

  var types = [[], [], []];
  var days = [0, 0, 0, 0, 0];

  function generateDayType() {
    var date = null;
    for (var i = 0; i < 365; i++) {
      date = dayjs(`${props.year}-01-01`).add(i, "day");
      //days에 해당 요일 수업 count한 뒤, 4개씩 끊어서 3가지 type부여(테두리 색상)
      if (date.$W > 1 && !isHoliday(date)) {
        types[Math.floor((days[date.$W - 2] / 4) % 3)].push(date);
        days[date.$W - 2] = days[date.$W - 2] + 1;
      }
    }
  }
  generateDayType();

  function RenderDay(props) {
    const isSelected =
      selectedDate
        ? selectedDate.some((date) => dayjs(date.TheDate).isSame(props.day))
        : false;
    const bgColor = isHoliday(props.day)
      ? red[200]
      : isSelected
      ? blue[800]
      : common.white;
    const [dayMenuAnchrEl, setDayMenuAnchrEl] = React.useState(null);

    const dayMenuOpen = Boolean(dayMenuAnchrEl);

    const handleDayMenuClick = (event) => {
      setDayMenuAnchrEl(event.currentTarget);
    };

    const handleDayMenuClose = () => {
      setDayMenuAnchrEl(null);
    };

    const handleSetHoliday = () => {
      CreateHoliday(props.day.$d).then(() => {
        reload().then(()=>{
          handleDayMenuClose();
        })
      });
    };

    const handleUnsetHoliday = () => {
      DeleteHoliday(props.day.format("YYYY-MM-DD")).then(() => {
        reload().then(()=>{
          handleDayMenuClose();
        })
      });
    };

    const dayColor = (() => {
      if (props["aria-colindex"] === 1) return red[800];
      else if (isSelected) return common.white;
      else if (props.day.isSame(dayjs(), "day")) return blue[800];
      else return common.black;
    })();
    const borderColor = (() => {
      if (types[0].some((day) => day.isSame(props.day, "day")))
        return purple[500];
      else if (types[1].some((day) => day.isSame(props.day, "day")))
        return green[700];
      else if (types[2].some((day) => day.isSame(props.day, "day")))
        return yellow[500];
    })();

    const border = (() => {
      return isHoliday(props.day) ? "none" : `2px solid ${borderColor}`;
    })();

    return (
      <div>
        <PickersDay
          id={props.id + "day"}
          {...props}
          style={{
            backgroundColor: bgColor,
            color: dayColor,
            fontSize: "1rem",
            fontWeight: "bold",
            width: "30px",
            height: "30px",
            margin: "3px 5px",
            border: border,
          }}
          onClick={() => {
            if (selectedDate) {
              handleDayClick(props.day);
            }
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            handleDayMenuClick(e);
          }}
        />
        <Menu
          id="day-menu"
          anchorEl={dayMenuAnchrEl}
          open={dayMenuOpen}
          onClose={handleDayMenuClose}
        >
          <MenuItem onClick={handleSetHoliday}>휴일 설정</MenuItem>
          <MenuItem onClick={handleUnsetHoliday}>휴일 해제</MenuItem>
        </Menu>
      </div>
    );
  }
  return (
    <Card
      sx={{
        border: `1px solid${grey[300]}`,
        width: 320,
        borderRadius: "0px",
        alignItems: "center",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          value={date}
          onChange={(newDate) => {
            console.log(newDate);
            setDate(newDate);
          }}
          readOnly
          slots={{ calendarHeader: CustomHeader, day: RenderDay }}
          dayOfWeekFormatter={(day) => CustomDayFormat(day)}
        />
      </LocalizationProvider>
    </Card>
  );
}

export default CSCalendar;

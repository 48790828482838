import React from "react";
import { Chip, ThemeProvider, alpha, createTheme } from "@mui/material";
import { common, green, grey, yellow } from "@mui/material/colors";

//상태 표시를 위한 Chip 컴포넌트
// label : 제목,  color : palette 색상(코드가 아닌 테마 내의 색상 이름 ex- 'success', 'info' )

const StatusLabelDef = {
  student: {
    on: "재원",
    off: "졸업",
    sleeping: "휴원",
    waiting: "대기",
    deleted: "퇴원",
  },
  program: {
    on: "운영중",
    off: "종료",
    waiting: "대기",
  },
  student_program: {
    on: "수강중",
    complete: "완강",
    waiting: "대기",
    abort: "중도취소",
  },
  classroom: {
    on: "운영중",
    off: "종료",
    waiting: "대기",
  },
  message_type: {
    kakao: "카톡",
    sms: "문자",
    push: "앱푸시",
  },
  attendance: {
    attend: "출석",
    absent: "결석",
    late: "지각",
    leave: "조퇴",
    online: "온라인",
    wait: "대기",
    waiting: "대기",
  },
  lecture:{
    on:"ON",
    off:"OFF"
  },
  lecture_type:{
    normal:"정규",
    makeup:"보강",
    supplement:"보충",
    skip:"스킵",
    abort:"취소"
  },
  triallecture:{
    applywaiting:"신청후대기",
    applycancel:"신청후취소",
    applycomplete:"체험완료",
  }
};


////////////////////////////////////////////////
//상태 표시를 위한 Chip 컴포넌트
// type : 상태 타입, value : 상태 값, sx : 추가 스타일
//
// triallecture 상태 타입은 체험수업진행 상태를 표시하기 위한 상태 타입
//    => 체험수업상태를 사용할지? 체험수업반(클래스)를 사용할지?, 일단 클래스로 분류하는 것을 선진행. 
///////////////////////////////////////////////
function StatusChip({ type, value, sx }) {
  const theme = createTheme({
    palette: {
      //학생 상태 Palette
      student_on: {
        main: alpha("#34aa56", 0.32),
        contrastText: "#34aa56",
      },
      student_sleeping: {
        main: alpha("#ffca61", 0.32),
        contrastText: "#ffca61",
      },
      student_waiting: {
        main: alpha("#3a63de", 0.32),
        contrastText: "#20a144",
      },
      student_off: {
        main: alpha("#9956eb", 0.32),
        contrastText: "#9956eb",
      },
      student_deleted: {
        main: alpha("#fd726a", 0.32),
        contrastText: "#fd726a",
      },
      //체험수업진행 상태 Palette
      triallecture_applywaiting: {
        main: alpha("#ffca61", 0.32),
        contrastText: "#ffca61",
      },
      triallecture_applycancel: {
        main: alpha("#fd726a", 0.32),
        contrastText: "#fd726a",
      },
      triallecture_applycomplete: {
        main: alpha("#44cb7f", 0.32),
        contrastText: "#44cb7f",
      },
      //프로그램 운영 상태 Palette
      program_on: {
        main: "#44cb7f",
        contrastText: common.white,
      },
      program_off: {
        main: "#818386",
        contrastText: common.white,
      },
      program_waiting: {
        main: "#FFCA61",
        contrastText: common.white,
      },
      //학생프로그램 상태 Palette
      student_program_on: {
        main: "#44cb7f",
        contrastText: common.white,
      },
      student_program_complete: {
        main: "#3a63de",
        contrastText: common.white,
      },
      student_program_waiting: {
        main: "#ffca61",
        contrastText: common.white,
      },
      student_program_abort: {
        main: "#fd726a",
        contrastText: common.white,
      },
      //교실 상태 Palette
      classroom_on: {
        main: "#44cb7f",
        contrastText: common.white,
      },
      classroom_off: {
        main: "#818386",
        contrastText: common.white,
      },
      classroom_waiting: {
        main: "#FFCA61",
        contrastText: common.white,
      },
      //메시지 타입 Palette
      message_type_kakao: {
        main: alpha("#ffca61", 0.68),
        contrastText: common.white,
      },
      message_type_sms: {
        main: alpha("#44cb7f", 0.68),
        contrastText: common.white,
      },
      message_type_push: {
        main: alpha("#fd726a", 0.68),
        contrastText: common.white,
      },
      //출석 상태 Palette
      attendance_attend: {
        main: "#859eea",
        contrastText: common.white,
      },
      attendance_absent: {
        main: "#fea7a2",
        contrastText: common.white,
      },
      attendance_late: {
        main: "#ffde9d",
        contrastText: common.white,
      },
      attendance_leave: {
        main: "#8bdfaf",
        contrastText: common.white,
      },
      attendance_online: {
        main: "#c096f2",
        contrastText: common.white,
      },
      attendance_wait: {
        main: "#b1b2b4",
        contrastText: common.white,
      },
      attendance_waiting: {
        main: "#b1b2b4",
        contrastText: common.white,
      },
      lecture_on: {
        main: "#44cb7f",
        contrastText: common.white,
      },
      lecture_off: {
        main: "#818386",
        contrastText: common.white,
      },
      reservation: {
        main: "#9956eb",
        contrastText: common.white,
      },
      lecture_type_normal: {
        main: "#44cb7f",
        contrastText: common.white,
      },
      lecture_type_makeup: {
        main: "#c096f2",
        contrastText: common.white,
      },
      lecture_type_supplement: {
        main: "#ffca61",
        contrastText: common.white,
      },
      lecture_type_skip: {
        main: "#b1b2b4",
        contrastText: common.white,
      },
      lecture_type_abort: {
        main: "#fd726a",
        contrastText: common.white,
      },
    },
  });
  var fontSize = "1rem";
  const label = StatusLabelDef[type][value];
  const color = type + "_" + value;
  if (label.length === 4) fontSize = "1rem";
  var width = "60px";
  var height = "25px";
  if (sx) {
    width = sx.width;
    height = sx.height;
  }
  return (
    <ThemeProvider theme={theme}>
      <Chip
        label={label}
        color={color}
        {...sx}
        sx={{
          width: width,
          height: height,
          borderRadius: "0px",
          fontSize: { fontSize },
          "& .MuiChip-label": {
            padding: "0px", // 라벨 내의 padding을 0으로 설정
          },
        }}
      />
    </ThemeProvider>
  );
}

export default StatusChip;

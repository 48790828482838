import StatusChip from "ui-component/StatusChip";
import AttendenceChip from "ui-component/AttendenceChip";
import * as React from "react";
import { IconChalkboard, IconPlus, IconUser } from "@tabler/icons";
import {
  Avatar,
  Button,
  Divider,
  Box,
  ButtonBase,
  Card,
  InputAdornment,
  OutlinedInput,
  Typography,
  Popper,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import { red, grey, common } from "@mui/material/colors";
import ClassStudent from "ui-component/buttons/ClassStudent";
import IconButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconDotsVertical } from "@tabler/icons";
import { forwardRef } from "react";
import { GridToolbarQuickFilter, DataGridPro } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";

// material-ui
import { useTheme, styled } from "@mui/material/styles";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";
import { student_program_data } from "data";

export default function O_MessageTable(props){
  const CustomQuickFilter = styled(GridToolbarQuickFilter, { shouldForwardProp })(
    ({ theme }) => ({
      width: "100%",
      fontSize: "1.0rem",
      "& input": {
        background: "transparent !important",
        paddingLeft: "4px !important",
        height:'29px',
        fontSize:'1.0rem',
        "&::placeholder": {
          fontSize: "1.0rem",
          color: grey[800],
        },
      },
      [theme.breakpoints.down("lg")]: {
        width: "90%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginLeft: 4,
        background: "#fff",
      },
    })
  );

  const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
    ({ theme }) => ({
      ...theme.typography.commonAvatar,
      ...theme.typography.mediumAvatar,
      background: theme.palette.grey[100],
      color: theme.palette.grey[500],
      "&:hover": {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light,
      },
    })
  );

  const SearchSection = () => {
    const theme = useTheme();
    const [value, setValue] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <>
        <div style={{ display: "flex", gap: 10 }}>
          <Box sx={{ display: { xs: "none", md: "block" }, flex: 1 }}>
          <CustomQuickFilter
              variant="outlined"
              placeholder="메시지 검색"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch
                      stroke={1.5}
                      size="1.5rem"
                      color={theme.palette.grey[500]}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ heigth: "60px" }}>
                    <ButtonBase sx={{ borderRadius: "12px" }}>
                      <HeaderAvatarStyle variant="rounded">
                        <IconAdjustmentsHorizontal stroke={1.5} size="1.0rem" />
                      </HeaderAvatarStyle>
                    </ButtonBase>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ButtonBase
            onClick={handleClick}
            sx={{ height: "60px", width: "100px", backgroundColor: "#f2f5fa" }}
          >
            <Typography fontSize={"1rem"}>Actions</Typography>
            <ArrowDropDown sx={{ marginLeft: "10px" }} />
          </ButtonBase>
          <Menu
            id="hamburger-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "150px", // 원하는 너비로 설정,
                fontSize: "1.7rem",
              },
            }}
            sx={{
              "& .MuiMenuItem-root": {
                fontSize: "1rem",
              },
            }}
            MenuListProps={{
              "aria-labelledby": "hamburger-button",
            }}
          >
            <MenuItem onClick={handleClose}>새 메시지</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>{'(선택)메시지 재발송'}</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>{'(선택)발송/예약 취소'}</MenuItem>
          </Menu>
          <ButtonBase
          sx={{
            height: "60px",
            width: "60px",
            backgroundColor: "#3a63de",
            color: common.white,
          }}
        >
          <IconPlus />
        </ButtonBase>
        </div>
      </>
    );
  };

  function RenderTeacher(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconUser size={36} />
        </Avatar>
        {'아토샘'}
      </div>
    );
  }

  function RenderType(props) {
    // return <StatusChip type={'message_type'} value={props.value} /> 데이터셋 교체 시 적용
    return <StatusChip type={'student'} value={'on'}/>
  }
  function RenderStatus(props) {
    // return <StatusChip type={'message_status'} value={props.value} />
    return <StatusChip type={'student'} value={'on'}/>
  }

  function RenderStudnetName(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconUser size={36} />
        </Avatar>
        {props.value}
      </div>
    );
  }

  function RenderStudentGrade(props) {
    if (props.value < 7) return "초" + props.value;
    else if (props.value < 10) return "중" + (props.value - 6);
    else return "고" + (props.value - 9);
  }

  function RenderHasMessage(props) {
    if (props.value === "true")
      return (
        <Avatar sx={{ bgcolor: "#fd726a", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
    else
      return (
        <Avatar sx={{ bgcolor: "#3a63de", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
  }

  const MenuButton = forwardRef((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [attend, setAttend] = React.useState("attend");
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget.parentElement);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          aria-label="more"
          size="small"
          sx={{ width: "40px", padding: 0 }}
          id="hamburger-button"
          aria-controls={open ? "hamburger-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          disableRipple={true}
          onClick={handleClick}
        >
          <IconDotsVertical color={grey[800]} />
        </IconButton>
        <Menu
          id="hamburger-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
          PaperProps={{
            style: {
              width: "150px", // 원하는 너비로 설정,
              fontSize: "1.7rem",
            },
          }}
          sx={{
            "& .MuiMenuItem-root": {
              fontSize: "1rem",
            },
          }}
        >
          <MenuItem onClick={handleClose}>상세정보</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>재발송</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>발송/예약취소</MenuItem>
        </Menu>
      </div>
    );
  });

  function RenderMessageMenu() {
    return <MenuButton />;
  }

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // Hide columns status and traderName, the other columns will remain visible
        id: false,
      },
    },
    pagination: {
      paginationModel: { pageSize: 25, page: 0 },
    },
  };

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "name",
      headerName: "이름",
      renderCell: RenderStudnetName,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "grade",
      headerName: "학년",
      width: 40,
      renderCell: RenderStudentGrade,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "school",
      headerName: "학교",
      width: 60,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "hasMessage",
      headerName: "",
      minWidth: 20,
      width: 20,
      renderCell: RenderHasMessage,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "title",
      renderCell: ()=>{return '9월학원공지입니다.'},
      headerName: "제목",
      headerAlign: "center",
      minWidth: 160,
      flex: 1,
      align: "center",
    },
    {
      field: "family_type",
      renderCell: ()=>{return '모'},
      headerName: "보호자",
      headerAlign: "center",
      minWidth: 40,
      flex: 1,
      align: "center",
    },
    {
      field: "phone_num_family",
      headerName: "전번",
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
    },
    {
      field: "teacher",
      renderCell:RenderTeacher,
      headerName: "발송샘",
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "send_date",
      renderCell:()=>{return '2023-10-30'},
      headerName: "발송날짜",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "종류",
      width: 100,
      renderCell:RenderType,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "상태",
      width: 70,
      renderCell: RenderStatus,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "menu",
      headerName: "",
      width: 70,
      renderCell: RenderMessageMenu,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const message_table_data = {
    rows: student_program_data,
    columns: columns,
    initialState: initialState,
    disableRowSelectionOnClick: true,
    checkboxSelection: true,
    components: { toolbar: SearchSection },
    rowHeight: 90,
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <DataGridPro
         localeText={{
          noRowsLabel: '데이터가 없습니다',
        }}
          // experimentalFeatures={{ lazyLoading: true }}
          sx={{
            fontSize: "1rem",
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              px: "1px !important",
            },
          }}
          autoHeight
          {...message_table_data}
          pagination
          apiRef={props.apiRef}
        />
      </div>
    </div>
  );
};

import StatusChip from "ui-component/StatusChip";
import AttendenceChip from "ui-component/AttendenceChip";
import * as React from "react";
import { IconChalkboard } from "@tabler/icons";
import {
  Avatar,
  Button,
  Divider,
  Box,
  ButtonBase,
  Card,
  InputAdornment,
  OutlinedInput,
  Popper,
  Typography,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import { red, grey, common } from "@mui/material/colors";
import ClassStudent from "ui-component/buttons/ClassStudent";
import IconButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconDotsVertical } from "@tabler/icons";
import { forwardRef } from "react";
import { GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import { useState } from "react";
import dayjs from "dayjs";

import { DataGridPro } from "@mui/x-data-grid-pro";
// material-ui
import { useTheme, styled } from "@mui/material/styles";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";
import AttendenceMemoClass from "ui-component/modals/AttendenceMemoClass";
import { ThisweekSchedules, GetScheduleStudents, SetScheduleAttendance } from "data/ClassroomDataAPI";

export default function Timetable(props){

  const apiRef = props.apiRef;

  const [classroomSchedules,setClassroomSchedules] = useState([])
  
  const selectedDay = ()=>{
    switch(props.value){
      case 0:
        return "MON"
      case 1:
        return "TUE"
      case 2:
        return "WED"
      case 3:
        return "THU"
      case 4:
        return "FRI"
      case 5:
        return "SAT"
      case 6:
        return "SUN"
      case 7:
        return "all"
    }
  }

  React.useEffect(() => {
    ThisweekSchedules(selectedDay()).then(async (data) => {
      const updatedData = await Promise.all(data.map(async (schedule) => {
        schedule.id = schedule.ID;
        const studentList = await GetScheduleStudents(schedule.ID);
        schedule.studentList = studentList;
        return schedule;
      }));
      setClassroomSchedules(updatedData);
    });
  }, []);

  const OutlineInputStyle = styled(GridToolbarQuickFilter, { shouldForwardProp })(
    ({ theme }) => ({
      width: "100%",
      fontSize: "1.0rem",
      "& input": {
        background: "transparent !important",
        paddingLeft: "4px !important",
        height:'29px',
        fontSize:'1.0rem',
        "&::placeholder": {
          fontSize: "1.0rem",
          color: grey[800],
        },
      },
      [theme.breakpoints.down("lg")]: {
        width: "90%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginLeft: 4,
        background: "#fff",
      },
    })
  );

  const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
    ({ theme }) => ({
      ...theme.typography.commonAvatar,
      ...theme.typography.mediumAvatar,
      background: theme.palette.grey[100],
      color: theme.palette.grey[500],
      "&:hover": {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light,
      },
    })
  );

  const SearchSection = () => {
    const theme = useTheme();
    const [value, setValue] = useState("");

    return (
      <>
        <Box sx={{ display: { xs: "none", md: "block" }, flex: 1 }}>
          <OutlineInputStyle
           variant="outlined"
           placeholder="교실 검색"
           InputProps={{
             startAdornment: (
               <InputAdornment position="start">
                 <IconSearch
                   stroke={1.5}
                   size="1.5rem"
                   color={theme.palette.grey[500]}
                 />
               </InputAdornment>
             ),
             endAdornment: (
               <InputAdornment position="end" sx={{heigth:'60px'}}>
                 <ButtonBase sx={{ borderRadius: "12px" }}>
                   <HeaderAvatarStyle variant="rounded">
                     <IconAdjustmentsHorizontal stroke={1.5} size="1.0rem" />
                   </HeaderAvatarStyle>
                 </ButtonBase>
               </InputAdornment>
             ),
           }}
          />
        </Box>
      </>
    );
  };

  function RenderClassTime(props) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 5,
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "1rem" }}>
          {dayjs(props.row.TheStartTime).format("HH:mm")} ~ {dayjs(props.row.TheEndTime).format("HH:mm")}
        </Typography>
      </div>
    );
  }

  

  function RenderClassStudent(props) {
    function handleAttend(attend,index){
      const list = props.value
      list[index].Attendance = attend
      SetScheduleAttendance(list[index],attend)
      apiRef.current.updateRows([{id:props.row.id,studentList:list}])
    }
    return (
      <div style={{ display: "flex" }}>
        {props.value.map((student, index) => 
          {
          return <ClassStudent 
            index={index}
            handleAttend={handleAttend}
            name={student.DisplayName}
            type={student.LectureType}
            attend={student.Attendance}
            grade={student.GradeLevel}
            school={student.School}
          />}
        )}
      </div>
    );
  }

  function RenderStatus(props) {
        return <StatusChip type={"program"} value={'on'} />;
  }

  function RenderProgramAvatar(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant="square"
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconChalkboard size={36} />
        </Avatar>
        {props.value}
      </div>
    );
  }

  function RenderTeacher(props) {
    return (
      <div
        style={{
          width: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            border: `1px solid`,
            borderColor: grey[600],
            backgroundColor: common.white,
            color: grey[600],
            fontSize: "40px",
          }}
        >
          <Person fontSize="inherit" />
        </Avatar>
        {props.value}
      </div>
    );
  }

  const MenuButton = forwardRef((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [minianchorEl, setminiAnchorEl] = React.useState(null);
    const [attend, setAttend] = React.useState("attend");
    const [memoOpen, setMemoOpen] = useState(false);
    const chipLabel = (() => {
      switch (attend) {
        case "attend":
          return "출석";
        case "late":
          return "지각";
        case "leaveEarly":
          return "조퇴";
        case "online":
          return "온라인";
        case "absent":
          return "결석";
        case "wait":
          return "대기";
        default:
          return "출석";
      }
    })();
    const open = Boolean(anchorEl);
    const miniopen = Boolean(minianchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget.parentElement);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleMemo = () => {
      setMemoOpen(true);
      setAnchorEl(null);
    };
    const handleMemoClose = () => {
      setMemoOpen(false);
    };
    const handleStatusClick = (e) => {
      e.stopPropagation();
      setminiAnchorEl(e.currentTarget.parentElement);
    };
    const handleStatusClose = (props) => {
      setminiAnchorEl(null);
    };

    function handleAttendAll(attend, students){
      const list = props.row.studentList
      list.map((student)=>{
        if(students.includes(student)){
          SetScheduleAttendance(student,attend)
          student.Attendance= attend
        }

      })
      setAnchorEl(null);
      apiRef.current.updateRows([{id:props.row.id,studentList:list}])
    }

    const handleAttendClick=()=>{
      handleAttendAll(attend,props.row.studentList)
    }

    return (
      <div>
        <IconButton
          aria-label="more"
          size="small"
          sx={{ width: "40px", padding: 0 }}
          id="hamburger-button"
          aria-controls={open ? "hamburger-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          disableRipple={true}
          onClick={handleClick}
        >
          <IconDotsVertical color={grey[800]} />
        </IconButton>
        <Menu
          id="hamburger-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
        >
          <MenuItem onClick={handleAttendClick}>
            <div style={{ marginRight: 4 }}>모두</div>
            <Button id={anchorEl} onClick={handleStatusClick} disableRipple={true}>
              <AttendenceChip label={chipLabel} color={attend} />
            </Button>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleMemo}>교실전체 출결메모</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>교실전체 메시지</MenuItem>
        </Menu>
        <Menu
          id="hamburger-menu"
          anchorEl={minianchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={miniopen}
          onClose={handleStatusClose}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
        >
          <MenuItem
            onClick={() => {
              setAttend("attend");
              setminiAnchorEl(null);
            }}
          >
            <AttendenceChip label={"출석"} color={"attend"} />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setAttend("late");
              setminiAnchorEl(null);
            }}
          >
            <AttendenceChip label={"지각"} color={"late"} />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setAttend("leaveEarly");
              setminiAnchorEl(null);
            }}
          >
            <AttendenceChip label={"조퇴"} color={"leaveEarly"} />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setAttend("online");
              setminiAnchorEl(null);
            }}
          >
            <AttendenceChip label={"온라인"} color={"online"} />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setAttend("absent");
              setminiAnchorEl(null);
            }}
          >
            <AttendenceChip label={"결석"} color={"absent"} />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setAttend("wait");
              setminiAnchorEl(null);
            }}
          >
            <AttendenceChip label={"대기"} color={"wait"} />
          </MenuItem>
        </Menu>
        <AttendenceMemoClass handleAttendAll={handleAttendAll} studentList={props.row.studentList} open={memoOpen} handleClose={handleMemoClose} />
      </div>
    );
  });

  function RenderProgramMenu(props) {
    return <MenuButton {...props} />;
  }

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // Hide columns status and traderName, the other columns will remain visible
        id: false,
      },
    },
    pagination: {
      paginationModel: { pageSize: 25, page: 0 },
    },
  };

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "ClassroomTitle",
      headerName: "교실이름",
      renderCell: RenderProgramAvatar,
      minWidth: 170,
      headerAlign: "center",
      align: "center",
      cellProps: {
        style: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" },
      },
    },
    {
      field: "classTime",
      renderCell: RenderClassTime,
      headerName: "시간",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "studentList",
      headerName: "수강생",
      minWidth: 340,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: RenderClassStudent,
    },
    {
      field: "LocationTitle",
      headerName: "장소",
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "AdminDisplayName",
      headerName: "관리샘",
      width: 120,
      renderCell: RenderTeacher,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "TheDate",
      headerName: "날짜",
      width: 100,
      renderCell: (props) => {
        return <Typography variant="h5">{dayjs(props.row.TheDate).format("MM/DD")}</Typography>;
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "상태",
      width: 70,
      renderCell: RenderStatus,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "menu",
      headerName: "",
      width: 70,
      renderCell: RenderProgramMenu,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const timetable_data = {
    rows: classroomSchedules,
    columns: columns,
    initialState: initialState,
    disableRowSelectionOnClick: true,
    checkboxSelection: true,
    components: { toolbar: SearchSection },
    rowHeight: 90,
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <DataGridPro
         localeText={{
          noRowsLabel: '데이터가 없습니다',
        }}
          // experimentalFeatures={{ lazyLoading: true }}
          sx={{
            fontSize: "1rem",
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              px: "1px !important",
            },
          }}
          autoHeight
          {...timetable_data}
          pagination
          apiRef={apiRef}
        />
      </div>
    </div>
  )
}


import { Alert, Button, Divider, Fade, Grid, TextField } from "@mui/material";
import LoginCard from "ui-component/cards/LoginCard";
import image from "assets/image/kakao_login_medium_wide.png";
import React from "react";
import { useDispatch } from "react-redux";
import { LoginRequest } from "data/AuthAPI";
import { Navigate, useNavigate } from "react-router";
import { useSelector } from "react-redux";



function LoginView() {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState({open:false, message:''});
    const [id,setId] = React.useState('');
    const [password,setPassword] = React.useState('');
    const before = useSelector((state) => state.auth.before);
    const token = useSelector((state) => state.auth.token); 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        if(id === '' || password === ''){
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
            }
            , 1000);
        }else{
            LoginRequest(id, password).then((res) => {
               if(res.error){
                     setError({open:true, message:res.error});
                     setTimeout(() => {
                          setError(false);
                     }
                     , 1000);
               }else{
                    dispatch({type:'LOGIN_SUCCESS', payload:res})
                    navigate('/')
               }
            })
        }
    }
    const handleSocialError = () => {
        setError({open:true, message:'소셜 로그인은 준비중입니다. CSLMS 계정으로 로그인해주세요'});
        setTimeout(() => {
            setError(false);
        }
        , 1000);
    }
  return (
    <LoginCard border={false} elevation={16} content={false} boxShadow>
      <Grid container spacing={3} direction={"column"}>
        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
          <h1> 코딩스쿨 로그인 </h1>
        </Grid>
        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
          <Button onClick={handleSocialError}>
            <img src={image} alt="Image" />
          </Button>
        </Grid>
        <Divider sx={{ marginY: 5 }}>OR LOGIN WITH CSLMS ACCOUNT</Divider>
        
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <TextField
              label="아이디"
              variant="outlined"
              sx={{ width: "80%" }}
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
          <form style={{width:'100%', display:'flex', justifyContent:'center'}} onSubmit={handleSubmit}>
            <TextField
              label="비밀번호"
              variant="outlined"
              sx={{ width: "80%" }}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            </form>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ width: "80%" }}
              onClick={handleSubmit}
            >
              로그인
            </Button>
          </Grid>
      </Grid>
      <Fade in={open} timeout={{appear:1000, exit:2000}}>
        <Alert
          severity="error"
          sx={{
            position: "fixed",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >아이디와 비밀번호를 입력해주세요
        </Alert>
      </Fade>
      <Fade in={error.open} timeout={{appear:1000, exit:2000}}>
        <Alert
          severity="error"
          sx={{
            position: "fixed",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >{error.message}
        </Alert>
      </Fade>
    </LoginCard>
  );
}

export default LoginView;

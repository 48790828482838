import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  FilledInput,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

import { common } from "@mui/material/colors";
import { IconX } from "@tabler/icons";
import React from "react";
import theme from "themes";
import StatusChip from "ui-component/StatusChip";
import { SetScheduleAttendanceMemo } from "data/ClassroomDataAPI";

function AttendenceMemoClass(props) {
  const [studentList,setStudentList] = React.useState(props.studentList)
  const [status,setStatus] =  React.useState('wait')
  const [memo,setMemo] = React.useState('')
  const init = ()=>{setStudentList(props.studentList); setStatus('waiting')}
  const paletteTheme = useTheme(theme());
  function handleXClick(e,index) {
    e.stopPropagation();
    const list = studentList.filter((_,i)=>i!==index)
    setStudentList(list);
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    paddingBottom: 10,
  };
  function handleMemoChange(e){
    setMemo(e.target.value)
  }
  function handleSave() {
    props.handleAttendAll(status,studentList)
    studentList.map(async(student,_)=>{
      await SetScheduleAttendanceMemo(student,memo)
    })
    props.handleClose();
    init();
  }
  function handleChange(e){
    setStatus(e.target.value)
  }
  return (
    <ThemeProvider theme={theme}>
      <Modal open={props.open} onClose={()=>{props.handleClose(); init()}}>
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "5%" }} />
            <Typography variant="h2">교실전체 출결메모</Typography>
            <div style={{ flex: 1 }} />
            <ButtonBase onClick={props.handleClose}>
              <IconX  />
            </ButtonBase>
            <div style={{ width: "2%" }} />
          </Box>
          <Divider />
          <Grid
            container
            alignItems={"center"}
            direction={"column"}
            spacing={3}
          >
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {studentList.map((student, index) => (
                  <Grid
                    item
                    xs={4}
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap:4
                    }}
                  >
                    <div style={{ minWidth: 160, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }} >
                    <Avatar
                      variant="square"
                      sx={{
                        width: 50,
                        height: 50,
                        backgroundColor: common.white,
                        color: paletteTheme.palette.cslms.avatar,
                        borderRadius: 4,
                        border: "1px solid",
                      }}
                    />
                    <div style={{display:'flex', flexDirection:'row'}}>
                    <Typography fontSize='1rem'>{student.DisplayName}</Typography>
                    <ButtonBase onClick={(e)=>handleXClick(e,index)} sx={{ marginBottom:3, width:25,height:25, zIndex:1000 }}className={`${index}`}>
                      <IconX style={{zIndex:800}} />
                    </ButtonBase>
                    </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ width: "90%" }}>
              <InputLabel id="status_label">출결상태</InputLabel>
              <Select
                labelId="status_label"
                id="status"
                value={status}
                onChange={handleChange}
                input={<FilledInput label="출결상태"/>}
                sx={{textAlign:'right'}}
                renderValue={(value)=>{return <StatusChip type={'attendance'} value = {value}/>}}
                fullWidth
              >
                <MenuItem
                  sx={{justifyContent:'right'}}
                  value='attend'
                >
                  <StatusChip type={'attendance'} value={'attend'}/>
                </MenuItem>
                <MenuItem
                  sx={{justifyContent:'right'}}
                  value='late'
                >
                  <StatusChip type={'attendance'} value={'late'}/>
                </MenuItem>
                <MenuItem
                  sx={{justifyContent:'right'}}
                  value='leave'
                >
                  <StatusChip type={'attendance'} value={'leave'}/>
                </MenuItem>
                <MenuItem
                  sx={{justifyContent:'right'}}
                  value='online'
                >
                  <StatusChip type={'attendance'} value={'online'}/>
                </MenuItem>
                <MenuItem
                  sx={{justifyContent:'right'}}
                  value='absent'
                >
                  <StatusChip type={'attendance'} value={'absent'}/>
                </MenuItem>
                <MenuItem
                  sx={{justifyContent:'right'}}  
                  value='waiting'
                >
                  <StatusChip type={'attendance'} value={'waiting'}/>
                </MenuItem>                
              </Select>
            </Grid>
            <Grid item xs={12} sx={{ width: "90%" }}>
              <TextareaAutosize
                style={{
                  padding: 10,
                  width: '630px',
                  minWidth: '200px',
                  fontSize: "1.0rem",
                  fontFamily: `'Roboto',sans-serif`,
                }}
                onChange={handleMemoChange}
                minRows={5}
                placeholder="출결메모 : 10분 늦음"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleSave}
                variant="contained"
                color="save"
                sx={{
                  borderRadius: 0,
                  width: 140,
                  height: 60,
                  fontSize: "1.5rem",
                }}
              >
                저장하기
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}

export default AttendenceMemoClass;

import { useState } from "react";
import { ButtonBase, Avatar, Badge, useTheme } from "@mui/material";
import StudentModal from "ui-component/modals/StudentModal";
import { Person } from "@mui/icons-material";
import { green, grey, red, purple, yellow, common } from "@mui/material/colors";
import { ThemeProvider } from "@emotion/react";
import themes from "themes";
import AttendenceMemo from "ui-component/modals/AttendenceMemo";

function ClassStudent(props) {
  const theme = useTheme(themes())
  const [anchorEl, setAnchorEl] = useState(null);
  const [singleMemoOpen, setSingleMemoOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSingleMemo = ()=>{
    document.body.style.overflow='hidden';
    setSingleMemoOpen(true);
    setAnchorEl(null);
  }
  const handleMemoClose = ()=>{
    document.body.style.overflow='auto';
    setSingleMemoOpen(false);
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
 
  
  const isSupply = (props.type==='supply'||props.type==='free_supply')
  const badgeColor = isSupply? (props.type==='free_supply'?'free_supply':props.type): 'primary'
  const label = (()=>{
    switch(props.attend){
      case 'attend': return '출석'
      case 'late': return '지각'
      case 'leaveEarly': return '조퇴'
      case 'online': return '온라인'
      case 'absent': return '결석'
      case 'wait': return '대기'
      
    }
  })()
  return (
    <div>
      <ThemeProvider theme={theme}>
      <Badge color={badgeColor} badgeContent={'C'} sx={{'& .MuiBadge-badge':{fontSize:'1rem', fontWeight:700, width:'8%'}}} invisible={!isSupply}>
      <ButtonBase onClick={handleClick} sx={{ padding: 0 }}>
        <div style={{ width: 60, fontSize:'1rem',textAlign:'center', display:'flex',justifyContent:'center', alignItems:'center', flexDirection:'column' }}>
          <Avatar
            variant="rounded"
            sx={{ border: `2px solid`, borderColor:theme.palette[`${props.attend}`]? theme.palette[`${props.attend}`].main : theme.palette[`waiting`].main , backgroundColor:common.white, color:grey[600],fontSize:'40px' }}
          >
            <Person fontSize='inherit'/>
          </Avatar>
          {props.name}
        </div>
      </ButtonBase>
      </Badge>
      </ThemeProvider>
      <StudentModal
        anchorEl={anchorEl}
        index={props.index}
        setAttend = {props.handleAttend}
        setAnchorEl={setAnchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        handleSingleMemo = {handleSingleMemo}
        open={open}
        id={id}
        name={props.name}
        grade={props.grade}
        school={props.school}
        programs={props.programs}
      />
      <AttendenceMemo index={props.index} setAttend={props.handleAttend} name={props.name} open={singleMemoOpen} handleClose={handleMemoClose} attend={{label:label,color:props.attend}}/>
    </div>
  );
}

export default ClassStudent;

function generateRandomId() {
    const idSet = new Set();
    while (true) {
      const randomId = String(Math.floor(100000 + Math.random() * 900000));
      if (!idSet.has(randomId)) {
        idSet.add(randomId);
        return randomId;
      }
    }
  }
  
  
  // 랜덤 한글 이름 생성 함수 (3글자)
  function generateRandomName() {
    const firstNames = ["김", "이", "박", "최", "정", "강", "조", "윤", "장", "임"];
    const middleNames = ["미", "지", "하", "영", "성", "은", "현", "주", "정", "수"];
    const lastNames = ["철", "영", "민", "우", "진", "서", "수", "예", "은", "하"];
  
    const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
    const middleName = middleNames[Math.floor(Math.random() * middleNames.length)];
    const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
  
    return firstName + middleName + lastName;
  }
  
  // 랜덤 상태 (status) 생성 함수
  function generateRandomStatus() {
    const statuses = ["attend", "finished", "withdraw", "off"];
    return statuses[Math.floor(Math.random() * statuses.length)];
  }

  function generateRandomAttend(){
    const attends = ['attend','online','late','leaveEarly','wait','absent']
    return attends[Math.floor(Math.random()*6)];
  }
  function generateRandomSchool(grade) {
    const schoolnames = ["서울", "고덕", "천호", "중앙"];
    const schoollevels = ["초", "중", "고"];
    return schoolnames[Math.floor(Math.random() * schoolnames.length)]+''+(grade<7?schoollevels[0]:grade<10?schoollevels[1]:schoollevels[2]);
  }
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  // 랜덤한 날짜 생성
  // 랜덤 학년 (grade) 생성 함수 (1~12 중 랜덤)
  function generateRandomGrade() {
    return Math.floor(1 + Math.random() * 12);
  }
  
  // 랜덤 Progress 정보 생성 함수
  function generateRandomProgress() {
    const randomDate = new Date(
      firstDay.getTime() + Math.random() * (lastDay.getTime() - firstDay.getTime())
      );
    const minLabelValue = 1;
    const maxLabelValue = 50;
    const label = `엔트리기본(${Math.floor(minLabelValue + Math.random() * (maxLabelValue - minLabelValue + 1))})`;
    const progressTypes = ["regular", "skip", "supply", "free_supply"];
    const isFinished = Math.random() < 0.5 ? false : true;
    const memoCount = Math.floor(Math.random() * 2);
    const counselCount = Math.floor(Math.random() * 2);
    function getDay(){
      switch(randomDate.getDay()){
        case 0: return '일'
        case 1: return '월'
        case 2: return '화'
        case 3: return '수'
        case 4: return '목'
        case 5: return '금'
        case 6: return '토'
      }
    }
    const progress = {
      label: label,
      attend: generateRandomAttend(),
      type: progressTypes[Math.floor(Math.random() * progressTypes.length)],
      isFinished: (progressTypes === "skip" || progressTypes === "absent") ? false : isFinished,
      memoCount: memoCount,
      counselCount: counselCount,
      lectures:'11,12,13...',
      day: getDay(),
      classroom: getDay()+'1500교실A',
      date: randomDate.toISOString().slice(0, 10)
    };
  
    return progress;
  }
  
  // 지정된 개수만큼 Progress 정보 배열 생성 함수
  function generateProgressArray(count) {
    const progresses = [];
    for (let i = 0; i < count; i++) {
      progresses.push(generateRandomProgress());
    }
    return progresses;
  }
  
  // 지정된 개수만큼 데이터 생성 함수
  function generateRandomData(count) {
    const data = [];
    for (let i = 0; i < count; i++) {
      const progressCount = Math.floor(4 + Math.random() * 5);
      const grade = generateRandomGrade();
      const entry = {
        id: generateRandomId(),
        name: generateRandomName(),
        status: generateRandomStatus(),
        grade: grade,
        school: generateRandomSchool(grade),
        progress_count: progressCount,
        progresses: generateProgressArray(progressCount),
      };
      data.push(entry);
    }
    return data;
  }

  export default generateRandomData
  
import { Outlet, useNavigate } from "react-router";
import { Box, CssBaseline } from "@mui/material";
import { VerifyToken } from "data/AuthAPI";
import { useDispatch } from "react-redux";


function LoginLayout() {
    const loc_token = localStorage.getItem('token');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    if(loc_token){
        console.log('token exists');
        VerifyToken(loc_token).then((res) => {
            if(res.Valid){
                dispatch({type:'LOGIN_SUCCESS', payload:{token:loc_token}})
                navigate('/')
            }else{
                localStorage.removeItem('token');
            }
        })
    }
    return ( 
        <Box sx={{ display: 'flex', backgroundColor: '#ebeef2', height:'100vh' }}>
            <CssBaseline/>
            <Outlet />
        </Box>
     );
}

export default LoginLayout;
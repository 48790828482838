
export async function GetStudents() {
  const API_URL = process.env.REACT_APP_API_URL;
  const center_id = 1000001;// TODO: get center id from user
  const res = await fetch(API_URL + `/users/?role=student`);
  const result = await res.json();
  result.map((student, _) => {
    student.id = student.UserId;
  });
  return result;
}
export async function GetStudent(id) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/" + id);
  const result = await res.json();
  return result;
}

export async function CreateStudent(student) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/", {
    method: "POST",
    body: JSON.stringify(student),
  });
  const result = await res.json();
  return result;
}

export async function CreateUIC(uic) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/center", {
    method: "POST",
    body: JSON.stringify(uic),
  });
  const result = await res.json();
  return result;
}

export async function UpdateStudent(student) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/"+student.ID, {
    method: "PUT",
    body: JSON.stringify(student),
  });
  const result = await res.json();
  return result;
}

export async function UpdateUIC(uic) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/center/"+uic.ID, {
    method: "PUT",
    body: JSON.stringify(uic),
  });
  const result = await res.json();
  return result;
}

export async function DeleteStudent(id) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/"+id, {
    method: "DELETE",
  });
  const result = await res.json();
  return result;
}

export async function DeleteUICStudent(id) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/center/"+id, {
    method: "DELETE",
  });
  const result = await res.json();
  return result;
}

export async function DeleteUIC(id) {
  const API_URL = process.env.REACT_APP_API_URL;
  const res = await fetch(API_URL + "/users/center/"+id, {
    method: "DELETE",
  });
  const result = await res.json();
  return result;
}

export const ChangePassword = async (userId, guidId, userName, newPassword) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/pw/${guidId}`,  {
      method: "PUT",
      body: JSON.stringify({
        UserId: userId,
        GuidId: guidId,
        UserName: userName,
        Password: newPassword,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};
// Data CRUD for Lectures using fetch

export async function GetLectures(ProgramId) {
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/lectures?programid=" + ProgramId);
    var result = await res.json();
    if (result.length === undefined) {
        const temp = [];
        temp.push(result);
        result = temp;
    }
    result.map((lecture, _) => {
        lecture.id = lecture.ID;
    });
    return result;
}

export async function GetLecture(id) {
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/lectures" + id);
    const result = await res.json();
    return result;
}

export async function CreateLecture(lecture) {
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/lectures/", {
        method: "POST",
        body: JSON.stringify(lecture),
    });
    const result = await res.json();
    return result;
}

export async function UpdateLecture(lecture) {
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/lectures/"+lecture.ID, {
        method: "PUT",
        body: JSON.stringify(lecture),
    });
    const result = await res.json();
    return result;
}

export async function DeleteLecture(id) {
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/lectures/" + id, {
        method: "DELETE",
    });
    const result = await res.json();
    return result;
}

export async function GetStudentLecture(programid, studentid){
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/lectures/student?programid=" + programid + "&userid=" + studentid);
    
}

export const saveLectureOrder = async (lectures) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/lectures/order`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(lectures.map((lecture, index) => ({
        ID: lecture.ID,
        ChapterNo: index + 1
      }))),
    });
    return await response.json();
  } catch (error) {
    console.error('Error saving lecture order:', error);
    throw error;
  }
};

const FormData = require('form-data');

// Upload Image func that executed like postman body-formdata "image" key multipart/form-data
export async function UploadImage(image, filepath) {
    console.log(image);
    const API_URL = process.env.REACT_APP_API_URL;
    const form = new FormData();
    form.append('image', image);
    form.append('filepath',filepath)
    const res = await fetch(API_URL + "/images", {
        method: "POST",
        headers: form.getHeaders,
        body: form,
    });
    const result = await res.json();
    return result;
}

export async function GetImage(filename) {
    console.log(filename);
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL +"/image/?filepath="+filename);
    const result = await res.json();
    return result;
}

export async function GetImages(directory){
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/images/"+directory);
    var result = await res.json();
    if(result === null) result = [];
    else if(result.length === undefined){
      const temp = [];
      temp.push(result);
      result = temp;
    }
    return result;
}

export async function DeleteImage(filename){
    const API_URL = process.env.REACT_APP_API_URL;
    const res = await fetch(API_URL + "/image/?filepath="+filename,{
        method: "DELETE",
    });
    const result = await res.json();
    return result;
}
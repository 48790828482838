import StatusChip from "ui-component/StatusChip";
import * as React from "react";
import { useState, useEffect, forwardRef } from "react";
import { IconPlus, IconUser } from "@tabler/icons";
import { Avatar,Divider,Box,ButtonBase,InputAdornment,Typography,Checkbox,FormControlLabel,FormGroup,FormControl,Button,} from "@mui/material";
import {GridToolbarQuickFilter,DataGridPro,GridToolbarContainer,} from "@mui/x-data-grid-pro";
import {  grey, common } from "@mui/material/colors";
import IconButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconDotsVertical } from "@tabler/icons";
import { ArrowDropDown } from "@mui/icons-material";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import PasswordChangeModalView from "ui-component/modals/PasswordChangeModalView";

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { alpha, shouldForwardProp } from "@mui/system";
import { useRef } from "react";
import CourseAssign from "ui-component/modals/CourseAssign";
import ChangeClassroom from "ui-component/modals/ChangeClassroom";
import {GetStudents, DeleteStudent, DeleteUICStudent} from "data/StudentDataAPI";
import CRUDStudent from "ui-component/modals/CRUDStudent";
import DeleteElement from "ui-component/modals/DeleteElement";


export default function I_StudentTable(props) {
  const [originalData, setOriginalData] = useState([]);
  const [studentdata, setStudentdata] = useState([]);
  const [filterOptions, setFilterOptions] = useState(() => {
    const savedFilters = localStorage.getItem('studentTableFilters');
    return savedFilters ? JSON.parse(savedFilters) : {
      on: true,
      off: true,
      deleted: true,
      waiting: true
    };
  });

  useEffect(() => {
    GetStudents().then((Students) => {
      setOriginalData(Students);
      const filteredData = applyFilters(Students, filterOptions);
      setStudentdata(filteredData);
    });
  }, []);

  const applyFilters = (data, filters) => {
    return data.filter(student => {
      if (student.UserStatus === "on" && filters.on) return true;
      if (student.UserStatus === "off" && filters.off) return true;
      if (student.UserStatus === "deleted" && filters.deleted) return true;
      if (student.UserStatus === "waiting" && filters.waiting) return true;
      return false;
    });
  };

  const ref = useRef()
  const CustomQuickFilter = styled(GridToolbarQuickFilter, { shouldForwardProp })(
    ({ theme }) => ({
      width: "100%",
      fontSize: "1.0rem",
      "& input": {
        background: "transparent !important",
        paddingLeft: "4px !important",
        height:'29px',
        fontSize:'1.0rem',
        "&::placeholder": {
          fontSize: "1.0rem",
          color: grey[800],
        },
      },
      [theme.breakpoints.down("lg")]: {
        width: "90%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginLeft: 4,
        background: "#fff",
      },
    })
  );

  const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
    ({ theme }) => ({
      ...theme.typography.commonAvatar,
      ...theme.typography.mediumAvatar,
      background: theme.palette.grey[100],
      color: theme.palette.grey[500],
      "&:hover": {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light,
      },
    })
  );

  const handleXClick = (e, index, id) => {
    const list = props.selectedRow.filter((_, i) => i !== index);
    props.setSelectedRow(list);
    props.apiRef.current.selectRow(id, false);
  };

  function SelectedStudents(props) {
    return (
      <Box
        key={`student${props.index}`}
        sx={{
          borderRadius: "2px",
          backgroundColor: "#e3e6eb",
          padding: "2px",
          width: "114px",
          height: "45px",
        }}
      >
        {props.LastName+props.FirstName +
          "(" +
          (RenderStudentGrade({ value: props.GradeLevel }) +
            "," +
            (props.Gender === "M" ? "남" : "여") +
            ")")}
        <ButtonBase
          id={`xButton${props.index}`}
          onClick={(e) => handleXClick(e, props.index, props.id)}
          sx={{ marginBottom: 3, width: 14, height: 14, zIndex: 1000 }}
        >
          <IconX style={{ zIndex: 800 }} />
        </ButtonBase>
      </Box>
    );
  }

  const SearchSection = () => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [courseAnchorEl,setCourseAnchorEl] = useState(null);
    const [changeAnchorEl,setChangeAnchorEl] = useState(null);
    const [createMenuAnchorEl,setCreateMenuAnchorEl] = useState(null);
    const [createAnchorEl,setCreateAnchorEl] = useState(null);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [tempFilterOptions, setTempFilterOptions] = useState(filterOptions);

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCreateMenu = (e)=>{
      setCreateMenuAnchorEl(e.currentTarget);
    }

    const handleCourse = (e)=>{
      setCourseAnchorEl(e.currentTarget);
    }
    const handleCourseClose = ()=>{
      setCourseAnchorEl(null);
    }

    const handleChangeClassroom=(e)=>{
      setChangeAnchorEl(e.currentTarget);
    }

    const handleChangeClose=()=>{
      setChangeAnchorEl(null);
    }

    const handleCreate = (e)=>{
      setCreateAnchorEl(e.currentTarget)
    }

    const handleCreateClose = ()=>{
      setCreateAnchorEl(null);
    }

    const handleSave = () => {
      setAnchorEl(null);
      GetStudents().then((Students) => {
        setOriginalData(Students);
        const filteredData = applyFilters(Students, filterOptions);
        setStudentdata(filteredData);
      });
    };

    const handleFilterClick = (event) => {
      setFilterAnchorEl(event.currentTarget);
      setTempFilterOptions({...filterOptions});
    };

    const handleFilterClose = () => {
      setFilterAnchorEl(null);
      setTempFilterOptions({...filterOptions});
    };

    const handleFilterOptionChange = (option) => {
      setTempFilterOptions(prev => ({
        ...prev,
        [option]: !prev[option]
      }));
    };

    const handleApplyFilter = () => {
      setFilterOptions(tempFilterOptions);
      const filteredData = applyFilters(originalData, tempFilterOptions);
      setStudentdata(filteredData);
      localStorage.setItem('studentTableFilters', JSON.stringify(tempFilterOptions));
      handleFilterClose();
    };

    const open = Boolean(anchorEl);
    const courseOpen = Boolean(courseAnchorEl);
    const changeOpen = Boolean(changeAnchorEl);
    const createOpen = Boolean(createAnchorEl);

    return (
      <>
        <div style={{ display: "flex", gap: 10 }}>
          <Box sx={{ display: { xs: "none", md: "block" }, flex: 1 }}>
            <CustomQuickFilter
              variant="outlined"
              placeholder="학생 검색"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch
                      stroke={1.5}
                      size="1.5rem"
                      color={theme.palette.grey[500]}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{height:'60px'}}>
                    <ButtonBase 
                      sx={{ borderRadius: "12px" }}
                      onClick={handleFilterClick}
                    >
                      <HeaderAvatarStyle variant="rounded">
                        <IconAdjustmentsHorizontal stroke={1.5} size="1.0rem" />
                      </HeaderAvatarStyle>
                    </ButtonBase>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ButtonBase
            onClick={handleClick}
            sx={{ height: "60px", width: "100px", backgroundColor: "#f2f5fa" }}
          >
            <Typography fontSize={"1rem"}>Actions</Typography>
            <ArrowDropDown sx={{ marginLeft: "10px" }} />
          </ButtonBase>
          <Menu
            id="hamburger-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            slotProps={{
              paper: {
                style: {
                  width: "150px", // 원하는 너비로 설정,
                  fontSize: "1.7rem",
                },
              },
            }}
            sx={{
              "& .MuiMenuItem-root": {
                fontSize: "1rem",
              },
            }}
            MenuListProps={{
              "aria-labelledby": "hamburger-button",
            }}
          >
            <MenuItem onClick={handleCourse} disabled={props.selectedRow.length===0}>프로그램 수강등록</MenuItem>
            <Divider />
            <MenuItem onClick={handleChangeClassroom} disabled={props.selectedRow.length===0}>교실배정 변경</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose} disabled={props.selectedRow.length===0}>메시지 발송</MenuItem>
          </Menu>
          <CourseAssign open={courseOpen} onClose={handleCourseClose} students={props.selectedRow}/>
          <ChangeClassroom open={changeOpen} onClose={handleChangeClose} students={[props.selectedRow]}/>
          <ButtonBase
          sx={{
            height: "60px",
            width: "60px",
            backgroundColor: "#3a63de",
            color: common.white,
          }}
          onClick={handleCreate}
        >
          <IconPlus />
        </ButtonBase>
        </div>
        {/* Selected Students */}
        <div
          style={{
            overflowX: "auto",
            backgroundColor: alpha("#009eb2", 0.14),
            display: "flex",
          }}
        >
          {props.selectedRow.length > 0 && (
            <>
              <Box
                sx={{
                  borderLeft: `5px solid #009eb2`,

                  height: 60,
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <div></div>
                {props.selectedRow.map((row, index) => {
                  return (
                    <div key={`student${index}`}>
                      <SelectedStudents {...row} index={index} />
                    </div>
                  );
                })}
              </Box>
              <Box sx={{ width: 0 }}>{"ㅤ"}</Box>
            </>
          )}
        </div>
        <CRUDStudent open={createOpen} onClose = {handleCreateClose} handleSave = {handleSave}/>
        <Menu
          anchorEl={filterAnchorEl}
          open={Boolean(filterAnchorEl)}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps ={{
            paper: {
              sx: {
                p: 2,
              width: '300px'
              },
            },
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: 2, px: 2 }}>필터 옵션</Typography>
          <FormGroup sx={{ px: 2 }}>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={tempFilterOptions.on}
                  onChange={() => handleFilterOptionChange('on')}
                />
              }
              label="재원"
            />
            <FormControlLabel
              control={
                <Checkbox 
                  checked={tempFilterOptions.off}
                  onChange={() => handleFilterOptionChange('off')}
                />
              }
              label="종료"
            />
            <FormControlLabel
              control={
                <Checkbox 
                  checked={tempFilterOptions.deleted}
                  onChange={() => handleFilterOptionChange('deleted')}
                />
              }
              label="퇴원"
            />
            <FormControlLabel
              control={
                <Checkbox 
                  checked={tempFilterOptions.waiting}
                  onChange={() => handleFilterOptionChange('waiting')}
                />
              }
              label="대기"
            />
          </FormGroup>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Button onClick={handleFilterClose} sx={{ mr: 1 }}>취소</Button>
            <Button onClick={handleApplyFilter} variant="contained">적용</Button>
          </Box>
        </Menu>
      </>
    );
  };

  function RenderPhoneOwn(props){
    return props.row.phone
  }

  function RenderStatus(props) {
    return <StatusChip type={"student"} value={props.value}/>
  }

  function RenderStudentName(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant='rounded'
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconUser size={36} />
        </Avatar>
        {props.value}
      </div>
    );
  }

  function RenderStudentGrade(props) {
    return props.value
  }

  function RenderHasMessage(props) {
    if (props.value === "true")
      return (
        <Avatar sx={{ bgcolor: "#fd726a", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
    else
      return (
        <Avatar sx={{ bgcolor: "#3a63de", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
  }

  const MenuButton = forwardRef((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [minianchorEl, setminiAnchorEl] = React.useState(null);
    const [infoAnchorEl, setInfoAnchorEl] = React.useState(null);
    const [courseAnchorEl, setCourseAnchorEl] = React.useState(null);
    const [changeAnchorEl, setChangeAnchorEl] = React.useState(null);
    const [passwordAnchorEl, setPasswordAnchorEl] = React.useState(null);
    const [attend, setAttend] = React.useState("attend");
    const [deleteAnchorEl, setDeleteAnchorEl] = React.useState(null);
    const courseOpen= Boolean(courseAnchorEl);
    const changeOpen = Boolean(changeAnchorEl);
    const infoOpen = Boolean(infoAnchorEl);
    const deleteOpen = Boolean(deleteAnchorEl);
    const passwordOpen = Boolean(passwordAnchorEl);

    const handleInfo = (e)=>{
      setInfoAnchorEl(e.currentTarget);
      setAnchorEl(null);
    }
    const handleInfoClose = ()=>{
      setInfoAnchorEl(null);
    }
    const handleInfoSave = () => {
      setInfoAnchorEl(null);
      GetStudents().then((Students) => {
        setOriginalData(Students);
        const filteredData = applyFilters(Students, filterOptions);
        setStudentdata(filteredData);
      });
    };
    const handleCourse = (e)=>{
      setCourseAnchorEl(e.currentTarget);
      setAnchorEl(null);
    }
    const handleCourseClose=()=>{
      setCourseAnchorEl(null);
    }
    const handleChangeClassroom=(e)=>{
      setChangeAnchorEl(e.currentTarget)
    }
    const handleChangeClose=()=>{
      setChangeAnchorEl(null);
    }

    const handleDelete = (e)=>{
      setDeleteAnchorEl(e.currentTarget);
      setAnchorEl(null);
    }

    const handlePasswordChange = (e)=>{
      setPasswordAnchorEl(e.currentTarget);
      setAnchorEl(null);
    }
    const handlePasswordClose = ()=>{
      setPasswordAnchorEl(null);
    }

    const handleDeleteClose = ()=>{
      setDeleteAnchorEl(null);
    }

    const chipLabel = (() => {
      switch (attend) {
        case "attend":
          return "출석";
        case "late":
          return "지각";
        case "leaveEarly":
          return "조퇴";
        case "online":
          return "온라인";
        case "absent":
          return "결석";
        case "wait":
          return "대기";
        default:
          return "출석";
      }
    })();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget.parentElement);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          aria-label="more"
          size="small"
          sx={{ width: "40px", padding: 0 }}
          id="hamburger-button"
          aria-controls={open ? "hamburger-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          disableRipple={true}
          onClick={handleClick}
        >
          <IconDotsVertical color={grey[800]} />
        </IconButton>
        <Menu
          id="hamburger-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
        >
          <MenuItem onClick={handleInfo}>학생 상세정보 열기</MenuItem>
          <Divider />
          <MenuItem onClick={handleCourse}>프로그램 수강등록</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>수강중도 변경</MenuItem>
          <Divider />
          <MenuItem onClick={handleChangeClassroom}>교실배정 변경</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>메시지 발송</MenuItem>
          <Divider />
          <MenuItem onClick={handleDelete}>학생 삭제</MenuItem>
          <Divider />
          <MenuItem onClick={handlePasswordChange}>비밀번호 변경</MenuItem>
        </Menu>
        <CourseAssign open = {courseOpen} onClose={handleCourseClose} students={[props.row]}/>
        <ChangeClassroom open={changeOpen} onClose={handleChangeClose} students={[props.row]}/>
        <PasswordChangeModalView open={passwordOpen} onClose={handlePasswordClose} students={[props.row]}/>
        <CRUDStudent open={infoOpen} onClose={handleInfoClose} handleSave={handleInfoSave} {...props.row} />
        <DeleteElement open={deleteOpen} onClose={handleDeleteClose} onDelete={async ()=>{
           await DeleteStudent(props.row.UserId); handleDeleteClose();
           await DeleteUICStudent(props.row.UICID); 
            GetStudents().then((Students)=>{
              setStudentdata(Students);
            })
          }}
           />
      </div>
    );
  });

  function RenderStudentMenu(props) {
    return <MenuButton {...props} />;
  }

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // Hide columns status and traderName, the other columns will remain visible
        UserId: false,
      },
    },
    pagination: {
      paginationModel: { pageSize: 25, page: 0 },
    },
  };

  function handleChange(item, e) {
    const selectedIds = new Set(item);
    const selectedRows = studentdata.filter((row) => selectedIds.has(row.id));
    props.setSelectedRow(selectedRows);
  }

  const columns = [
    {
      field: "UserId",
    },
    {
      field: "DisplayName",
      headerName: "이름",
      renderCell: RenderStudentName,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "DisplaySchoolage",
      headerName: "학년연도",
      width: 60,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "GradeLevel",
      headerName: "학년",
      width: 10,
      renderCell: RenderStudentGrade,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "School",
      headerName: "학교",
      width: 60,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "hasMessage",
      headerName: "",
      minWidth: 20,
      width: 20,
      renderCell: RenderHasMessage,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "UserName",
      headerName: "아이디",
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "phone_num_own",
      headerName: "학생전번",
      headerAlign: "center",
      renderCell:(props)=>{
        return props.row.PhoneOwn
      },
      minWidth: 120,
      flex: 1,
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "phone_num_family",
      headerName: "보호자 전번",
      renderCell:(props)=>{
        return props.row.PhoneParent
      },
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "RegisterAt",
      headerName: "등록날짜",
      renderCell: (props)=>{
        return props.value.slice(0,10)
      },
      width: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "UserStatus",
      headerName: "상태",
      width: 70,
      renderCell: RenderStatus,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "menu",
      headerName: "",
      width: 70,
      renderCell: RenderStudentMenu,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const CustomToolbar = React.forwardRef((props, ref) => {
    const { state } = props;
    
    return (
      <GridToolbarContainer 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 2,
          borderBottom: '1px solid rgba(224, 224, 224, 1)'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="subtitle1" sx={{ p: 1, fontSize: '1.0rem' }}>
            전체 학생수: {studentdata.length}명
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SearchSection />
        </Box>
      </GridToolbarContainer>
    );
  });

  const studenttable_data = {
    rows: studentdata,
    columns: columns,
    initialState: {
      ...initialState,
      pagination: {
        ...initialState.pagination,
        paginationModel: { pageSize: 25, page: 0 },
      }
    },
    disableRowSelectionOnClick: true,
    checkboxSelection: true,
    onRowSelectionModelChange: handleChange,
    slots: {
      BaseCheckbox: React.forwardRef((props, _) => {
        return <Checkbox {...props} />;
      }),
      toolbar: CustomToolbar,
    },
    rowHeight: 90,
    pagination: true,
    paginationMode: 'client',
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <DataGridPro
          localeText={{
            noRowsLabel: '데이터가 없습니다',
            MuiTablePagination: {
              labelRowsPerPage: '페이지당 행:',
              labelDisplayedRows: ({ from, to, count }) =>
                `${count}개 중 ${from}–${to}`,
            },
          }}
          sx={{
            fontSize: "1rem",
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              px: "1px !important",
            },
            "& .MuiDataGrid-toolbarContainer": {
              padding: 0
            }
          }}
          autoHeight
          {...studenttable_data}
          apiRef={props.apiRef}
          slotProps={{
            toolbar: {
              state: {
                pagination: {
                  rowCount: studentdata.length,
                  page: studenttable_data.initialState.pagination.paginationModel.page
                }
              }
            }
          }}
        />
      </div>
    </div>
  );
}

import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  Input,
  Modal,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
  InputLabel,
  Select,
  FilledInput,
  MenuItem,
} from "@mui/material";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { common } from "@mui/material/colors";
import { IconX } from "@tabler/icons";
import React from "react";
import theme from "themes";
import StatusChip from "ui-component/StatusChip";

function AttendenceMemo(props) {
  const paletteTheme = useTheme(theme());
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    paddingBottom: 10,
  };
  const [status, setStatus] = React.useState('waiting');
  const init = ()=>{setStatus('waiting')}
  function handleChange(e) {
    setStatus(e.target.value);
  }
  function handleSave() {
    props.setAttend(status,props.index)
    props.handleClose();
    init();
  }
  return (
    <ThemeProvider theme={theme}>
      <Modal open={props.open} onClose={()=>{props.handleClose();init()}}>
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "5%" }} />
            <Typography variant="h2">출결메모</Typography>
            <div style={{ flex: 1 }} />
            <ButtonBase onClick={props.handleClose}>
              <IconX />
            </ButtonBase>
            <div style={{ width: "2%" }} />
          </Box>
          <Divider />
          <Grid
            container
            alignItems={"center"}
            direction={"column"}
            spacing={3}
          >
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Avatar
                variant="square"
                sx={{
                  width: 200,
                  height: 200,
                  backgroundColor: common.white,
                  color: paletteTheme.palette.cslms.avatar,
                  borderRadius: 4,
                  border: "1px solid",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ width: "90%" }}>
              <TextField
                label="이름"
                variant="filled"
                defaultValue={props.name}
                color=""
                sx={{ "& label": { fontSize: "1rem" } }}
                InputProps={{ readOnly: true, style: { fontSize: "1.0rem" } }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ width: "90%" }}>
              <InputLabel id="status_label">출결상태</InputLabel>
              <Select
                labelId="status_label"
                id="status"
                defaultValue="waiting"
                value={status}
                onChange={handleChange}
                input={<FilledInput label="출결상태" />}
                sx={{ textAlign: "right" }}
                renderValue={(value) => (
                  <StatusChip type={"attendance"} value={value}  />
                )}
                fullWidth
              >
                <MenuItem
                  sx={{ justifyContent: "right" }}
                  value="attend"
                >
                  <StatusChip type={"attendance"} value={"attend"} />
                </MenuItem>
                <MenuItem
                  sx={{ justifyContent: "right" }}
                  value="late"
                >
                  <StatusChip type={"attendance"} value={"late"} />
                </MenuItem>
                <MenuItem
                  sx={{ justifyContent: "right" }}
                  value="leave"
                >
                  <StatusChip type={"attendance"} value={"leave"} />
                </MenuItem>
                <MenuItem
                  sx={{ justifyContent: "right" }}
                  value="online"
                >
                  <StatusChip type={"attendance"} value={"online"} />
                </MenuItem>
                <MenuItem
                  sx={{ justifyContent: "right" }}
                  value="absent"
                >
                  <StatusChip type={"attendacne"} value={"absent"} />
                </MenuItem>
                <MenuItem
                  sx={{ justifyContent: "right" }}
                  value="waiting"
                >
                  <StatusChip  value={"wait"} />
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sx={{ width: "90%" }}>
              <TextareaAutosize
                style={{
                  padding: 10,
                  width: '630px',
                  minWidth: '200px',
                  fontSize: "1.0rem",
                  fontFamily: `'Roboto',sans-serif`,
                }}
                minRows={5}
                placeholder="출결메모 : 10분 늦음"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleSave}
                variant="contained"
                color="save"
                sx={{
                  borderRadius: 0,
                  width: 140,
                  height: 60,
                  fontSize: "1.5rem",
                }}
              >
                저장하기
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}

export default AttendenceMemo;

/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import {
  Typography,
  Grid,
  Divider,
  Tabs,
  Tab,
  Box,
  Modal,
  Avatar,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Button,
  IconButton,
  FormControl,
  Snackbar,
  Alert,
  InputAdornment,
  Switch,
  Checkbox,
  Menu,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { v4 as uuidv4 } from "uuid";
import { TabContext, TabPanel } from "@mui/lab";
import {
  IconCloudUpload,
  // IconDotsVertical,
  // IconEye,
  IconSearch,
  // IconUpload,
  IconUser,
  // IconTrash,
  IconPlus,
} from "@tabler/icons";
import StatusChip from "ui-component/StatusChip";
import { common, grey } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  DragIndicator,
  SquareRounded,
  Delete,
  ExpandMore,
} from "@mui/icons-material";
import {
  CreateClassroom,
  UpdateClassroom,
  GetLocations,
  GetLocation,
  GetClassroomSchedule,
  CreateClassroomSchedule,
} from "data/ClassroomDataAPI";
import { GetTeachers, GetTeacher } from "data/TeacherDataAPI";
import { useEffect, useState } from "react";
import { GetImages, UploadImage, DeleteImage } from "data/ImageDataAPI";
import { useDropzone } from "react-dropzone";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import MenuButton from "ui-component/buttons/MenuButton";
import { GetHolidays } from "data/CalandarDataAPI";
import CSCalendar from "ui-component/CSCalendar";
import { ref } from "yup";

const ClassroomInfo = React.forwardRef((props, ref) => {
  //teachers 변수에 Promise함수인 GetTeachers함수를 사용하여 선생님들 불러오기.
  const [teachers, setTeachers] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    GetTeachers().then((res) => {
      setTeachers(res);
    });
  }, []);

  return (
    <Grid container spacing={3} sx={{ overflow: "auto", maxHeight: 620 }}>
      <Grid item xs={12}>
        <TextField
          label="제목"
          variant="filled"
          defaultValue={props.formData.ClassroomTitle}
          color=""
          sx={{ "& label": { fontSize: "1rem" } }}
          InputProps={{ style: { fontSize: "1.0rem" } }}
          fullWidth
          onChange={(e) => {
            props.handleChange("ClassroomTitle", e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          variant="filled"
          sx={{
            textAlign: "right",
            width: "100%",
            height: "100%",
            "& .MuiSelect-select": {
              paddingLeft: "12px",
            },
          }}
        >
          <InputLabel id="ManagerLabel" sx={{ fontSize: "1rem" }}>
            관리자
          </InputLabel>
          <Select
            labelId="Manager_label"
            id="Manager"
            onChange={(e) => {
              props.handleChange("Manager", e.target.value);
              props.handleChange("ManagerId", e.target.value.UserId);
            }}
            value={props.formData.Manager}
            sx={{
              height: "100%",
              "& .MuiMenu-list": {
                width: "40%",
              },
            }}
            renderValue={(value) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    marginRight: 20,
                    gap: 10,
                  }}
                >
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: common.white,
                      color: grey[600],
                      border: "1px solid",
                    }}
                  >
                    <IconUser size={36} />
                  </Avatar>
                  {value.DisplayName}
                </div>
              );
            }}
          >
            <TextField
              variant="outlined"
              placeholder="찾기"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch stroke={1.5} size="1.5rem" color={grey[500]} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              fullWidth
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onBlur={(e) => {
                e.preventDefault();
                e.target.focus();
              }}
            />
            {teachers
              .filter((teacher) => {
                //영어검색과 한글 검색이 모두 되어야 하고, searchValue가 포함된 값은 true, searchValue 가 공백일 경우는 전체를 보여줌.
                if (
                  teacher.DisplayName.toLowerCase().includes(
                    searchValue.toLowerCase()
                  ) ||
                  teacher.DisplayName.includes(searchValue) ||
                  searchValue === ""
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              .map((teacher, index) => {
                return (
                  <MenuItem
                    sx={{ justifyContent: "left" }}
                    value={teacher}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 10,
                      }}
                    >
                      <Avatar
                        variant="rounded"
                        sx={{
                          width: "40px",
                          height: "40px",
                          backgroundColor: common.white,
                          color: grey[600],
                          border: "1px solid",
                        }}
                      >
                        <IconUser size={36} />
                      </Avatar>
                      {teacher.DisplayName}
                    </div>
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          variant="filled"
          sx={{
            textAlign: "right",
            width: "100%",
            height: "100%",
            "& .MuiSelect-select": {
              paddingLeft: "12px",
            },
          }}
        >
          <InputLabel id="StatusLabel" sx={{ fontSize: "1rem" }}>
            상태
          </InputLabel>
          <Select
            labelId="Status_label"
            id="Status"
            onChange={(e) => {
              props.handleChange("Status", e.target.value);
            }}
            value={props.formData.Status}
            sx={{ height: "100%" }}
            renderValue={(value) => (
              <StatusChip type={"program"} value={value} />
            )}
          >
            <MenuItem sx={{ justifyContent: "right" }} value="on">
              <StatusChip type={"program"} value={"on"} />
            </MenuItem>
            <MenuItem sx={{ justifyContent: "right" }} value="waiting">
              <StatusChip type={"program"} value={"waiting"} />
            </MenuItem>
            <MenuItem sx={{ justifyContent: "right" }} value="off">
              <StatusChip type={"program"} value={"off"} />
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TextareaAutosize
            style={{
              padding: 10,
              width: "630px",
              minWidth: "200px",
              fontSize: "1.0rem",
              fontFamily: `'Roboto',sans-serif`,
            }}
            placeholder="교실 간단 메모 교실 운영 히스토리, 관리샘 히스토리 등을 기록합니다"
            minRows={5}
            defaultValue={props.formData.ContentPage}
            onChange={(e) => {
              props.handleChange("ContentPage", e.target.value);
            }}
          ></TextareaAutosize>
        </Box>
      </Grid>
    </Grid>
  );
});

const ClassroomDetail = React.forwardRef((props, ref) => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    GetLocations().then((res) => {
      setLocations(res);
    });
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <InputLabel id="StatusLabel" sx={{ fontSize: "1rem" }}>
          교실 위치
        </InputLabel>
        <Select
          labelId="Manager_label"
          id="Manager"
          onChange={(e) => {
            props.handleChange("Location", e.target.value);
            props.handleChange("LocationId", e.target.value.ID);
          }}
          value={props.formData.Location}
          sx={{
            width: "100%",
            "& .MuiMenu-list": {
              width: "40%",
            },
          }}
          renderValue={(value) => {
            return (
              <Typography sx={{ fontSize: "1rem" }}>
                {value.LocationTitle}
              </Typography>
            );
          }}
        >
          {locations.map((location, index) => {
            return (
              <MenuItem
                sx={{ justifyContent: "left" }}
                value={location}
                key={index}
              >
                <Typography sx={{ fontSize: "1rem" }}>
                  {location.LocationTitle}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    </Grid>
  );
});

const Image = React.forwardRef((props, ref) => {
  const [images, setImages] = useState(props.formData.Images || []);
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: "image/*",
    onDragOver: () => {},
    onDrop: async (acceptedFiles) => {
      await Promise.all(
        acceptedFiles.map(async (file) => {
          await UploadImage(file, props.formData.GuidId);
        })
      );
      const res = await GetImages(props.formData.GuidId);
      setImages(res);
    },
  });

  useEffect(() => {
    GetImages(props.formData.GuidId).then((res) => {
      if (res === null) res = [];
      else if (res.length === undefined) {
        const temp = [];
        temp.push(res);
        res = temp;
      }
      setImages(res);
    });
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          {...getRootProps()}
          fullWidth
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 3,
            backgroundColor: isDragActive
              ? "rgba(0,0,0,0.2)"
              : "rgba(0,0,0,0.05)",
            padding: 5,
          }}
          onClick={() => {
            open();
          }}
        >
          <input {...getInputProps()} />
          <IconCloudUpload size={36} />
          <Typography sx={{ fontSize: "1rem" }}>
            Drag and drop or Browse to upload
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
            height: 400,
            overflowY: "auto",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 3,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ padding: "0 17px" }} />
            <Typography sx={{ fontSize: "1rem" }}>이미지</Typography>
            <Typography sx={{ fontSize: "1rem" }}>대표</Typography>
            <div />
          </Box>
          {images.map((image, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                border={1}
                padding={2}
                draggable
              >
                <DragIndicator />
                <img
                  src={
                    process.env.REACT_APP_API_URL +
                    "/image/?filepath=" +
                    props.formData.GuidId +
                    "/" +
                    image
                  }
                  alt={
                    process.env.REACT_APP_API_URL +
                    "/image/?filepath=" +
                    props.formData.GuidId +
                    "/" +
                    image
                  }
                  style={{ width: "30%" }}
                />
                <Checkbox
                  sx={{ "&:checked": { bgcolor: "#3a63de" } }}
                  disableRipple
                  checkedIcon={<SquareRounded color={common.white} />}
                />

                <IconButton
                  onClick={async () => {
                    await DeleteImage(props.formData.GuidId + "/" + image);
                    const res = await GetImages(props.formData.GuidId);
                    setImages(res);
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
});

const ClassDate = React.forwardRef((props, ref) => {
  const [dateTab, setDateTab] = useState("반복지정");
  const schedule = props.schedule;
  const setSchedule = props.setSchedule;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [currentYear, setCurrentYear] = useState(props.formData.StartAt.$y);
  const [currentMonth, setCurrentMonth] = useState(props.formData.StartAt.$M);
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  const dayref = useGridApiRef();
  const [holidays, setHolidays] = React.useState([]);

  const addCurrentMonth = () => {
    setCurrentMonth((prev) => {
      if (prev === 11) {
        setCurrentYear((prev) => prev + 1);
        setCurrentMonth(0);
        return 0;
      } else {
        return prev + 1;
      }
    });
  };
  const subCurrentMonth = () => {
    setCurrentMonth((prev) => {
      if (prev === 0) {
        setCurrentYear((prev) => prev - 1);
        setCurrentMonth(11);
        return 11;
      } else {
        return prev - 1;
      }
    });
  };

  const reload = async () => {
    await GetHolidays(currentYear).then((res) => {
      setHolidays(res);
    });
  };

  const handleDayClick = (d) => {
    if (schedule.some((s) => s.id === d)) {
      const temp = [];
      schedule.forEach((s) => {
        if (s.id !== d) {
          temp.push(s);
        }
      });
      setSchedule(temp);
    } else {
      const temp_schedule = {
        TheDate: d,
        TheStartTime: dayjs(
          d.format("YYYY-MM-DD") +
            " " +
            props.formData.StartTime?.format("HH:mm")
        ),
        TheEndTime: dayjs(
          d.format("YYYY-MM-DD") + " " + props.formData.EndTime?.format("HH:mm")
        ),
        Manager: props.formData.Manager,
        ManagerId: props.formData.ManagerId,
        Weekday: d.format("ddd").toUpperCase(),
        id: d,
      };
      setSchedule((prev) => [...prev, temp_schedule]);
    }
  };


  const handleDeleteChecked = () => {
    const temp = [];
    schedule.forEach((s) => {
      if (!selectedSchedule.includes(s.id)) {
        temp.push(s);
      }
    });
    setSchedule(temp);
  };

  useEffect(() => {
    reload();
  }, [currentYear]);

  const menuOpen = Boolean(menuAnchorEl);

  const handleOpenMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const submitInput = () => {
    if (schedule.length === 0) {
      if (
        props.formData.StartAt &&
        props.formData.EndAt &&
        props.formData.StartTime &&
        props.formData.EndTime &&
        props.formData.Weekday
      ) {
        const weekday_list = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        let start_date = dayjs(props.formData.StartAt);
        if (start_date.day() < weekday_list.indexOf(props.formData.Weekday)) {
          start_date = start_date.add(
            7 - start_date.day() + weekday_list.indexOf(props.formData.Weekday),
            "day"
          );
        } else {
          start_date = start_date.add(
            weekday_list.indexOf(props.formData.Weekday) - start_date.day(),
            "day"
          );
        }
        for (
          let i = start_date;
          i <= props.formData.EndAt;
          i = i.add(7, "day")
        ) {
          const temp_schedule = {
            TheDate: i,
            TheStartTime: dayjs(
              i.format("YYYY-MM-DD") +
                " " +
                props.formData.StartTime?.format("HH:mm")
            ),
            TheEndTime: dayjs(
              i.format("YYYY-MM-DD") +
                " " +
                props.formData.EndTime?.format("HH:mm")
            ),
            Manager: props.formData.Manager,
            ManagerId: props.formData.ManagerId,
            Weekday: i.format("ddd").toUpperCase(),
            id: i,
          };
          setSchedule((prev) => [...prev, temp_schedule]);
        }
      } else {
        if (!props.formData.StartAt) {
          alert("시작 날짜를 입력해주세요");
        }
        if (!props.formData.EndAt) {
          alert("종료 날짜를 입력해주세요");
        }
        if (!props.formData.StartTime) {
          alert("시작 시간을 입력해주세요");
        }
        if (!props.formData.EndTime) {
          alert("종료 시간을 입력해주세요");
        }
        if (!props.formData.Weekday) {
          alert("요일을 입력해주세요");
        }
      }
    }
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  function RenderManager(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconUser size={36} />
        </Avatar>
        {props.value?.DisplayName}
      </div>
    );
  }

  function RenderMenu(props) {
    return <MenuButton {...props} />;
  }

  function RenderMenuHeader(props) {
    return (
      <div>
        <IconButton
          onClick={(e) => {
            handleOpenMenu(e);
          }}
        >
          <ExpandMore />
        </IconButton>
        <Menu
          id="hamburger-menu"
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={menuOpen}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
        >
          <MenuItem
            disabled={selectedSchedule.length === 0}
            onClick={handleCloseMenu}
          >{`(선택) 관리샘 변경`}</MenuItem>
          <Divider />
          <MenuItem
            disabled={selectedSchedule.length === 0}
            onClick={() => {
              handleDeleteChecked();
              handleCloseMenu();
            }}
          >{`(선택) 날짜 삭제`}</MenuItem>
        </Menu>
      </div>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TabContext value={dateTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={dateTab}
              onChange={(e, newValue) => {
                setDateTab(newValue);
              }}
              aria-label="Tab Grids"
            >
              <Tab
                label="반복지정"
                value="반복지정"
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  padding: 3,
                }}
              />
              <Tab
                label="달력지정"
                value="달력지정"
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  padding: 3,
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value="반복지정">
            <Grid container spacing={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item xs={6}>
                  <DatePicker
                    label={"시작 날짜"}
                    value={props.formData.StartAt}
                    onChange={(e) => {
                      props.handleChange("StartAt", e);
                    }}
                    format="YYYY-MM-DD"
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": {
                        textAlign: "left",
                        fontSize: "1.0rem",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label={"종료 날짜"}
                    value={props.formData.EndAt}
                    onChange={(e) => {
                      props.handleChange("EndAt", e);
                    }}
                    format="YYYY-MM-DD"
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": {
                        textAlign: "left",
                        fontSize: "1.0rem",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    label={"시작 시간"}
                    value={props.formData.StartTime}
                    onChange={(e) => {
                      props.handleChange("StartTime", e);
                    }}
                    format="HH:mm"
                    ampm={false}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": {
                        textAlign: "left",
                        fontSize: "1.0rem",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    label={"종료 시간"}
                    value={props.formData.EndTime}
                    onChange={(e) => {
                      props.handleChange("EndTime", e);
                    }}
                    format="HH:mm"
                    ampm={false}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": {
                        textAlign: "left",
                        fontSize: "1.0rem",
                      },
                    }}
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={6}>
                <FormControl
                  variant="filled"
                  sx={{
                    textAlign: "left",
                    width: "100%",
                    height: "100%",
                    "& .MuiSelect-select": {
                      paddingLeft: "12px",
                    },
                  }}
                >
                  <InputLabel id="WeekdayLabel" sx={{ fontSize: "1rem" }}>
                    요일
                  </InputLabel>

                  <Select
                    labelId="Weekday_label"
                    id="Weekday"
                    onChange={(e) => {
                      props.handleChange("Weekday", e.target.value);
                    }}
                    value={props.formData.Weekday}
                    sx={{ height: "100%", fontSize: "1.0rem" }}
                  >
                    <MenuItem sx={{ justifyContent: "right" }} value="MON">
                      월요일
                    </MenuItem>
                    <MenuItem sx={{ justifyContent: "right" }} value="TUE">
                      화요일
                    </MenuItem>
                    <MenuItem sx={{ justifyContent: "right" }} value="WED">
                      수요일
                    </MenuItem>
                    <MenuItem sx={{ justifyContent: "right" }} value="THU">
                      목요일
                    </MenuItem>
                    <MenuItem sx={{ justifyContent: "right" }} value="FRI">
                      금요일
                    </MenuItem>
                    <MenuItem sx={{ justifyContent: "right" }} value="SAT">
                      토요일
                    </MenuItem>
                    <MenuItem sx={{ justifyContent: "right" }} value="SUN">
                      일요일
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}></Grid>
              <Grid item xs={9} sx={{ position: "relative" }}>
                <TextField
                  label="반복 주기"
                  variant="filled"
                  value={"매주 반복"}
                  color=""
                  readOnly
                  sx={{ "& label": { fontSize: "1rem" } }}
                  InputProps={{ style: { fontSize: "1.0rem" } }}
                  fullWidth
                />
                <Switch
                  sx={{
                    marginLeft: "auto",
                    position: "absolute", // set the position to absolute
                    top: "30%", // adjust the top value to position the switch
                    right: 15,
                    zIndex: 1,
                    width: "80px",
                    height: 50,
                    "& .MuiSwitch-switchBase": {
                      margin: "14px",
                      padding: 0,
                      transform: "translateX(6px)",
                      "&.Mui-checked": {
                        transform: "translateX(25px)",
                        color: "#fff",
                        "& + .MuiSwitch-track": {
                          opacity: 1,
                          backgroundColor: "#3a63de",
                        },
                      },
                    },
                    "& .MuiSwitch-thumb": {
                      width: 25,
                      height: 25,
                      borderRadius: 2,
                    },
                    "& .MuiSwitch-track": {
                      borderRadius: 2,
                      height: 30,
                      opacity: 1,
                      backgroundColor: grey[400],
                    },
                  }}
                  defaultChecked
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="save"
                  sx={{
                    borderRadius: 0,
                    width: 140,
                    height: 60,
                    fontSize: "1rem",
                  }}
                  onClick={submitInput}
                >
                  <div
                    style={{ display: "flex", gap: 20, alignItems: "center" }}
                  >
                    <IconPlus size={30} />
                    추가
                  </div>
                </Button>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel
            currentMonth={currentMonth}
            value="달력지정"
            sx={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              justifyContent: "cetner",
            }}
          >
            <CSCalendar
              holidays={holidays}
              year={currentYear}
              month={currentMonth + 1}
              addCurrentMonth={addCurrentMonth}
              subCurrentMonth={subCurrentMonth}
              selected={schedule}
              handleDayClick={handleDayClick}
              reload={reload}
            />
            <Button
              variant="contained"
              color="save"
              sx={{
                borderRadius: 0,
                width: 140,
                height: 60,
                fontSize: "1rem",
              }}
            >
              <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                <IconPlus size={30} />
                추가
              </div>
            </Button>
          </TabPanel>
        </TabContext>
      </Grid>
      <Grid item xs={6}>
        <DataGridPro
          rows={schedule}
          checkboxSelection
          disableRowSelectionOnClick
          apiRef={dayref}
          onRowSelectionModelChange={(e) => {
            setSelectedSchedule(e);
          }}
          columns={[
            {
              field: "TheDate",
              headerName: "수업날짜",
              renderCell: (params) => {
                return params.value
                  ? dayjs(params.value).format("YYYY-MM-DD")
                  : null;
              },
              flex: 1,
              headerAlign: "center",
              align: "center",
            },
            {
              field: "TimeDuration",
              headerName: "시간",
              flex: 1,
              headerAlign: "center",
              align: "center",
              renderCell: (params) => {
                return (
                  <Typography sx={{ fontSize: "1rem" }}>
                    {dayjs(params.row.TheStartTime).format("HH:mm")}~
                    {dayjs(params.row.TheEndTime).format("HH:mm")}
                  </Typography>
                );
              },
            },
            {
              field: "Manager",
              headerName: "관리자",
              flex: 1,
              headerAlign: "center",
              align: "center",
              renderCell: RenderManager,
            },
            {
              field: "menu",
              headerName: "",
              renderHeader: RenderMenuHeader,
              width: 60,
              headerAlign: "center",
              align: "center",
              renderCell: RenderMenu,
              disableColumnMenu: true,
              sortable: false,
            },
          ]}
          sx={{
            fontSize: "1rem",
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              px: "1px !important",
            },
            height: 600,
            overflowY: "auto",
          }}
          localeText={{
            noRowsLabel: "데이터가 없습니다",
          }}
        />
      </Grid>
    </Grid>
  );
});

const TabList = [
  {
    label: "기본정보",
    component: (props) => {
      return <ClassroomInfo {...props} onClose={props.onClose} />;
    },
  },
  {
    label: "상세정보",
    component: (props) => {
      return <ClassroomDetail {...props} onClose={props.onClose} />;
    },
  },
  {
    label: "이미지",
    component: (props) => {
      return <Image {...props} />;
    },
  },
  {
    label: "수업날짜",
    component: (props) => {
      return <ClassDate {...props} />;
    },
  },
];

const CRUDClassroom = React.forwardRef((props, ref) => {
  const [schedule, setSchedule] = useState([]);
  const [formData, setFormData] = useState({
    GuidId: props.GuidId || uuidv4(),
    CenterId: 1000001, // TODO: User's CenterId
    ID: Number(props.id) || null,
    ClassroomExams: props.ClassroomExams || "",
    ClassroomTitle: props.ClassroomTitle || "",
    ManagerId: props.ManagerId || 0,
    LocationId: props.LocationId || 0,
    Subject: props.Subject || "",
    ContentPage: props.ContentPage || "",
    Status: props.Status || "waiting",
    CreatedAt: dayjs(props.CreatedAt) || dayjs(),
    StartAt: props.StartAt
      ? dayjs(props.StartAt)
      : dayjs(dayjs().year() + "03-02"),
    EndAt: props.EndAt
      ? dayjs(props.EndAt)
      : dayjs(dayjs().add(1, "year").year() + "02-28"),
    StartTime: props.StartTime //2023-01-01은 의미 없고 StartTime 입력을 위한 DATETIME 형식 변환을 위한 임시 날짜 값
      ? dayjs("2023-01-01" + props.StartTime)
      : dayjs("2023-01-01 00:00"),
    EndTime: props.EndTime
      ? dayjs("2023-01-01" + props.EndTime)
      : dayjs("2023-01-01 00:00"),
    Weekday: props.weekday || "",
  });

  useEffect(() => {
    GetClassroomSchedule(props.id).then((res) => {
      setSchedule(res);
    });
  }, []);

  useEffect(() => {
    if (formData.ManagerId !== 0) {
      GetTeacher(formData.ManagerId).then((res) => {
        setFormData((prevData) => ({
          ...prevData,
          Manager: res,
        }));
      });
    }
    if (formData.LocationId !== 0) {
      GetLocation(formData.LocationId).then((res) => {
        setFormData((prevData) => ({
          ...prevData,
          Location: res,
        }));
      });
    }
  }, [formData.ManagerId, formData.LocationId]);

  function formInit() {
    setFormData({
      CenterId: 1000001, // TODO: User's CenterId
      GuidId: props.GuidId || uuidv4(),
      ClassroomId: Number(props.programId),
      ClassroomExams: props.ClassroomExams || "",
      ClassroomTitle: props.ClassroomTitle || "",
      ManagerId: props.ManagerId || 0,
      LocationId: props.LocationId || 0,
      Subject: props.Subject || "",
      ContentPage: props.ContentPage || "",
      Status: props.Status || "waiting",
      StartAt: props.StartAt
        ? dayjs(props.StartAt)
        : dayjs(dayjs().year() + "03-02"),
      EndAt: props.EndAt
        ? dayjs(props.EndAt)
        : dayjs(dayjs().add(1, "year").year() + "02-28"),
      StartTime: props.StartTime
        ? dayjs("2023-01-01 " + props.StartTime)
        : dayjs("2023-01-01 00:00"),
      EndTime: props.EndTime
        ? dayjs("2023-01-01 " + props.EndTime)
        : dayjs("2023-01-01 00:00"),
      Weekday: props.Weekday ? props.Weekday : props.weekday,
    });
  }

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [value, setValue] = useState(TabList[0].label);
  const [errorField, setErrorField] = useState("");
  const errorOpen = Boolean(errorField);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: value === "수업날짜" ? 1300 : 600,
    height: 800,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleError = (field) => {
  //   setErrorField(field);
  // };

  const handleErrorClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorField("");
  };

  // function checkForm() {
  //   for (const key in formData) {
  //     if (formData[key] == "") {
  //       handleError(key);
  //       return 0;
  //     }
  //   }
  //   return 1;
  // }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={(e) => {
          props.onClose();
          formInit();
        }}
      >
        <TabContext value={value}>
          <Box sx={style}>
            <div style={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="Tab Grids"
                >
                  {TabList.map((tab, index) => {
                    return (
                      <Tab
                        key={"Table" + String(index)}
                        label={tab.label}
                        value={tab.label}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          padding: 3,
                        }}
                      />
                    );
                  })}
                </Tabs>
              </Box>
              {TabList.map((tab, index) => {
                return (
                  <TabPanel value={tab.label} key={"Table" + String(index)}>
                    <tab.component
                      handleChange={handleInputChange}
                      formData={formData}
                      onClose={props.onClose}
                      weekday={props.weekday}
                      schedule={schedule}
                      setSchedule={setSchedule}
                    />
                  </TabPanel>
                );
              })}
            </div>
            <Grid container spacing={3} sx={{ padding: 3 }}>
              <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
                <Button
                  onClick={() => {
                    if (props.id) {
                      UpdateClassroom({
                        ID: props.id,
                        GuidId: formData.GuidId,
                        ClassroomExams: formData.ClassroomExams,
                        ClassroomId: formData.ClassroomId,
                        ClassroomTitle: formData.ClassroomTitle,
                        CenterId: formData.CenterId,
                        ContentPage: formData.ContentPage,
                        Status: formData.Status,
                        StartAt: formData.StartAt,
                        EndAt: formData.EndAt,
                        StartTime:
                          String(formData.StartTime.$H).padStart(2, "0") +
                          String(formData.StartTime.$m).padStart(2, "0"),
                        EndTime:
                          String(formData.EndTime.$H).padStart(2, "0") +
                          String(formData.EndTime.$m).padStart(2, "0"),
                        Subject: formData.Subject,
                        ManagerId: formData.ManagerId,
                        LocationId: formData.LocationId,
                        Weekday: formData.Weekday,
                      });
                    } else {
                      CreateClassroom({
                        GuidId: formData.GuidId,
                        ClassroomExams: formData.ClassroomExams,
                        ClassroomId: formData.ClassroomId,
                        ClassroomTitle: formData.ClassroomTitle,
                        CenterId: formData.CenterId,
                        ContentPage: formData.ContentPage,
                        Status: formData.Status,
                        CreatedAt: dayjs(props.CreatedAt) || dayjs(),
                        StartAt: formData.StartAt,
                        EndAt: formData.EndAt,
                        StartTime:
                          String(formData.StartTime.$H).padStart(2, "0") +
                          String(formData.StartTime.$m).padStart(2, "0"),
                        EndTime:
                          String(formData.EndTime.$H).padStart(2, "0") +
                          String(formData.EndTime.$m).padStart(2, "0"),
                        Subject: formData.Subject,
                        ManagerId: formData.ManagerId,
                        LocationId: formData.LocationId,
                        Weekday: formData.Weekday,
                      }).then((res) => {
                        schedule.forEach(async (schedule) => {
                          await CreateClassroomSchedule({
                            ClassroomId: res.ID,
                            TheDate: schedule.TheDate,
                            TheStartTime: schedule.TheStartTime,
                            TheEndTime: schedule.TheEndTime,
                            Weekday: schedule.Weekday,
                            YearName: schedule.TheDate.$y,
                          });
                        });
                      });
                    }
                    props.handleSave();
                  }}
                  variant="contained"
                  color="save"
                  sx={{
                    borderRadius: 0,
                    width: 140,
                    height: 60,
                    fontSize: "1.5rem",
                  }}
                >
                  저장
                </Button>
                <Button
                  onClick={() => {
                    GetHolidays(2023);
                    props.onClose();
                    formInit();
                  }}
                  variant="contained"
                  color="cancel"
                  sx={{
                    borderRadius: 0,
                    width: 140,
                    height: 60,
                    fontSize: "1.5rem",
                  }}
                >
                  취소
                </Button>
              </Grid>
            </Grid>
            <Snackbar
              open={errorOpen}
              autoHideDuration={1800}
              onClose={handleErrorClose}
            >
              <Alert onClose={handleErrorClose} severity="error">
                Please Enter {errorField} field
              </Alert>
            </Snackbar>
          </Box>
        </TabContext>
      </Modal>
    </div>
  );
});

export default CRUDClassroom;

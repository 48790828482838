// material-ui
import { Typography, Grid, Button, Box } from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";
import CSCalendar from "ui-component/CSCalendar";
import dayjs from "dayjs";
import React from "react";

import { GetHolidays } from "data/CalandarDataAPI";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

// ==============================|| SAMPLE PAGE ||============================== //

function SamplePage() {
  const [year, setYear] = React.useState(dayjs().year());
  const [holidays, setHolidays] = React.useState([]);
  

  React.useEffect(() => {
    GetHolidays(year).then((res) => {
      setHolidays(res);
      console.log('call from reload');
    });
  }, [year]);

  const reload = async () => {
    await GetHolidays(year).then((res) => {
      setHolidays(res);
    });
  };

  return (
    <MainCard title="달력" sx={{ overflow: "auto" }}>
      <Grid
        container
        spacing={1}
        sx={{ width: "100%", height: "100%", minWidth: 1000 }}
      >
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={()=>{setYear(year-1);}}>
              <KeyboardArrowLeft />
            </Button>
            <Typography variant="h3" sx={{ textAlign: "center", flex: 1 }}>
              {year}
            </Typography>
            <Button onClick={()=>{setYear(year+1)}}>
              <KeyboardArrowRight />
            </Button>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <CSCalendar
            year={year}
            month={"01"}
            holidays={holidays}
            reload={reload}
          />
        </Grid>
        <Grid item xs={4}>
          <CSCalendar
            year={year}
            month={"02"}
            holidays={holidays}
            reload={reload}
          />
        </Grid>
        <Grid item xs={4}>
          <CSCalendar
            year={year}
            month={"03"}
            holidays={holidays}
            reload={reload}
          />
        </Grid>
        <Grid item xs={4}>
          <CSCalendar
            year={year}
            month={"04"}
            holidays={holidays}
            reload={reload}
          />
        </Grid>
        <Grid item xs={4}>
          <CSCalendar
            year={year}
            month={"05"}
            holidays={holidays}
            reload={reload}
          />
        </Grid>
        <Grid item xs={4}>
          <CSCalendar
            year={year}
            month={"06"}
            holidays={holidays}
            reload={reload}
          />
        </Grid>
        <Grid item xs={4}>
          <CSCalendar
            year={year}
            month={"07"}
            holidays={holidays}
            reload={reload}
          />
        </Grid>
        <Grid item xs={4}>
          <CSCalendar
            year={year}
            month={"08"}
            holidays={holidays}
            reload={reload}
          />
        </Grid>
        <Grid item xs={4}>
          <CSCalendar
            year={year}
            month={"09"}
            holidays={holidays}
            reload={reload}
          />
        </Grid>
        <Grid item xs={4}>
          <CSCalendar
            year={year}
            month={"10"}
            holidays={holidays}
            reload={reload}
          />
        </Grid>
        <Grid item xs={4}>
          <CSCalendar
            year={year}
            month={"11"}
            holidays={holidays}
            reload={reload}
          />
        </Grid>
        <Grid item xs={4}>
          <CSCalendar
            year={year}
            month={"12"}
            holidays={holidays}
            reload={reload}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
}


export default SamplePage;

import StatusChip from "ui-component/StatusChip";
import AttendenceChip from "ui-component/AttendenceChip";
import * as React from "react";
import { IconBrandPython, IconFileDescription, IconUser } from "@tabler/icons";
import {
  Avatar,
  Button,
  Divider,
  Box,
  ButtonBase,
  Card,
  InputAdornment,
  OutlinedInput,
  Typography,
  Popper,
  Input,
  Checkbox,
} from "@mui/material";
import { Favorite, Person } from "@mui/icons-material";

import { red, grey, common } from "@mui/material/colors";
import ClassStudent from "ui-component/buttons/ClassStudent";
import IconButton from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconDotsVertical } from "@tabler/icons";
import { forwardRef } from "react";
import {
  GridToolbarQuickFilter,
  DataGridPro,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";

// material-ui
import { useTheme, styled } from "@mui/material/styles";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { alpha, shouldForwardProp } from "@mui/system";
import { useRef } from "react";
import { GetThisMonthStudentSchedules } from "data/ClassroomDataAPI";


export default function O_CourseProgress(props) {
  const [progressdata, setProgressData] = useState([]);
  
  React.useEffect(() => {
    GetThisMonthStudentSchedules(0).then((data) => {
      setProgressData(data);
    });
  }, []);
  const CustomQuickFilter = styled(GridToolbarQuickFilter, {
    shouldForwardProp,
  })(({ theme }) => ({
    width: "100%",
    fontSize: "1.0rem",
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
      height: "29px",
      fontSize: "1.0rem",
      "&::placeholder": {
        fontSize: "1.0rem",
        color: grey[800],
      },
    },
    [theme.breakpoints.down("lg")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background: "#fff",
    },
  }));

  const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
    ({ theme }) => ({
      ...theme.typography.commonAvatar,
      ...theme.typography.mediumAvatar,
      background: theme.palette.grey[100],
      color: theme.palette.grey[500],
      "&:hover": {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light,
      },
    })
  );

  const handleXClick = (e, index, id) => {
    const list = props.selectedRow.filter((_, i) => i !== index);
    props.setSelectedRow(list);
    props.apiRef.current.selectRow(id, false);
  };

  function SelectedStudents(props) {
    return (
      <Box
        key={`student${props.index}`}
        sx={{
          borderRadius: "2px",
          backgroundColor: "#e3e6eb",
          padding: "2px",
          width: "114px",
          height: "45px",
        }}
      >
        {props.DisplayName +
          "(" +
          (props.GradeLevel ) +
            "," +
            (props.Gender === "M" ? "남" : "여") +
            ")"}
        <ButtonBase
          id={`xButton${props.index}`}
          onClick={(e) => handleXClick(e, props.index, props.id)}
          sx={{ marginBottom: 3, width: 14, height: 14, zIndex: 1000 }}
        >
          <IconX style={{ zIndex: 800 }} />
        </ButtonBase>
      </Box>
    );
  }

  const SearchSection = () => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <>
        <div style={{ display: "flex", gap: 10 }}>
          <Box sx={{ display: { xs: "none", md: "block" }, flex: 1 }}>
            <CustomQuickFilter
              variant="outlined"
              placeholder="수업진행 검색"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch
                      stroke={1.5}
                      size="1.5rem"
                      color={theme.palette.grey[500]}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ heigth: "60px" }}>
                    <ButtonBase sx={{ borderRadius: "12px" }}>
                      <HeaderAvatarStyle variant="rounded">
                        <IconAdjustmentsHorizontal stroke={1.5} size="1.0rem" />
                      </HeaderAvatarStyle>
                    </ButtonBase>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ButtonBase
            onClick={handleClick}
            sx={{ height: "60px", width: "100px", backgroundColor: "#f2f5fa" }}
          >
            <Typography fontSize={"1rem"}>Actions</Typography>
            <ArrowDropDown sx={{ marginLeft: "10px" }} />
          </ButtonBase>
          <Menu
            id="hamburger-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "150px", // 원하는 너비로 설정,
                fontSize: "1.7rem",
              },
            }}
            sx={{
              "& .MuiMenuItem-root": {
                fontSize: "1rem",
              },
            }}
            MenuListProps={{
              "aria-labelledby": "hamburger-button",
            }}
          >
            <MenuItem onClick={handleClose}>프로그램 수강등록</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>교실배정 변경</MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>메시지 발송</MenuItem>
          </Menu>
        </div>
        {/* Selected Students */}
        <div
          style={{
            overflowX: "auto",
            backgroundColor: alpha("#009eb2", 0.14),
            display: "flex",
          }}
        >
          {props.selectedRow?.length>0 && (
            <>
              <Box
                sx={{
                  borderLeft: `5px solid #009eb2`,

                  height: 60,
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <div></div>
                {props.selectedRow.map((row, index) => {
                  return (
                    <div key={`student${index}`}>
                      <SelectedStudents {...row} index={index} />
                    </div>
                  );
                })}
              </Box>
              <Box sx={{ width: 0 }}>{"ㅤ"}</Box>
            </>
          )}
        </div>
      </>
    );
  };

  function RenderStatus(props) {
    return <StatusChip type ={'attendance'} value={props.value} />;
  }

  function RenderStudentName(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconUser size={36} />
        </Avatar>
        {props.row.DisplayName +
          "(" +
      props.row.GradeLevel+
          "," + (props.row.Gender ==='M' ? '남' : '여') + ")"}
      </div>
    );
  }

  function RenderHasMessage(props) {
    if (props.value === "true")
      return (
        <Avatar sx={{ bgcolor: "#fd726a", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
    else
      return (
        <Avatar sx={{ bgcolor: "#3a63de", width: "15px", height: "15px" }}>
          {" "}
        </Avatar>
      );
  }

  function RenderProgram(props) {
    if (props.value) {
      return (
        <>
          {" "}
          <Avatar src={props.row.programImg} variant="rounded" />
          {props.value}
        </>
      );
    } else
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              width: "40px",
              height: "40px",
              backgroundColor: common.white,
              color: grey[600],
              border: "1px solid",
            }}
          >
            <IconBrandPython size={36} />
          </Avatar>
          {"파이썬기본@2023"}
        </div>
      );
  }

  function RenderLectures(props) {
    return props.row.LectureLog;
  }
  function RenderClassroom(props) {
    return props.row.ClassroomTitle;
  }
  function RenderProgressType(props) {
    return <StatusChip type={'lecture_type'} value={props.value} />;// progress status로 변경 필요
  }

  function RenderTeacher(props) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: common.white,
            color: grey[600],
            border: "1px solid",
          }}
        >
          <IconUser size={36} />
        </Avatar>
        {'아토샘'}
      </div>
    );
  }

  function RenderProgressDate(props){
    return props.row.TheDate.split('-')[1]+'-'+props.row.TheDate.split('-')[2].split('T')[0];
  }

  function RenderNotice(props){
    return <div style={{display:'flex'}}><IconFileDescription/><Favorite sx={{width:24,height:24}}/></div>
  }

  const MenuButton = forwardRef((props, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [minianchorEl, setminiAnchorEl] = React.useState(null);
    const [attend, setAttend] = React.useState("attend");
    const chipLabel = (() => {
      switch (attend) {
        case "attend":
          return "출석";
        case "late":
          return "지각";
        case "leaveEarly":
          return "조퇴";
        case "online":
          return "온라인";
        case "absent":
          return "결석";
        case "wait":
          return "대기";
        default:
          return "출석";
      }
    })();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget.parentElement);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          aria-label="more"
          size="small"
          sx={{ width: "40px", padding: 0 }}
          id="hamburger-button"
          aria-controls={open ? "hamburger-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          disableRipple={true}
          onClick={handleClick}
        >
          <IconDotsVertical color={grey[800]} />
        </IconButton>
        <Menu
          id="hamburger-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "hamburger-button",
          }}
        >
          <MenuItem onClick={handleClose}>상세정보</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>보강/보충 추가</MenuItem>
        </Menu>
      </div>
    );
  });

  function RenderStudentMenu() {
    return <MenuButton />;
  }

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // Hide columns status and traderName, the other columns will remain visible
        id: false,
      },
    },
    pagination: {
      paginationModel: { pageSize: 25, page: 0 },
    },
  };

  function handleChange(item, e) {
    const selectedIds = new Set(item);
    const selectedRows = progressdata.filter((row) => selectedIds.has(row.id));
    props.setSelectedRow(selectedRows);
  }

  const columns = [
    {
      field: "id",
      hide: true,
      disableColumnMenu: true,
    },
    {
      field: "DisplayName",
      headerName: "이름",
      renderCell: RenderStudentName,
      minWidth: 180,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "Weekday",
      headerName: "요일",
      minWidth: 60,
      width: 60,
      renderCell : (params) => {
        switch(params.value){
          case 'SUN': return '일'
          case 'MON': return '월'
          case 'TUE': return '화'
          case 'WED': return '수'
          case 'THU': return '목'
          case 'FRI': return '금'
          case 'SAT': return '토'
          default: return ''
        }
      },
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "program",
      headerName: "프로그램",
      renderCell: RenderProgram,
      headerAlign: "center",
      minWidth: 220,
      flex: 1,
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "lectures",
      headerName: "진도단원",
      renderCell: RenderLectures,
      headerAlign: "center",
      minWidth: 80,
      flex: 1,
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "classroom",
      headerName: "교실",
      renderCell: RenderClassroom,
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "LectureType",
      renderCell: RenderProgressType,
      headerName: "종류",
      width: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "teacher",
      renderCell: RenderTeacher,
      headerName: "수업교사",
      width: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "TheDate",
      renderCell:RenderProgressDate,
      headerName: "날짜",
      width: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "notice",
      renderCell:RenderNotice,
      headerName: "표시",
      width: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "Attendance",
      headerName: "출결",
      width: 70,
      renderCell: RenderStatus,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "menu",
      headerName: "",
      width: 70,
      renderCell: RenderStudentMenu,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const course_progress_table = {
    rows: progressdata,
    columns: columns,
    initialState: initialState,
    disableRowSelectionOnClick: true,
    checkboxSelection: true,
    onRowSelectionModelChange: handleChange,
    slots: {
      BaseCheckbox: React.forwardRef((props, _) => {
        return <Checkbox {...props} />;
      }),
      toolbar: SearchSection,
    },
    sortModel: [
      {
        field: "TheDate",
        sort: "desc",
      },
      {
        field: "name",
        sort: "asc",
      }
    ],
    rowHeight: 90,
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <DataGridPro
         localeText={{
          noRowsLabel: '데이터가 없습니다',
        }}
          // experimentalFeatures={{ lazyLoading: true }}
          sx={{
            fontSize: "1rem",
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              px: "1px !important",
            },
          }}
          autoHeight
          {...course_progress_table}
          pagination
          apiRef={props.apiRef}
        />
      </div>
    </div>
  );
}

import * as React from 'react'
import { Paper, Typography,Checkbox, ButtonBase,Menu,MenuItem,Divider } from "@mui/material";
import { IconFileDescription, IconClockHour4 } from "@tabler/icons";
import { Favorite } from "@mui/icons-material";
import {
  blue,
  red,
  common,
  green,
  purple,
  yellow,
  grey,
} from "@mui/material/colors";
import { Box, alpha, bgcolor} from "@mui/system";
import CRUDProgressing from './modals/CRUDProgressing';
function ProgressCell(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [processAnchorEl, setProcessAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const processOpen = Boolean(processAnchorEl);

  const handleProcess = (e)=>{
    setProcessAnchorEl(e.currentTarget)
  }
  const handleCloseProcess = ()=>{
    setProcessAnchorEl(null)
  }

  function CellColor(type, isFinished) {
    switch (type) {
      case "normal":
        return isFinished ? green[300] : yellow[300];
      case "skip":
        return isFinished ? red[300] : yellow[300];
      case "supply":
        return isFinished ? blue[300] : yellow[300];
      case "free_supply":
        return isFinished ? purple[300] : yellow[300];
      default:
        return grey[300];
    }
  }
  const border = props.progress.isFinished ? '5px':'0px'

  const handleClick=((event)=>{
    setAnchorEl(event.currentTarget)
  })

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheck = ((e)=>{
    e.stopPropagation();
    
  })
  return (
    <>
    <ButtonBase onClick={handleClick} sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{ width: "100%", height: "100%", backgroundColor: alpha(CellColor(props.progress.type,props.progress.isFinished),0.32)}}
        border={`1px solid ${grey[300]}`}
        borderLeft={`${border} solid ${CellColor(props.progress.LectureType,props.progress.LectureStatus==='done')}`}
      >
          <div>
            <div style={{display:'flex',alignItems:'center',}}><Checkbox onClick={handleCheck} sx={{padding:'5px'}}/><Typography fontSize={'1rem'}>{props.progress.ProgramTitle}</Typography><div style={{flex:1}}/> <Typography fontSize={'1.1rem'}>{props.progress.TheDate.split('-')[1]+'-'+props.progress.TheDate.split('-')[2].split('T')[0]}</Typography></div>
            <div style={{ marginLeft: 25, marginTop:10,display:'flex' }}><Typography fontSize={'1rem'}>{props.progress.LectureLog}</Typography><div style={{flex:1}}/><div><IconFileDescription/><Favorite sx={{width:24,height:24}}/><IconClockHour4/></div></div>
          </div>
      </Box>
    </ButtonBase>
    <Menu
    id="hamburger-menu"
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    open={open}
    onClose={handleClose}
    PaperProps={{
      style: {
        width: '150px', // 원하는 너비로 설정,
        fontSize:'1.7rem'
      }
    }}
    sx={{'& .MuiMenuItem-root':{
      fontSize:'1rem'
    }}}
    MenuListProps={{
      'aria-labelledby': 'hamburger-button',
    }}
  >
    <MenuItem onClick={handleProcess}>진도처리</MenuItem>
    <Divider/>
    <MenuItem onClick={handleClose}>보강예약</MenuItem>
    <Divider/>
    <MenuItem onClick={handleClose}>보충예약</MenuItem>
    <Divider/>
    <MenuItem onClick={handleClose}>스킵처리</MenuItem>
    <Divider/>
    <MenuItem onClick={handleClose}>메모</MenuItem>
    <Divider/>
    <MenuItem onClick={handleClose}>평가(시험)</MenuItem>
    <Divider/>
    <MenuItem onClick={handleClose}>수업후기</MenuItem>
    <Divider/>
    <MenuItem onClick={handleClose}>메시지</MenuItem>
    <Divider/>
    <MenuItem onClick={handleClose}>진도정보</MenuItem>
  </Menu>
  <CRUDProgressing open={processOpen} onClose={handleCloseProcess} {...props.progress}/>
  </>
  );
}

export default ProgressCell;

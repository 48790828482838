import ClassIcon from '@mui/icons-material/Class'
import { IconUser, IconChalkboard , IconUserCheck, IconChartBar} from '@tabler/icons';

const icons = {
  ClassIcon,
  IconUser,
  IconChalkboard,
  IconUserCheck,
  IconChartBar
}
// ==============================|| Classwork MENU ITEMS ||============================== //

const statistics = {
  id: 'statistics',
  title: '통계',
  type: 'group',
  icon: icons.IconChartBar,
  children: [
    {
      id: 'statistics_student',
      title: '학생',
      type: 'item',
      breadcrumbs: false,
      url : '/statistics/student'
    },
    {
      id: 'statistics_lecture',
      title: '수업',
      type: 'item',
      breadcrumbs: false,
      url : '/statistics/lecture'
    }
  ]
};

export default statistics;

import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';
import Typography from '@mui/material/Typography';

// project imports
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import SettingSection from './SettingSection';
import NotificationSection from './NotificationSection';
import { useDispatch, useSelector } from 'react-redux';
import { MENU_OPEN } from 'store/actions';
import config from 'config';

// assets
import { IconMenu2 } from '@tabler/icons';
import { blue } from '@mui/material/colors';
import { Link } from 'react-router-dom';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const defaultId = useSelector((state) => state.customization.defaultId);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 200,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        
      </Box>

      {/* header search */}
      <SearchSection />
      
      {/* notification & profile */}
      <SettingSection/>
      <NotificationSection />
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;

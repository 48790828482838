import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  Input,
  Modal,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
  InputLabel,
  Select,
  FilledInput,
  MenuItem,
  FormControl,
  Alert,
} from "@mui/material";
import { common, grey } from "@mui/material/colors";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IconBrandMiniprogram, IconUser, IconX } from "@tabler/icons";
import dayjs from "dayjs";
import React from "react";
import theme from "themes";
import StatusChip from "ui-component/StatusChip";
import { useState } from "react";
import { AssignClassroom, GetClassrooms } from "data/ClassroomDataAPI";

function ChangeClassroom(props) {
  const [value, setValue] = useState({});
  const [classrooms, setClassrooms] = useState([]);

  React.useEffect(() => {
    GetClassrooms().then((data) => {
      setClassrooms(data);
      //find props.ClassroomID
      const classroom = data.find((classroom) => {
        return classroom.id === props.ClassroomId;
      });
      setValue(classroom);
    });
  }, []);

  const handleClassroomChange = () => {
    if (value)
    {
      props.students.forEach((student) => {
        if (student.ClassroomId !== value.id)
        {
          if(!student.ClassroomId|| student.ClassroomId === 0 )
          {
            AssignClassroom(student,value);
          }
          else
          {
            //update
            alert("배정 변경은 Schedule 처리 로직 구현 미비로 준비중입니다.");
          }
        }
      });
    }
    else alert('교실을 선택해주세요.');
    props.onClose();
    props.reload();
  }
  
  const paletteTheme = useTheme(theme());
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  function handleSave() {
    props.onClose();
  }
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  

  return (
    <>
      {props.open && (
        <ThemeProvider theme={theme}>
          <Modal
            open={props.open}
            onClose={() => {
              props.onClose();
            }}
          >
            <Box sx={style}>
              <Box
                sx={{
                  width: "100%",
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "5%" }} />
                <Typography variant="h2">교실배정 변경</Typography>
                <div style={{ flex: 1 }} />
                <ButtonBase onClick={props.onClose}>
                  <IconX />
                </ButtonBase>
                <div style={{ width: "2%" }} />
              </Box>
              <Divider />
              <Grid
                container
                alignItems={"center"}
                spacing={3}
                justifyContent={"center"}
                sx={{ padding: 5 }}
              >
                {Array.isArray(props.students) &&
                  props.students.map((student, index) => {
                    return (
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            gap: 10,
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            variant="rounded"
                            sx={{
                              width: "60px",
                              height: "60px",
                              backgroundColor: common.white,
                              color: grey[600],
                              border: "1px solid",
                            }}
                          >
                            <IconUser size={60} />
                          </Avatar>
                          <Typography
                            variant="h4"
                            sx={{ justifySelf: "flex-end" }}
                          >
                            {`${student.DisplayName} ${
                              student.Gender === "M" ? "남" : "여"
                            },${student.GradeLevel
                            },${student.School}`}
                          </Typography>
                          <Typography
                            variant="h4"
                            sx={{ justifySelf: "flex-end" , marginLeft: "30px"}}
                          >
                            {student.ProgramTitle}
                          </Typography>
                          <Typography
                            variant="h4"
                            sx={{ justifySelf: "flex-end" , marginLeft: "30px"}}
                          >
                            {student.ClassroomTitle ? student.ClassroomTitle : "미배정"}
                          </Typography>
                          <div style={{ marginLeft: "auto" }}>
                            <StatusChip
                              type={"classroom"}
                              value={"on"}
                              sx={{ width: "100px", height: "30px" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                <Grid item xs={12} sx={{ width: "90%" }}>
                  <InputLabel id="status_label">변경교실</InputLabel>
                  <FormControl fullWidth>
                    <Select
                      value={value}
                      onChange={handleChange}
                      SelectDisplayProps={{ style: { padding: 10 } }}
                    >
                      {classrooms.map((classroom, index) => {
                        return (
                          <MenuItem value={classroom}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                              }}
                            >
                              <Avatar
                                variant="square"
                                sx={{
                                  width: "40px",
                                  height: "40px",
                                  backgroundColor: common.white,
                                  color: grey[600],
                                  border: "1px solid",
                                }}
                              >
                                <IconBrandMiniprogram size={36} />
                              </Avatar>
                              <Typography fontSize={"1.0rem"}>
                                {classroom.ClassroomTitle}
                              </Typography>
                            </div>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {props.students[0].ClassroomTitle && <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid item xs={6}>
                    <DatePicker
                      label={"시작 날짜"}
                      defaultValue={dayjs(props.start_date)}
                      format="YYYY-MM-DD"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          textAlign: "left",
                          fontSize: "1.0rem",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label={"종료 날짜"}
                      defaultValue={dayjs(props.start_date)}
                      format="YYYY-MM-DD"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          textAlign: "left",
                          fontSize: "1.0rem",
                        },
                      }}
                    />
                  </Grid>
                </LocalizationProvider>}
                <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
                  <Button
                    onClick={handleClassroomChange}
                    variant="contained"
                    color="save"
                    sx={{
                      borderRadius: 0,
                      width: 140,
                      height: 60,
                      fontSize: "1.5rem",
                    }}
                  >
                    변경
                  </Button>
                  <Button
                    onClick={props.onClose}
                    variant="contained"
                    color="cancel"
                    sx={{
                      borderRadius: 0,
                      width: 140,
                      height: 60,
                      fontSize: "1.5rem",
                    }}
                  >
                    취소
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </ThemeProvider>
      )}
    </>
  );
}

export default ChangeClassroom;
